import {
  Button,
  Dialog,
  TextField,
  Paper,
  Grid,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { request } from '../../helpers';
import React, { Component, Fragment } from 'react';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { MoreVert, Close, Dashboard } from '@material-ui/icons';
import DeliveryItemBatch from './DeliveryItemBatch';
import DeliveryStatusChanger from './DeliveryStatusChanger';
import Info from '../Infos/Info';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 10,
    },
    topHeader: {
      height: 65,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      background: theme.palette.primary.dark,
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    containerButtons: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    inputTextField: {
      width: 50,
      height: 5,
      fontSize: '1em',
    },
  });

interface Props extends WithStyles {
  updateListItems: () => string;
  delivery: any;
  toggleDeliveryMaintenance: () => void;
  deliveryDeliveredDate: Date;
}

interface State {
  open: boolean;
  fields: string[];
  showBatches: boolean;
  item: {};
  deliveryItems: any;
  packages: number;
  netWeight: number;
  deliveryStatusMessage?: string;
  showChangeStatusDelivery?: boolean;
  deliveryStatusChanged: string;
  dataDeliveryStatusChanger: {
    data: {
      title: string;
      packages: number;
      netWeight: number;
      buttonCancelText: string;
      buttonConfirmText: string;
    };
  };
  error?: string;
}

class DeliveryMaintenance extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      fields: ['SKU', 'Produto', 'Quantidade', 'Lote', 'Validade', 'Ações'],
      item: {},
      showBatches: false,
      deliveryItems: [],
      showChangeStatusDelivery: false,
      deliveryStatusChanged: '',
      dataDeliveryStatusChanger: {
        data: {
          title: '',
          packages: 0,
          netWeight: 0,
          buttonCancelText: '',
          buttonConfirmText: '',
        },
      },
      packages: 0,
      netWeight: 0,
    };
  }

  toggleThisModal = () => {
    this.props.toggleDeliveryMaintenance();
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as any);
  };

  toggleBatch = async item => {
    this.setState({ showBatches: !this.state.showBatches, item: await item });

    this.componentDidMount();
  };

  getDeliveryItems = async delivery => {
    try {
      const { deliveryItems } = (
        await request('GET:/api/delivery/item', delivery)
      ).data;

      this.setState({
        deliveryItems: await deliveryItems,
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  componentDidMount = async () => {
    this.getDeliveryItems(await this.props.delivery);
  };

  render() {
    const { classes, delivery } = this.props;
    const {
      open,
      fields,
      showBatches,
      deliveryItems,
      item,
      packages,
      netWeight,
      deliveryStatusMessage,
      showChangeStatusDelivery,
      deliveryStatusChanged,
      dataDeliveryStatusChanger,
      error,
    }: State = this.state;

    const deliveryCanceled = 'DELIVERY_CANCELED';
    const deliveryShipped = 'DELIVERY_SHIPPED';
    const deliveryAwaitingInvoice = 'DELIVERY_AWAITING_INVOICE';
    const deliveryDelivered = 'DELIVERY_DELIVERED';

    return (
      <Fragment>
        <Dialog fullScreen open={open} onClose={this.toggleThisModal}>
          <header>
            <div className={classes.topHeader} onClick={this.toggleThisModal}>
              <IconButton>
                <Close style={{ color: 'white' }} />
              </IconButton>
            </div>

            <Info
              type="error"
              onClose={() => this.setState({ error: undefined })}
            >
              {error}
            </Info>

            <Paper className={classes.root}>
              <Grid container>
                <Grid
                  xs={4}
                  item
                  container
                  direction="column"
                  alignItems="center"
                >
                  <Typography variant="h4">Cliente</Typography>

                  <Typography variant="h5">
                    {delivery.customer ? delivery.customer.name : '-'}
                  </Typography>
                </Grid>

                <Grid xs item container direction="column" alignItems="center">
                  <Typography variant="h4">Delivery</Typography>

                  <Typography variant="h5">{delivery.externalCode}</Typography>
                </Grid>

                <Grid xs item container direction="column" alignItems="center">
                  <Typography variant="h4">Pedido</Typography>

                  <Typography variant="h5">{delivery.orderId}</Typography>
                </Grid>

                <Grid xs item container direction="column" alignItems="center">
                  <Typography variant="h4">Status</Typography>

                  <Typography
                    variant="h5"
                    color={deliveryStatusMessage ? 'error' : 'textPrimary'}
                  >
                    {!deliveryStatusMessage
                      ? delivery.status.name
                      : deliveryStatusMessage}
                  </Typography>
                </Grid>

                <Grid xs item container direction="column" alignItems="center">
                  <Typography variant="h4">Volumes</Typography>

                  <Typography variant="h5">
                    {packages === 0 ? delivery.packages : packages}
                  </Typography>
                </Grid>

                <Grid xs item container direction="column" alignItems="center">
                  <Typography variant="h4">Peso</Typography>

                  <Typography variant="h5">
                    {netWeight === 0 ? delivery.netWeight : netWeight}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </header>

          <Divider
            variant="middle"
            style={{ margin: '20px auto ', width: '70%' }}
          />

          <main>
            <Paper className={classes.root}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    {fields.map(field => {
                      return (
                        <TableCell className={classes.tableHeaderText}>
                          {field}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {deliveryItems.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item.sku}</TableCell>
                        <TableCell>{item.productDescription}</TableCell>
                        <TableCell>{item.qtyRemaining}</TableCell>

                        <TableCell>
                          {item.deliveryItemBatch
                            .map((itemCode, index) =>
                              index > 0 ? ' | ' + itemCode.code : itemCode.code,
                            )
                            .slice(0, 3)}
                        </TableCell>

                        <TableCell>
                          {item.deliveryItemBatch
                            .map((itemValidate, index) =>
                              index > 0
                                ? ' | ' +
                                  itemValidate.expirationAt
                                    .split('-')
                                    .reverse()
                                    .join('/')
                                : itemValidate.expirationAt
                                    .split('-')
                                    .reverse()
                                    .join('/'),
                            )
                            .slice(0, 3)}
                        </TableCell>

                        <TableCell>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {popupState => (
                              <React.Fragment>
                                <IconButton {...bindTrigger(popupState)}>
                                  <MoreVert />
                                </IconButton>

                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      this.toggleBatch(item);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Dashboard />
                                    </ListItemIcon>

                                    <ListItemText inset>Lotes</ListItemText>
                                  </MenuItem>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </main>

          <footer>
            <Paper className={classes.root}>
              <div className={classes.containerButtons}>
                <TextField
                  label="Volumes"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    classes: {
                      input: this.props.classes.inputTextField,
                    },
                  }}
                  autoFocus
                  name="packages"
                  value={packages}
                  onChange={event => this.handleChange(event)}
                />

                <TextField
                  label="Peso"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    classes: {
                      input: this.props.classes.inputTextField,
                    },
                  }}
                  name="netWeight"
                  value={netWeight}
                  onChange={event => this.handleChange(event)}
                />

                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      showChangeStatusDelivery:
                        !this.state.showChangeStatusDelivery,
                      deliveryStatusChanged: deliveryAwaitingInvoice,
                      dataDeliveryStatusChanger: {
                        data: {
                          title:
                            'Tem certeza que deseja liberar o faturamento?',
                          buttonConfirmText: 'Faturar',
                          buttonCancelText: 'Cancelar',
                          packages: this.state.packages,
                          netWeight: this.state.netWeight,
                        },
                      },
                    })
                  }
                  disabled={deliveryStatusMessage == 'Cancelado' ? true : false}
                >
                  Liberação Faturamento
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showChangeStatusDelivery:
                        !this.state.showChangeStatusDelivery,
                      deliveryStatusChanged: deliveryCanceled,
                      dataDeliveryStatusChanger: {
                        data: {
                          title:
                            'Tem certeza que deseja cancelar esta delivery?',
                          buttonConfirmText: 'Cancelar',
                          buttonCancelText: 'Não Cancelar',
                          packages: this.state.packages,
                          netWeight: this.state.netWeight,
                        },
                      },
                    })
                  }
                  disabled={deliveryStatusMessage == 'Cancelado' ? true : false}
                >
                  Cancelar Delivery
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showChangeStatusDelivery:
                        !this.state.showChangeStatusDelivery,
                      deliveryStatusChanged: deliveryShipped,
                      dataDeliveryStatusChanger: {
                        data: {
                          title:
                            'Tem certeza que deseja expedir esta delivery?',
                          buttonConfirmText: 'Expedir',
                          buttonCancelText: 'Cancelar',
                          packages: this.state.packages,
                          netWeight: this.state.netWeight,
                        },
                      },
                    })
                  }
                  disabled={deliveryStatusMessage == 'Cancelado' ? true : false}
                >
                  Expedir Delivery
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={deliveryStatusMessage == 'Cancelado' ? true : false}
                  onClick={() =>
                    this.setState({
                      showChangeStatusDelivery:
                        !this.state.showChangeStatusDelivery,
                      deliveryStatusChanged: deliveryDelivered,
                      dataDeliveryStatusChanger: {
                        data: {
                          title:
                            'Tem certeza que deseja entregar esta delivery?',
                          buttonConfirmText: 'Entregar',
                          buttonCancelText: 'Cancelar',
                          packages: this.state.packages,
                          netWeight: this.state.netWeight,
                        },
                      },
                    })
                  }
                >
                  Confirmar Entrega
                </Button>
              </div>
            </Paper>
          </footer>

          <Divider
            variant="middle"
            style={{ margin: '20px auto ', width: '70%' }}
          />
        </Dialog>

        {showBatches && (
          // @ts-ignore
          <DeliveryItemBatch
            toggleBatch={() => this.toggleBatch(null)}
            item={item}
          />
        )}

        {showChangeStatusDelivery && (
          <DeliveryStatusChanger
            delivery={delivery}
            changeStatusDelivery={(deliveryDeliveredDate: Date): any =>
              this.handleSubmit(deliveryStatusChanged, deliveryDeliveredDate)
            }
            toggleDeliveryStatusChanger={() =>
              this.toggleDeliveryStatusChanger()
            }
            data={dataDeliveryStatusChanger.data}
          />
        )}
      </Fragment>
    );
  }

  handleSubmit = async (
    deliveryStatusChanged: string,
    deliveryDeliveredDate: Date,
  ) => {
    const { id } = await this.props.delivery;
    const { packages, netWeight, deliveryItems } = this.state;

    try {
      if (!packages || !netWeight) {
        this.setState({
          error: 'O campo "Volumes" ou "Peso" não possui valor',
        });

        return null;
      }

      const response = await request('POST:/api/delivery/status-change', {
        id,
        deliveryStatusChanged,
        packages,
        netWeight,
        deliveryDeliveredDate,
      });

      this.setState({
        deliveryStatusMessage: await response.data.message,
        showChangeStatusDelivery: !this.state.showChangeStatusDelivery,
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  toggleDeliveryStatusChanger = () => {
    this.setState({
      showChangeStatusDelivery: !this.state.open,
      dataDeliveryStatusChanger: {
        data: {
          title: '',
          packages: 0,
          netWeight: 0,
          buttonCancelText: '',
          buttonConfirmText: '',
        },
      },
    });
  };
}

export default withStyles(styles)(DeliveryMaintenance);
