import * as React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/Router/PrivateRoute';
import Dashboard from './pages/Dashboard';
import DashboardOrders from './pages/DashboardOrders';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Orders from './pages/Orders';
import Packing from './pages/Packing';
import Page from './pages/Page';
import PreShipmentAssign from './pages/PreShipmentAssign';
import PreShipmentList from './pages/PreShipmentList';
import WaveGenerate from './pages/WaveGenerate';
import WaveList from './pages/WaveList';
import Delivery from './pages/Delivery';
import StorageControllerList from './pages/StorageControllerList';
import StorageControllerItemList from './pages/StorageControllerItemList';
import DeliveryList from './pages/DeliveryList';
import ReverseInvoice from './pages/ReverseInvoice';
import WaveSmart from './pages/WaveSmart';
import WaveOptimized from './pages/WaveOptimized';
import WarehouseManagementDashboard from './pages/WarehouseManagementDashboard';
import WarehousesManagementDashboardGeneral from './pages/WarehousesManagementDashboardGeneral';
import Checkout from './pages/Checkout';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Page>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/report/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/report/dashboard-orders"
            component={DashboardOrders}
          />
          <PrivateRoute
            exact
            path="/report/dashboard-by-warehouse"
            component={WarehouseManagementDashboard}
          />
          <PrivateRoute
            exact
            path="/report/dashboard-warehouses"
            component={WarehousesManagementDashboardGeneral}
          />
          <PrivateRoute exact path="/report/orders" component={Orders} />
          <PrivateRoute exact path="/wave/generate" component={WaveGenerate} />
          <PrivateRoute exact path="/wave/list" component={WaveList} />
          <PrivateRoute exact path="/wave/smart" component={WaveSmart} />
          <PrivateRoute
            exact
            path="/wave/optimized"
            component={WaveOptimized}
          />
          <PrivateRoute
            exact
            path="/pre-shipment/list"
            component={PreShipmentList}
          />
          <PrivateRoute
            exact
            path="/pre-shipment/assign/:id"
            component={PreShipmentAssign}
          />
          <PrivateRoute exact path="/packing" component={Packing} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          <PrivateRoute
            exact
            path="/storage-controller/list"
            component={StorageControllerList}
          />

          <PrivateRoute
            exact
            path="/storage-controller-items/list/:id"
            component={StorageControllerItemList}
          />

          <PrivateRoute exact path="/delivery" component={Delivery} />

          <PrivateRoute exact path="/delivery/list" component={DeliveryList} />

          <PrivateRoute exact path="/checkout/packing" component={Checkout} />

          <PrivateRoute
            exact
            path="/unilog/reverse-logistics"
            component={ReverseInvoice}
          />
          <Switch />
        </Page>
      </BrowserRouter>
    );
  }
}

let ExportApp = App;

if (process.env.NODE_ENV === 'development') {
  ExportApp = hot(module)(App);
}

export default ExportApp;
