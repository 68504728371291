import {
  Fab,
  Menu,
  Grid,
  Paper,
  Table,
  Divider,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  Typography,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import * as React from 'react';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import { request } from '../helpers';
import { Search, MoreVert, Settings } from '@material-ui/icons';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import DeliveryMaintenance from '../components/Delivery/DeliveryMaintenance';
import Pagination from '../components/Pagination/Pagination';
import moment from 'moment';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    containerHeader: {
      height: 250,
      borderRadius: 5,
      padding: '10px 20px',
      position: 'relative',
      border: '2px solid #1E317E',
    },
    containerHeader__FilterTxt: {
      top: -15,
      width: '70px',
      height: '20px',
      textAlign: 'center',
      position: 'absolute',
      backgroundColor: 'white',
    },
    containerInputs: {
      paddingTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    alignButton: {
      display: 'flex',
      paddingBottom: 10,
      alignItems: 'flex-end',
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface State {
  //  loading: boolean;
  //  success?: string;
  //  error?: string;
  deliveries: any[];
  orderId: string;
  fields: string[];
  customer: string;
  delivery?: string;
  externalCode: string;
  releasedAtFinal: string;
  releasedAtInitial: string;
  deliveryExpectedDateFinal: string;
  deliveryExpectedDateInitial: string;
  showDeliveryMaintenance: boolean;
  statusDelivery: any[];
  statusId: string;
  pagination: {};
  current_page: number;
}

class DeliveryList extends React.Component<Props, State> {
  state = {
    //  loading: false,
    fields: [
      'Pedido',
      'Delivery',
      'Filial',
      'Tipo',
      'Cliente',
      'Emissão',
      'Data prevista',
      'Status',
      'Ações',
    ],
    orderId: '',
    customer: '',
    deliveries: [],
    externalCode: '',
    releasedAtFinal: '',
    releasedAtInitial: '',
    deliveryExpectedDateFinal: '',
    deliveryExpectedDateInitial: '',
    showDeliveryMaintenance: false,
    statusDelivery: [],
    statusId: '',
    pagination: {},
    current_page: 1,
  };

  getDeliveriesAndStatus = async () => {
    const response = (
      await request('GET:/api/delivery/list', {
        per_page: 10,
        current_page: this.state.current_page,
      })
    ).data;

    const { data: deliveries, ...pagination } = response;

    const { status } = (await request('GET:/api/status', {})).data;

    try {
      this.setState({
        deliveries,
        pagination,
        statusDelivery: status,
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  componentDidMount = () => {
    this.getDeliveriesAndStatus();
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as any);
  };

  handleSubmit = async event => {
    event.preventDefault();

    let {
      orderId,
      customer,
      externalCode,
      releasedAtFinal,
      releasedAtInitial,
      deliveryExpectedDateFinal,
      deliveryExpectedDateInitial,
      statusId,
    } = this.state;

    if (!releasedAtFinal) {
      releasedAtFinal = releasedAtInitial;
    }

    if (!deliveryExpectedDateFinal) {
      deliveryExpectedDateFinal = deliveryExpectedDateInitial;
    }

    const response = (
      await request('GET:/api/delivery/list', {
        per_page: 10,
        orderId,
        customer,
        externalCode,
        releasedAtFinal,
        releasedAtInitial,
        deliveryExpectedDateFinal,
        deliveryExpectedDateInitial,
        statusId,
      })
    ).data;

    const { data: deliveries, ...pagination } = response;

    this.setState({
      deliveries,
      pagination,
    });
  };

  toggleDeliveryMaintenance = async delivery => {
    this.setState({
      showDeliveryMaintenance: !this.state.showDeliveryMaintenance,
      delivery: await delivery,
    });
  };

  renderPagination() {
    const { deliveries, pagination } = this.state;

    try {
      if (deliveries.length > 0 && deliveries) {
        return (
          <Pagination
            pagination={pagination}
            changePage={this.handleChangePage}
          />
        );
      }
    } catch (e) {
      return null;
    }
  }

  handleChangePage = async page => {
    this.setState({ current_page: await page });

    this.getDeliveriesAndStatus();
  };

  render() {
    const { classes }: Props = this.props;
    let {
      fields,
      orderId,
      customer,
      delivery,
      deliveries,
      externalCode,
      releasedAtFinal,
      releasedAtInitial,
      deliveryExpectedDateFinal,
      deliveryExpectedDateInitial,
      showDeliveryMaintenance,
      statusDelivery,
      statusId,
    }: any = this.state;

    if (!releasedAtInitial) {
      releasedAtInitial = moment().format('YYYY-MM-DD');
    }

    if (!releasedAtFinal) {
      releasedAtFinal = releasedAtInitial;
    }

    if (!deliveryExpectedDateInitial) {
      deliveryExpectedDateInitial = moment().format('YYYY-MM-DD');
    }

    if (!deliveryExpectedDateFinal) {
      deliveryExpectedDateFinal = deliveryExpectedDateInitial;
    }

    return (
      <>
        <header>
          <Paper className={classes.root}>
            <div className={classes.containerHeader}>
              <Typography
                variant="h6"
                className={classes.containerHeader__FilterTxt}
              >
                Filtros
              </Typography>

              <form onSubmit={event => this.handleSubmit(event)}>
                <div className={classes.containerInputs}>
                  <Grid container>
                    <Grid xs item container justify="center">
                      <TextField
                        name="orderId"
                        label="Pedido"
                        margin="normal"
                        value={orderId}
                        variant="outlined"
                        onChange={event => this.handleChange(event)}
                      />

                      <TextField
                        margin="normal"
                        label="Delivery"
                        variant="outlined"
                        name="externalCode"
                        value={externalCode}
                        onChange={event => this.handleChange(event)}
                      />
                    </Grid>

                    <Grid xs item container justify="center">
                      <TextField
                        name="customer"
                        label="Cliente"
                        margin="normal"
                        value={customer}
                        variant="outlined"
                        onChange={event => this.handleChange(event)}
                      />

                      <TextField
                        select
                        name="statusId"
                        label="Status"
                        value={statusId}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          style: {
                            width: 230,
                          },
                        }}
                        onChange={event => this.handleChange(event)}
                      >
                        {statusDelivery.map(status => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid xs item container justify="center">
                      <TextField
                        type="date"
                        margin="normal"
                        variant="outlined"
                        label="Emissão inicial"
                        name="releasedAtInitial"
                        value={releasedAtInitial}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => this.handleChange(event)}
                      />

                      <TextField
                        type="date"
                        margin="normal"
                        variant="outlined"
                        name="deliveryExpectedDateInitial"
                        value={deliveryExpectedDateInitial}
                        label="Entrega prevista inicial"
                        InputLabelProps={{ shrink: true }}
                        onChange={event => this.handleChange(event)}
                      />
                    </Grid>

                    <Grid xs item container justify="center">
                      <TextField
                        type="date"
                        margin="normal"
                        variant="outlined"
                        label="Emissão final"
                        name="releasedAtFinal"
                        value={releasedAtFinal}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => this.handleChange(event)}
                      />

                      <TextField
                        type="date"
                        margin="normal"
                        variant="outlined"
                        name="deliveryExpectedDateFinal"
                        value={deliveryExpectedDateFinal}
                        label="Entrega prevista final"
                        InputLabelProps={{ shrink: true }}
                        onChange={event => this.handleChange(event)}
                      />
                    </Grid>

                    <Grid xl item className={classes.alignButton}>
                      <Fab type="submit" aria-label="add" color="primary">
                        <Search />
                      </Fab>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          </Paper>
        </header>
        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '70%' }}
        />
        <main>
          <Paper className={classes.root}>
            {this.renderPagination()}

            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {fields.map(field => {
                    return (
                      <TableCell className={classes.tableHeaderText}>
                        {field}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {deliveries.map(delivery => {
                  return (
                    <TableRow key={delivery.id}>
                      <TableCell>{delivery.orderId}</TableCell>
                      <TableCell>{delivery.externalCode}</TableCell>
                      <TableCell>{delivery.branchCode}</TableCell>
                      <TableCell>{delivery.type}</TableCell>
                      <TableCell>
                        {delivery.customer ? delivery.customer.name : '-'}
                      </TableCell>
                      <TableCell>{delivery.releasedAt}</TableCell>
                      <TableCell>{delivery.deliveryExpectedDate}</TableCell>
                      <TableCell>{delivery.status.name}</TableCell>
                      <TableCell>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreVert />
                              </IconButton>

                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    this.toggleDeliveryMaintenance(delivery);
                                  }}
                                >
                                  <ListItemIcon>
                                    <Settings />
                                  </ListItemIcon>

                                  <ListItemText inset>
                                    Manutenção Delivery
                                  </ListItemText>
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {this.renderPagination()}
          </Paper>
        </main>

        {showDeliveryMaintenance && (
          //@ts-ignore
          <DeliveryMaintenance
            delivery={delivery}
            toggleDeliveryMaintenance={() =>
              this.toggleDeliveryMaintenance(null)
            }
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(DeliveryList);
