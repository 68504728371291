import { MenuItem, TextField } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { request } from '../../helpers';

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      width: 170,
    },
  });

interface Props extends WithStyles<typeof styles> {
  onChange: any;
  value: string;
}

interface State {
  brands: any[];
}

const inputLabelProps = {
  shrink: true,
};
class BrandInput extends React.Component<Props, State> {
  state = {
    brands: [],
  };

  async componentDidMount() {
    const response = (await request('post:/api/brand/list', {})).data;

    this.setState({ brands: response.brands });
  }

  render() {
    const { onChange, value, classes } = this.props;
    const { brands } = this.state;

    return (
      <TextField
        name="brand"
        select
        label="Marca"
        margin="normal"
        InputLabelProps={inputLabelProps}
        className={classes.textField}
        onChange={onChange}
        value={value}
      >
        <MenuItem value="" />
        {brands.map((brand: any) => (
          <MenuItem value={brand.code} key={brand.code}>
            {brand.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default withStyles(styles)(BrandInput);
