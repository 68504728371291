import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({});

interface Props extends WithStyles {
  delivery: any;
  changeStatusDelivery: (deliveryDeliveredDate: Date) => Date;
  toggleDeliveryStatusChanger: () => void;
  data: {
    title: string;
    packages: number;
    netWeight: number;
    buttonCancelText: string;
    buttonConfirmText: string;
  };
}

interface State {
  open: boolean;
  deliveryDeliveredDate?: Date;
}

class DeliveryStatusChanger extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });

    this.props.toggleDeliveryStatusChanger();
  };

  render() {
    const { open, deliveryDeliveredDate }: any = this.state;
    const {
      delivery,
      data: { title, buttonConfirmText, buttonCancelText, packages, netWeight },
    } = this.props;

    return (
      <Fragment>
        <Dialog open={open}>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Cliente: {delivery.customer ? delivery.customer.name : '-'}
            </DialogContentText>

            <DialogContentText>
              Delivery: {delivery.externalCode}
            </DialogContentText>

            <DialogContentText>Pedido: {delivery.orderId}</DialogContentText>

            <DialogContentText>
              Status: {delivery.status.name}
            </DialogContentText>

            <DialogContentText>
              {packages > 1 ? `Volumes:  ${packages}` : `Volume:  ${packages}`}
            </DialogContentText>

            <DialogContentText>Peso: {netWeight}</DialogContentText>

            {buttonConfirmText == 'Entregar' ? (
              <TextField
                name="deliveryDeliveredDate"
                value={deliveryDeliveredDate}
                label="Data da entrega"
                margin="normal"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => this.handleChange(event)}
              />
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={this.handleToggle}
            >
              {buttonCancelText}
            </Button>

            <Button
              color={buttonConfirmText != 'Cancelar' ? 'primary' : 'secondary'}
              variant="contained"
              onClick={() =>
                this.props.changeStatusDelivery(deliveryDeliveredDate)
              }
            >
              {buttonConfirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as any);
  };
}

export default withStyles(styles)(DeliveryStatusChanger);
