import {
  Paper,
  Button,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { request } from '../../helpers';
import React, { Component } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      width: '30%',
      margin: 'auto',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
    title: {
      textAlign: 'center',
    },
    containerButton: {
      width: '35%',
      display: 'flex',
      justifyContent: 'space-around',
      margin: 'auto',
    },
    allContent: {
      width: '50%',
      margin: 'auto',
    },
  });

interface Props extends WithStyles<typeof styles> {
  redirect: (string) => void;
}

interface State {
  code: string;
}

interface ClassCSS {
  className: any;
}

class StorageControllerAdd extends Component<Props, State, ClassCSS> {
  state: State = {
    code: '',
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    } as any);
  };

  handleSubmit = async e => {
    e.preventDefault();

    const data = {
      warehouseCode: await Cookies.get('warehouse'),
      ...this.state,
    };

    try {
      const response = (
        await request('post:/api/storage-controller/upsert', data)
      ).data;

      this.props.redirect(response.storageControllerId);
    } catch (e) {
      throw new Error(e);
    }
  };

  render() {
    const { classes }: any = this.props;
    const { code }: any = this.state;

    return (
      <>
        <main className={classes.allContent}>
          <Typography variant="h5" color="inherit" className={classes.title}>
            Cadastrar colmeia {code}
          </Typography>

          <Divider
            variant="middle"
            style={{ margin: '20px auto ', width: '70%' }}
          />

          <form onSubmit={e => this.handleSubmit(e)}>
            <Paper className={classes.root}>
              <div className={classes.containerInputFields}>
                <div>
                  <TextField
                    required
                    autoFocus
                    name="code"
                    value={code}
                    margin="normal"
                    label="Código colméia"
                    onChange={e => this.handleChange(e)}
                  />
                </div>
              </div>
            </Paper>

            <Divider
              variant="middle"
              style={{ margin: '20px auto ', width: '70%' }}
            />

            <div className={classes.containerButton}>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>

              <Button type="reset" href="list" variant="contained">
                Cancelar
              </Button>
            </div>
          </form>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(StorageControllerAdd);
