import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';
import { Add, Delete, LiveTvRounded } from '@material-ui/icons';
import { request } from '../../helpers';
import moment from 'moment';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    container: {
      margin: 20,
      borderRadius: 5,
      position: 'relative',
      border: '2px solid #1E317E',
    },
    container_batchTxt: {
      top: -15,
      width: '70px',
      height: '20px',
      textAlign: 'center',
      position: 'absolute',
      backgroundColor: 'white',
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    footerTxt: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    inputTextField: {
      width: 60,
      height: 3,
    },
    inputTextFieldDate: {
      width: 145,
      height: 3,
    },
  });

interface Props extends WithStyles {
  item: {
    sku?: string;
    productDescription?: string;
    qtyRemaining?: number;
    id?: number;
    deliveryItemBatch?: any[];
  };
  toggleBatch: () => Promise<void>;
}

interface State {
  fields: string[];
  open: boolean;
  batchTextField: any[];
  quantityCounter: any;
  invalidDateError: string;
}

class DeliveryItemBatch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      fields: ['Lote', 'Validade', 'Quantidade', ''],
      batchTextField: [...this.props.item.deliveryItemBatch!],
      quantityCounter: 0,
      invalidDateError: '',
    };
  }

  toggleThisModal = () => {
    this.props.toggleBatch();
  };

  handleChange = (event, index) => {
    const { name, value } = event.target;

    const { batchTextField } = this.state;

    batchTextField[index][name] = value;

    this.setState({
      batchTextField: this.state.batchTextField,
      quantityCounter: this.handleSumBatch(),
    });
  };

  handleSubmit = async event => {
    try {
      event.preventDefault();
      const { batchTextField } = this.state;

      const validDate = moment().add(1, 'days').format('DD-MM-YYYY');

      const today = moment().format('YYYY-MM-DD');

      for (const batch of batchTextField) {
        if (batch.expirationAt <= today) {
          this.setState({
            invalidDateError: `A validade deve ser : ${validDate} ou superior`,
          });
          return null;
        }
      }

      await request('POST:/api/delivery/item/batch', batchTextField);

      this.toggleThisModal();
    } catch (err) {
      throw new Error(err);
    }
  };

  handleAddTextField = () => {
    this.setState({
      batchTextField: [
        ...this.state.batchTextField,
        {
          qty: 0,
          code: null,
          expirationAt: null,
          deliveryItemId: this.props.item.id,
        },
      ],
    });
  };

  handleSumBatch = () => {
    return this.state.batchTextField.reduce((acc, curr) => {
      return acc + parseInt(curr.qty);
    }, 0);
  };

  componentDidMount = () => {
    this.handleSumBatch();
  };

  render() {
    let {
      classes,
      item: { sku, productDescription, qtyRemaining },
    }: Props = this.props;
    let {
      open,
      fields,
      batchTextField,
      quantityCounter,
      invalidDateError,
    }: State = this.state;

    qtyRemaining = qtyRemaining ? qtyRemaining : 0;

    return (
      <Fragment>
        <form onSubmit={event => this.handleSubmit(event)}>
          <Dialog maxWidth="lg" open={open} onClose={this.toggleThisModal}>
            <section className={classes.container}>
              <DialogContent>
                <header>
                  <Typography
                    variant="h6"
                    className={classes.container_batchTxt}
                  >
                    Lotes
                  </Typography>

                  <Paper className={classes.root}>
                    <Grid container>
                      <Grid xs={4} item>
                        <Typography variant="h5">SKU</Typography>

                        <Typography variant="h6">{sku}</Typography>
                      </Grid>
                      <Grid xs item>
                        <Typography variant="h5">Produto</Typography>

                        <Typography variant="h6">
                          {productDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </header>

                <main>
                  <Paper className={classes.root}>
                    <Table>
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          {fields.map(field => {
                            return (
                              <TableCell
                                align="center"
                                className={classes.tableHeaderText}
                              >
                                {field}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {batchTextField.map((batch, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      input: this.props.classes.inputTextField,
                                    },
                                  }}
                                  onChange={e => this.handleChange(e, index)}
                                  name="code"
                                  value={batch.code}
                                />
                              </TableCell>

                              <TableCell>
                                <TextField
                                  type="date"
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      input:
                                        this.props.classes.inputTextFieldDate,
                                    },
                                  }}
                                  onChange={e => this.handleChange(e, index)}
                                  name="expirationAt"
                                  value={batch.expirationAt}
                                  inputProps={{
                                    min: moment()
                                      .add(1, 'days')
                                      .format('YYYY-MM-DD'),
                                  }}
                                />
                              </TableCell>

                              <TableCell>
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      input: this.props.classes.inputTextField,
                                    },
                                  }}
                                  onChange={e => this.handleChange(e, index)}
                                  error={
                                    quantityCounter != qtyRemaining
                                      ? true
                                      : false
                                  }
                                  name="qty"
                                  value={batch.qty}
                                  required
                                />
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    this.handleDelete(batch, index)
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                      <Fab
                        size="small"
                        color="primary"
                        style={{ margin: 10 }}
                        onClick={() => this.handleAddTextField()}
                      >
                        <Add />
                      </Fab>
                    </Table>
                  </Paper>
                </main>
              </DialogContent>

              <footer>
                {invalidDateError && (
                  <Typography
                    variant="h6"
                    color="error"
                    align="right"
                    className={classes.root}
                  >
                    {invalidDateError}
                  </Typography>
                )}

                <div className={classes.footerTxt}>
                  <Typography variant="h5">Quantidade: </Typography>
                  <Typography variant="h5">{qtyRemaining}</Typography>
                  <Typography
                    variant="h5"
                    color={quantityCounter > qtyRemaining ? 'error' : undefined}
                  >
                    {this.handleSumBatch()}

                    {quantityCounter > qtyRemaining ? (
                      <Typography color="error" variant="subtitle2">
                        {'Quantidade inválida'}
                      </Typography>
                    ) : null}
                  </Typography>
                </div>

                <DialogActions>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={this.toggleThisModal}
                    type="reset"
                  >
                    Cancelar
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={event => this.handleSubmit(event)}
                    disabled={quantityCounter != qtyRemaining ? true : false}
                    type="submit"
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </footer>
            </section>
          </Dialog>
        </form>
      </Fragment>
    );
  }

  handleDelete = async (batch: { id: number }, index: number) => {
    const { id } = batch;

    let { batchTextField } = this.state;

    try {
      if (id) {
        await request('DELETE:/api/delivery/item/batch', { id });
      }

      batchTextField.splice(index, 1);

      this.setState({
        batchTextField: [...batchTextField],
      });
    } catch (err) {
      throw new Error(err);
    }
  };
}
export default withStyles(styles)(DeliveryItemBatch);
