import {
  createStyles,
  Icon,
  IconButton,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import cn from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      height: '60px',
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    success: {
      backgroundColor: '#228b22',
      color: '#ffffff',
    },
    text: {
      margin: 0,
      fontWeight: 700,
    },
  });

interface Props extends React.Props<{}>, WithStyles<typeof styles> {
  classes: any;
  onClose?: any;
  type?: 'success' | 'error';
  infoStylePersonalized?: any;
}

interface State {}

class Info extends React.Component<Props, State> {
  state = {
    visible: false,
  };

  render() {
    const { children, classes, type, infoStylePersonalized } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return null;
    }

    const containerClass = cn(
      classes.container,
      infoStylePersonalized,
      classes[type || 'success'],
    );

    return (
      !!children && (
        <Paper className={containerClass} component="div">
          <Typography className={classes.text} color="inherit" paragraph={true}>
            {children}
          </Typography>

          <IconButton onClick={this.close} color="inherit">
            <Icon>cancel</Icon>
          </IconButton>
        </Paper>
      )
    );
  }

  componentDidMount() {
    this.setState({ visible: true });
  }

  static getDerivedStateFromProps(props) {
    return {
      visible: true,
    };
  }

  close = () => {
    const { onClose } = this.props;

    this.setState({ visible: false });

    onClose && onClose();
  };
}

export default withStyles(styles)(Info);
