import {
  Button,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import format from 'date-fns/format';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Info from '../components/Infos/Info';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    container: {
      padding: '0 10px',
    },
    containerInput: {
      paddingRight: '20px',
    },
    pendingOrders: {
      border: '1px solid #ccc',
      borderRadius: '10px',
      fontSize: '30px',
      padding: '10px',
      textAlign: 'center',
    },
    packageContainer: {
      border: '1px solid #ccc',
      borderRadius: '10px',
      fontSize: '30px',
      margin: '10px',
      padding: '0 10px',
      textAlign: 'center',
    },
    specialCampaign: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      background: '#178c46',
      color: '#fff',
      fontSize: '30px',
      margin: '10px',
      padding: '5px',
      textAlign: 'center',
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  invoiceKey?: string;
  delivery?: any;
  specialCampaigns: string[];
  productPackage?: any;
  values: any;
  events: any[];
  pendingOrdersTotal?: number;
  success?: string;
  error?: string;
}

class Packing extends React.Component<Props, State> {
  state = {
    loading: false,
    invoiceKey: '',
    delivery: null,
    productPackage: null,
    values: {
      input: '',
      keep_package: true,
    },
    events: [],
    specialCampaigns: [],
  };

  audioError: any;
  audioSuccess: any;

  constructor(props) {
    super(props);
    this.audioError = React.createRef();
    this.audioSuccess = React.createRef();
  }

  handleChange = e => {
    e.persist();

    const { name, value } = e.target;

    const { values } = this.state;
    values[name] = value;
    if (name === 'keep_package') {
      values[name] = e.target.checked;
    }

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  pack = async delivery => {
    const { productPackage }: any = this.state;

    if (!delivery) {
      return;
    }

    if (!productPackage) {
      return;
    }

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      product_package_id: productPackage.id,
      delivery_id: delivery.id,
    };

    try {
      await request('get:/api/packing/pack', options);

      await this.success();
    } catch (e) {
      this.setError(
        'Houve um erro ao finalizar: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      );
    }
  };

  success = async () => {
    this.setSuccess('Operação realizada com sucesso');

    setTimeout(() => {
      this.setState({
        success: undefined,
      });
    }, 10000);

    await this.loadReport();
    await this.loadPendingOrdersTotal();
  };

  loadReport = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (await request('get:/api/packing/report', options)).data;

    this.setState({ events: response.events });
  };

  loadPendingOrdersTotal = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (
      await request('get:/api/packing/pending-orders-total', options)
    ).data;

    this.setState({ pendingOrdersTotal: response.pendingOrdersTotal });
  };

  findDelivery = async invoiceKey => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      invoice_key: invoiceKey,
    };

    this.setState({
      invoiceKey: undefined,
      delivery: undefined,
      specialCampaigns: [],
      error: undefined,
    });

    try {
      const response = (
        await request('get:/api/delivery/find-by-invoice', options)
      ).data;

      if (!response.delivery) {
        this.setError('Nota fiscal não encontrada');
        return;
      }

      this.setState({
        invoiceKey,
        delivery: response.delivery,
        specialCampaigns: response.specialCampaigns,
      });

      return response.delivery;
    } catch (e) {
      console.log(e);
      this.setError('Houve um erro: ' + (e.response.data.message || e.message));
    }
  };

  findPackage = async sku => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      sku: sku,
    };
    try {
      const response = (await request('get:/api/packing/find-package', options))
        .data;

      if (!response.package) {
        this.setError('Pacote não encontrado');
        return;
      }

      this.setState({ productPackage: response.package });
    } catch (e) {
      this.setError('Pacote não encontrado');
      return;
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { values, productPackage } = this.state;

    if (!values.input) {
      return;
    }

    const barcode = values.input.trim();

    values.input = '';
    this.setState({ values });

    if (barcode.length === 44) {
      if (!productPackage) {
        this.setError('Escolha uma Embalagem primeiro');
        return;
      }
      const delivery = await this.findDelivery(barcode);

      if (delivery) {
        this.pack(delivery);
      }

      return;
    }

    await this.findPackage(barcode);
  };

  setError(error: string) {
    this.setState({ error });

    this.audioError.current.play();
  }

  setSuccess(success: string) {
    this.setState({
      success,
      error: undefined,
      invoiceKey: '',
      delivery: null,
    });

    const { values } = this.state;
    if (!values.keep_package) {
      this.setState({
        productPackage: null,
      });
    }

    this.audioSuccess.current.play();
  }

  async componentDidMount() {
    this.setState({ loading: true });

    this.loadPendingOrdersTotal();

    await this.loadReport();

    this.setState({ loading: false });
  }

  render() {
    const { classes } = this.props;
    const {
      values,
      events,
      loading,
      success,
      error,
      invoiceKey,
      productPackage,
      pendingOrdersTotal,
      specialCampaigns,
    }: any = this.state;

    return (
      <div>
        <audio src="/audio/error.mp3" ref={this.audioError} />
        <audio src="/audio/success.mp3" ref={this.audioSuccess} />

        <form onSubmit={this.handleSubmit}>
          <Info
            type="success"
            onClose={() => this.setState({ success: undefined })}
          >
            {success}
          </Info>

          <Info
            type="error"
            onClose={() => this.setState({ error: undefined })}
          >
            {error}
          </Info>

          <div className={classes.container}>
            {invoiceKey && (
              <Typography variant="h6">Nota Fiscal: {invoiceKey}</Typography>
            )}

            {loading && <Loading />}

            <Grid container>
              <Grid item xs={3}>
                <div className={classes.packageContainer}>
                  <div>
                    <b>Embalagem:</b> {productPackage && productPackage.sku}
                  </div>

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="keep_package"
                        checked={values.keep_package}
                        onChange={this.handleChange}
                        color="primary"
                      />
                    }
                    label="Manter"
                  />
                </div>
              </Grid>
              <Grid item xs={5} className={classes.containerInput}>
                <TextField
                  name="input"
                  label="Embalagem / Nota Fiscal"
                  margin="normal"
                  autoFocus
                  onChange={this.handleChange}
                  autoComplete={'off'}
                  value={values.input}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={2}>
                <br />
                <br />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Checkout
                </Button>
              </Grid>

              <Grid item xs={2}>
                {pendingOrdersTotal > 0 && (
                  <div className={classes.pendingOrders}>
                    Pendente:
                    <br />
                    {pendingOrdersTotal}
                  </div>
                )}

                {specialCampaigns.length && (
                  <div className={classes.specialCampaign}>
                    {specialCampaigns.map(
                      (specialCampaign, specialCampaignIndex) => (
                        <div key={specialCampaignIndex}>{specialCampaign}</div>
                      ),
                    )}
                  </div>
                )}
              </Grid>
            </Grid>

            {!loading && events.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nota Fiscal</TableCell>
                    <TableCell>Pedido</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event: any) => (
                    <TableRow key={event.id}>
                      <TableCell>
                        {event.invoice_number} / {event.invoice_serie}
                      </TableCell>
                      <TableCell>{event.order_id}</TableCell>
                      <TableCell>
                        {format(event.created_at, 'DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(Packing);
