import React from 'react';
import { TablePagination } from '@material-ui/core';

export default function pagination({ pagination, changePage }) {
  const currentPage = pagination.currentPage
    ? pagination.currentPage
    : pagination.current_page;

  const rowsPerPage = pagination.perPage
    ? pagination.perPage
    : pagination.per_page;

  return (
    <TablePagination
      component="div"
      page={currentPage - 1}
      rowsPerPageOptions={[]}
      count={Number(pagination.total)}
      rowsPerPage={Number(rowsPerPage)}
      onPageChange={(event, page) => changePage(page + 1)}
    />
  );
}
