import {
  Card,
  Grid,
  Paper,
  Table,
  Button,
  Switch,
  Divider,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  FormGroup,
  TableHead,
  Typography,
  CardContent,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import Cookies from 'js-cookie';
import { request } from '../helpers';
import Info from '../components/Infos/Info';
import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading/Loading';
import WarehouseInput from '../components/Inputs/WarehouseInput';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import WaveOptimizationOrders from '../components/WaveOptimization/WaveOptimizationOrders';
import WarehouseParameters from '../components/WaveOptimization/WarehouseParameters';

interface Message {
  error?: string;
  success?: string;
}

interface Optimization {
  branchCode?: string;
  minimalWave?: string;
  warehouseCode?: string;
  optimizationKey?: string;
}

interface Waves {
  id?: number;
  type?: string;
  orders?: number;
  isChecked?: boolean;
  upTo5Items?: number;
  averageAge?: number;
  upTo3Items?: number;
  oldestDate?: string;
  aggregation?: string;
  description?: string;
  moreThan5Items?: number;
}

interface LastWaveOptimization {
  username?: string;
  createdAt?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    reset: {
      margin: 0,
      padding: 0,
      borderSizing: 'border-box',
    },
    cardAndButtonContainer: {
      width: '100%',
    },
    buttonContainer: {
      marginTop: 5,
      display: 'flex',
      padding: '2px 15px 10px',
      justifyContent: 'flex-end',
      boxShadow: '2px 2px 8px #3f51b5',
      backgroundColor: theme.palette.grey['300'],
    },
    buttonContainerWithoutHeader: {
      width: '100%',
      display: 'flex',
      paddingLeft: 10,
      justifyContent: 'space-between',
      boxShadow: '2px 2px 8px #3f51b5',
      backgroundColor: theme.palette.grey['300'],
    },
    button: {
      width: '65%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      justifyContent: 'space-evenly',
    },
    buttonWithoutHeader: {
      width: '55%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    cardContainer: {
      height: '70%',
      marginTop: -10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cardSet: {
      width: '13%',
      height: '85%',
      '&:hover': {
        transform: 'translateY(-1.5px)',
      },
    },
    cardTitle: {
      padding: 0,
      textTransform: 'uppercase',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
    },
    cardContent: {
      display: 'grid',
      paddingTop: 13,
      placeItems: 'start center',
    },
    inputSelect: {
      height: 0,
      width: '10.4em',
      paddingTop: 14,
      paddingBottom: 10,
    },
    inputSelectWithoutHeader: {
      height: 0,
      width: '9.8em',
      paddingTop: 14,
      paddingBottom: 10,
      backgroundColor: theme.palette.grey['200'],
    },
    divideHeaderInside: {
      width: '95%',
      margin: '6px 0 14px 0',
      backgroundColor: '#8ac4ff',
    },
    divideHeader: {
      marginTop: 15,
      marginBottom: 15,
      backgroundColor: '#8ac4ff',
    },

    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      border: 'solid #8294b8 2px',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    row: {
      height: 30,
      '&:nth-child(odd)': {
        background: theme.palette.grey['100'],
      },
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.grey['300'],
      },
    },
    rowMain: {
      height: 35,
      backgroundColor: '#e0f0ff !important',
    },
    columnReduceSize: {
      padding: '4px 24px 4px 15px',
    },
  });

const WaveOptimized = ({ classes }) => {
  const [waves, setWaves] = useState<Waves[]>([]);
  const [message, setMessage] = useState<Message>({});
  const [toggleHeader, setToggleHeader] = useState(true);
  const [waveOptimizationOrders, setWaveOptimizationOrders] = useState<object>(
    {},
  );
  const [waveGenerate, setWaveGenerate] = useState<Waves[]>([]);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const [waveOptimizationWarehouse, setWaveOptimizationWarehouse] = useState<
    Optimization
  >({});
  const [disabledCheckBoxAndButton, setDisabledCheckBoxAndButton] = useState<
    boolean
  >(true);
  const [lastWaveOptimization, setLastWaveOptimization] = useState<
    LastWaveOptimization
  >({});
  const [toggleOrdersModal, setToggleOrdersModal] = useState<boolean>(false);

  moment.updateLocale(moment.locale(), { invalidDate: '-' });

  const optimizationKey = 'Pepper';
  const { error, success } = message;
  const renderMessage = success ? success : error;

  const kit = 'KIT';
  const preKit = 'PARTIAL_KIT';
  const conveyorBelt = 'MIXED';
  const uniqueProduct = 'SINGLE_SKU';
  const conveyorBeltWithoutLimit = 100000;

  const fields = [
    'Seleção',
    'Onda',
    'Descrição',
    'Pedidos',
    'Até 3',
    'Até 5',
    '> 5',
    '+ Antigo',
    'Media Dias',
  ];

  const cards = [
    {
      title: 'Selecionados',
      value: waveGenerate.length,
    },
    {
      title: 'Seleção Pedidos',
      value: waveGenerate.reduce(
        (total, wave) => total + Number(wave.orders),
        0,
      ),
    },
    {
      title: 'Agrupamentos',
      value: waves.length,
    },
    {
      title: 'Pedidos',
      value: waves
        ? waves
            .filter(element => element.orders)
            .reduce((total, current) => total + Number(current.orders), 0)
        : 0,
    },
    {
      title: 'Até 3',
      value: waves
        ? waves
            .filter(element => element.upTo3Items)
            .reduce((total, current) => total + Number(current.upTo3Items), 0)
        : 0,
    },
    {
      title: 'Até 5',
      value: waves
        ? waves
            .filter(element => element.upTo5Items)
            .reduce((total, current) => total + Number(current.upTo5Items), 0)
        : 0,
    },
    {
      title: '> 5',
      value: waves
        ? waves
            .filter(element => element.moreThan5Items)
            .reduce(
              (total, current) => total + Number(current.moreThan5Items),
              0,
            )
        : 0,
    },
  ];

  const checkField = (label: string) => {
    return (
      <FormControl>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            label={label}
            labelPlacement="end"
            style={{ marginLeft: 0 }}
            disabled={disabledCheckBoxAndButton}
            onChange={(e: any) => handleAllChecked(e)}
            control={<Checkbox color="primary" className={classes.reset} />}
          />
        </FormGroup>
      </FormControl>
    );
  };

  const handleGenerateOptimization = async () => {
    try {
      setToggleLoading(true);
      setWaves([]);

      const { warehouseCode } = waveOptimizationWarehouse;

      if (!warehouseCode) {
        return setMessage({ error: 'Armazém inválido' });
      }

      const { warehouse } = (
        await request('GET:/api/warehouse', { warehouseCode })
      ).data;

      const waveConfig = JSON.parse(warehouse.waveConfig);

      const MinimumAggregation =
        waveConfig && waveConfig.optimization.StockMovementManagement
          ? waveConfig.optimization.StockMovementManagement.MinimumAggregation
          : undefined;

      if (!MinimumAggregation) {
        setDisabledCheckBoxAndButton(true);
        setMessage({
          error: `Armazém ${warehouseCode} sem parâmetro cadastrado: Mínimo pedidos para agrupamento de onda`,
        });
        return console.error(
          'Erro: É necessário cadastrar o parâmetro "MinimumAggregation" na coluna wave_config do armazém',
        );
      }

      const data = {
        warehouse_code: warehouseCode,
        username: Cookies.get('username'),
        optimization_key: optimizationKey,
        minimum_aggregation: MinimumAggregation,
        branch_code: warehouse.branches.map(branch => branch.code).toString(),
      };

      const generateOptimization = await request(
        'POST:/api/wave-optimization/generate',
        data,
      );

      if (!generateOptimization) {
        return setMessage({ error: 'Falha ao gerar otimização' });
      }

      const { waves } = (
        await request('GET:/api/wave/optimization', {
          data,
        })
      ).data;

      for (const wave of waves) {
        wave.isChecked = false;
      }
      setWaves(waves);
      setDisabledCheckBoxAndButton(false);
    } catch (error) {
      console.error(Error, error);
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setWaveOptimizationWarehouse({
      ...waveOptimizationWarehouse,
      [name]: value,
    });
  };

  const listFilter = (array: Waves[], filteredType: string) => {
    array = array.filter(element => element.type === filteredType);

    return array;
  };

  const handleCheckbox = async (event, wave) => {
    const { checked } = event.target;
    const waveIndex = waveGenerate.indexOf(wave);

    wave.isChecked = checked;

    waves[waveIndex] = wave;

    setWaves(waves);

    if (!checked) {
      waveGenerate.splice(waveIndex, 1);
      return setWaveGenerate([...waveGenerate]);
    }

    setWaveGenerate([...waveGenerate, wave]);
  };

  const handleAllChecked = event => {
    const { checked } = event.target;

    if (!checked) {
      setWaveGenerate([]);
    }

    if (checked) {
      setWaveGenerate([]);
      setWaveGenerate([...waves]);
    }

    for (const wave of waves) {
      wave.isChecked = checked;
    }

    setWaves([...waves]);
  };

  const handleWaveGenerate = async () => {
    try {
      setToggleLoading(true);

      if (waveGenerate.length <= 0) {
        return setMessage({
          error:
            'Atenção: Nenhuma agregação foi selecionada para criação de ondas',
        });
      }
      const { warehouseCode } = waveOptimizationWarehouse;

      const { warehouse } = (
        await request('GET:/api/warehouse', { warehouseCode })
      ).data;

      const waveConfig = JSON.parse(warehouse.waveConfig);

      let items: any = [];

      for (const wave of waveGenerate) {
        const item = {
          type: wave.type,
          aggregation: wave.aggregation,
          description: wave.description,
          maximum_order: 100,
        };

        if (item.type === uniqueProduct) {
          item.maximum_order =
            waveConfig.optimization.type.SINGLE_SKU.maximum_order;
        }

        if (item.type === preKit) {
          item.maximum_order =
            waveConfig.optimization.type.PARTIAL_KIT.maximum_order;
        }

        if (item.type === conveyorBelt) {
          item.maximum_order = conveyorBeltWithoutLimit;
        }

        if (item.type === kit) {
          if (Number(wave.upTo3Items) > 0) {
            item.maximum_order =
              waveConfig.optimization.type.KIT.KIT_P.maximum_order;
          } else if (Number(wave.upTo5Items) > 0) {
            item.maximum_order =
              waveConfig.optimization.type.KIT.KIT_M.maximum_order;
          } else {
            item.maximum_order =
              waveConfig.optimization.type.KIT.KIT_G.maximum_order;
          }
        }

        items.push(item);
      }

      const generateWaves = (
        await request('POST:/api/wave-optimization/generate-wave', {
          items: items,
          warehouse_code: warehouseCode,
          optimization_key: optimizationKey,
          branch_code: warehouse.branches.map(branch => branch.code).toString(),
        })
      ).data;

      if (!generateWaves) {
        return setMessage({ error: 'Erro na criação das Ondas' });
      }

      if (generateWaves) {
        setMessage({ success: 'Ondas geradas com sucesso' });

        const generaWavesSummaryPdf = await request(
          'GET:/api/wave-optimization/generate-wave-summary',
          {
            ...generateWaves,
            generationDate: moment().format('DD/MM/YY HH:mm:ss'),
          },
        );

        window.open(generaWavesSummaryPdf.request.responseURL);
      }
    } catch (error) {
      console.error('Error', error);
      throw new Error(error);
    } finally {
      setWaves([]);
      setWaveGenerate([]);
      setToggleLoading(false);
      setWaveOptimizationWarehouse({});
      setDisabledCheckBoxAndButton(true);
    }
  };

  const handleWareHouseInput = () => {
    return (
      <WarehouseInput
        variant="outlined"
        name="warehouseCode"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            input: toggleHeader
              ? classes.inputSelect
              : classes.inputSelectWithoutHeader,
          },
        }}
        value={waveOptimizationWarehouse.warehouseCode || ''}
      />
    );
  };

  const fetchLastWaveOptimization = async () => {
    try {
      setToggleLoading(true);

      const { waveOptimization } = (
        await request('GET:/api/wave-optimization/get-last', {})
      ).data;

      setLastWaveOptimization(waveOptimization);
    } catch (error) {
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  useEffect(() => {
    fetchLastWaveOptimization();
  }, []);

  const handleOpenModal = waveOptimizationOrders => {
    setWaveOptimizationOrders(waveOptimizationOrders);
    setToggleOrdersModal(!toggleOrdersModal);
  };

  const unselectedWarehouseError = () => {
    const { warehouseCode } = waveOptimizationWarehouse;

    if (!warehouseCode) {
      setMessage({ error: 'Armazém inválido' });
      return true;
    }

    return false;
  };

  return (
    <>
      {toggleLoading && <Loading />}

      {renderMessage && (
        <Info
          type={!error ? 'success' : 'error'}
          onClose={() => setMessage({ error: undefined, success: undefined })}
        >
          {renderMessage}
        </Info>
      )}

      {toggleOrdersModal && (
        <WaveOptimizationOrders
          handleAction={handleOpenModal}
          wave={waveOptimizationOrders}
        />
      )}

      <header>
        <Grid container>
          {toggleHeader && (
            <Grid
              xs
              item
              container
              justify="center"
              direction="column"
              alignItems="flex-start"
            >
              <Typography>Última Otimização</Typography>
              <Typography variant="h6">
                {moment(lastWaveOptimization.createdAt).format(
                  'DD/MM/YY HH:mm:ss',
                )}
              </Typography>
              <Typography>Usuário</Typography>
              <Typography variant="h6">
                {lastWaveOptimization.username &&
                  lastWaveOptimization.username
                    .split('@')
                    .slice(0, 1)
                    .join('')}
              </Typography>

              <Divider
                variant="middle"
                className={classes.divideHeaderInside}
              />
              {handleWareHouseInput()}
            </Grid>
          )}

          <Grid
            item
            container
            justify="center"
            direction="column"
            alignItems="center"
            lg={toggleHeader ? 10 : 12}
          >
            <section className={classes.cardAndButtonContainer}>
              {toggleHeader && (
                <div className={classes.cardContainer}>
                  {cards.map(card => (
                    <Card key={card.title} className={classes.cardSet}>
                      <CardContent className={classes.cardTitle}>
                        <Typography
                          align="center"
                          variant="body1"
                          className={classes.cardTitle}
                        >
                          {card.title}
                        </Typography>
                      </CardContent>

                      <CardContent className={classes.cardContent}>
                        <Typography variant="body2">{card.value}</Typography>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}

              <div
                className={
                  toggleHeader
                    ? classes.buttonContainer
                    : classes.buttonContainerWithoutHeader
                }
              >
                {!toggleHeader && handleWareHouseInput()}
                <div
                  className={
                    toggleHeader ? classes.button : classes.buttonWithoutHeader
                  }
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          onChange={() => setToggleHeader(!toggleHeader)}
                        />
                      }
                      label={
                        toggleHeader ? 'Esconder resumo' : 'Mostrar resumo'
                      }
                    />
                  </FormGroup>

                  <WarehouseParameters
                    warehouseError={unselectedWarehouseError}
                    warehouse={waveOptimizationWarehouse.warehouseCode}
                  />

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleGenerateOptimization}
                  >
                    Gerar Otimização
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleWaveGenerate}
                    disabled={disabledCheckBoxAndButton}
                  >
                    Gerar Ondas
                  </Button>
                </div>
              </div>
            </section>
          </Grid>
        </Grid>
      </header>
      <Divider variant="fullWidth" className={classes.divideHeader} />
      <main>
        <Paper className={classes.root}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow
                style={{
                  height: 40,
                  padding: '4px 24px 4px 24px',
                }}
              >
                {fields.map((field, index) => {
                  return (
                    <TableCell
                      key={index}
                      className={cn(classes.tableHeaderText)}
                    >
                      {field}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* PRODUTO ÚNICO */}
              <TableRow className={cn(classes.row, classes.rowMain)}>
                {[
                  checkField('Tudo'),
                  'PRODUTO ÚNICO',
                  '',
                  listFilter(waves, uniqueProduct).reduce(
                    (total, current) => total + Number(current.orders),
                    0,
                  ),
                  listFilter(waves, uniqueProduct).reduce(
                    (total, current) => total + Number(current.upTo3Items),
                    0,
                  ),
                  listFilter(waves, uniqueProduct).reduce(
                    (total, current) => total + Number(current.upTo5Items),
                    0,
                  ),
                  listFilter(waves, uniqueProduct).reduce(
                    (total, current) => total + Number(current.moreThan5Items),
                    0,
                  ),
                  '',
                  '',
                ].map((element, index) => (
                  <TableCell key={index} className={classes.columnReduceSize}>
                    <b>{element}</b>
                  </TableCell>
                ))}
              </TableRow>

              {listFilter(waves, uniqueProduct).map((wave, index) => {
                return (
                  <TableRow key={index} className={classes.row}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={wave.isChecked}
                        className={classes.reset}
                        onChange={event => handleCheckbox(event, wave)}
                      />
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.type}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.description &&
                        wave.description
                          .split('|')
                          .slice(0, 3)
                          .join(' | ')}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.orders}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo3Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.moreThan5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {moment(wave.oldestDate || '-').format(
                        'DD/MM/YYYY HH:MM:ss',
                      )}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.averageAge}
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* KIT */}
              <TableRow className={cn(classes.row, classes.rowMain)}>
                {[
                  '',
                  'KIT',
                  '',
                  listFilter(waves, kit).reduce(
                    (total, current) => total + Number(current.orders),
                    0,
                  ),
                  listFilter(waves, kit).reduce(
                    (total, current) => total + Number(current.upTo3Items),
                    0,
                  ),
                  listFilter(waves, kit).reduce(
                    (total, current) => total + Number(current.upTo5Items),
                    0,
                  ),
                  listFilter(waves, kit).reduce(
                    (total, current) => total + Number(current.moreThan5Items),
                    0,
                  ),
                  '',
                  '',
                ].map((element, index) => (
                  <TableCell key={index}>
                    <b>{element}</b>
                  </TableCell>
                ))}
              </TableRow>

              {listFilter(waves, kit).map((wave, index) => {
                return (
                  <TableRow key={index} className={classes.row}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={wave.isChecked}
                        className={classes.reset}
                        onChange={event => handleCheckbox(event, wave)}
                      />
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.type}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.description &&
                        wave.description
                          .split('|')
                          .slice(0, 3)
                          .join(' | ')}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.orders}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo3Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.moreThan5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {moment(wave.oldestDate || '-').format(
                        'DD/MM/YYYY HH:MM:ss',
                      )}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.averageAge}
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* PRE KIT */}
              <TableRow className={cn(classes.row, classes.rowMain)}>
                {[
                  '',
                  'PRÉ KIT',
                  '',
                  listFilter(waves, preKit).reduce(
                    (total, current) => total + Number(current.orders),
                    0,
                  ),
                  listFilter(waves, preKit).reduce(
                    (total, current) => total + Number(current.upTo3Items),
                    0,
                  ),
                  listFilter(waves, preKit).reduce(
                    (total, current) => total + Number(current.upTo5Items),
                    0,
                  ),
                  listFilter(waves, preKit).reduce(
                    (total, current) => total + Number(current.moreThan5Items),
                    0,
                  ),
                  '',
                  '',
                ].map((element, index) => (
                  <TableCell key={index}>
                    <b>{element}</b>
                  </TableCell>
                ))}
              </TableRow>

              {listFilter(waves, preKit).map((wave, index) => {
                return (
                  <TableRow key={index} className={classes.row}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={wave.isChecked}
                        className={classes.reset}
                        onChange={event => handleCheckbox(event, wave)}
                      />
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.type}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.description &&
                        wave.description
                          .split('|')
                          .slice(0, 3)
                          .join(' | ')}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.orders}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo3Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.moreThan5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {moment(wave.oldestDate || '-').format(
                        'DD/MM/YYYY HH:MM:ss',
                      )}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.averageAge}
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* ESTEIRA */}
              <TableRow className={cn(classes.row, classes.rowMain)}>
                {[
                  '',
                  'ESTEIRA',
                  '',
                  listFilter(waves, conveyorBelt).reduce(
                    (total, current) => total + Number(current.orders),
                    0,
                  ),
                  listFilter(waves, conveyorBelt).reduce(
                    (total, current) => total + Number(current.upTo3Items),
                    0,
                  ),
                  listFilter(waves, conveyorBelt).reduce(
                    (total, current) => total + Number(current.upTo5Items),
                    0,
                  ),
                  listFilter(waves, conveyorBelt).reduce(
                    (total, current) => total + Number(current.moreThan5Items),
                    0,
                  ),
                  '',
                  '',
                ].map((element, index) => (
                  <TableCell key={index}>
                    <b>{element}</b>
                  </TableCell>
                ))}
              </TableRow>

              {listFilter(waves, conveyorBelt).map((wave, index) => {
                return (
                  <TableRow key={index} className={classes.row}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={wave.isChecked}
                        className={classes.reset}
                        onChange={event => handleCheckbox(event, wave)}
                      />
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.type}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.description &&
                        wave.description
                          .split('|')
                          .slice(0, 3)
                          .join(' | ')}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.orders}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo3Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.upTo5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.moreThan5Items}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {moment(wave.oldestDate || '-').format(
                        'DD/MM/YYYY HH:MM:ss',
                      )}
                    </TableCell>

                    <TableCell onClick={() => handleOpenModal(wave)}>
                      {wave.averageAge}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </main>
    </>
  );
};

export default withStyles(styles)(WaveOptimized);
