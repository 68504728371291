import {
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Info from '../components/Infos/Info';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputContainer: {
      padding: '2px 5px',
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  orders: any[];
  anchorEl?: any;
  success?: string;
  error?: string;
  filters: any;
}

class Orders extends React.Component<Props, State> {
  state = {
    loading: true,
    orders: [],
    anchorEl: null,
    filters: {},
  };

  async componentDidMount() {
    this.setState({ loading: false });
  }

  handleActionsMenuClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleActionsMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = e => {
    const { name, value } = e.target;

    const { filters } = this.state;

    filters[name] = value;

    this.setState({ filters });

    this.getOrders(filters);
  };

  getOrders = async (filters): Promise<void> => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      ...filters,
    };

    this.setState({ loading: true });

    const response = (await request('get:/api/orders/list', options)).data;

    this.setState({ orders: response.orders, loading: false });
  };

  reprintLabel = async (deliveryId): Promise<void> => {
    this.handleActionsMenuClose();

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      id: deliveryId,
    };

    try {
      await request('get:/api/delivery/reprint-label', options);

      this.setState({
        success: 'Impressão gerada com sucesso',
        error: undefined,
      });
    } catch (e) {
      this.setState({
        success: undefined,
        error: 'Houve um erro ao gerar a impressão',
      });
    }
  };

  reprintInvoice = async (deliveryId): Promise<void> => {
    this.handleActionsMenuClose();

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      id: deliveryId,
    };

    try {
      (await request('get:/api/delivery/reprint-invoice', options)).data;

      this.setState({
        success: 'Impressão gerada com sucesso',
        error: undefined,
      });
    } catch (e) {
      this.setState({
        success: undefined,
        error: 'Houve um erro ao gerar a impressão',
      });
    }
  };

  render() {
    const { loading, orders, anchorEl, success, error }: any = this.state;
    const { classes }: any = this.props;

    return (
      <div>
        <Info
          type="success"
          onClose={() => this.setState({ success: undefined })}
        >
          {success}
        </Info>

        <Info type="error" onClose={() => this.setState({ error: undefined })}>
          {error}
        </Info>

        <div>{loading && <Loading />}</div>

        <Grid container>
          <Grid item xs={2} className={classes.inputContainer}>
            <TextField
              name="order_id"
              label="Pedido"
              margin="normal"
              autoFocus
              onChange={this.handleChange}
              autoComplete={'off'}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={2} className={classes.inputContainer}>
            <TextField
              name="invoice"
              label="Nota"
              margin="normal"
              autoFocus
              onChange={this.handleChange}
              autoComplete={'off'}
              fullWidth={true}
            />
          </Grid>
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pedido</TableCell>
              <TableCell>NF</TableCell>
              <TableCell>Romaneio</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order: any) => (
              <TableRow key={order.id}>
                <TableCell>{order.orderId}</TableCell>
                <TableCell>
                  {order.invoiceNumber} / {order.invoiceSerie}
                </TableCell>
                <TableCell>{order.preShipmentId}</TableCell>
                <TableCell>
                  <IconButton
                    aria-haspopup="true"
                    onClick={this.handleActionsMenuClick}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleActionsMenuClose}
                  >
                    <MenuItem onClick={() => this.reprintLabel(order.id)}>
                      <ListItemIcon>
                        <Icon>print</Icon>
                      </ListItemIcon>
                      <ListItemText inset primary="Reimprimir Etiqueta" />
                    </MenuItem>

                    <MenuItem onClick={() => this.reprintInvoice(order.id)}>
                      <ListItemIcon>
                        <Icon>print</Icon>
                      </ListItemIcon>
                      <ListItemText inset primary="Reimprimir NF" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(Orders);
