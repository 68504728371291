import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import React, { Component, FormEvent } from 'react';
import { request } from '../helpers';
import Cookies from 'js-cookie';
import Loading from '../components/Loading/Loading';
import Queue from 'promise-queue';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      padding: theme.spacing(3),
      width: '95%',
      margin: 'auto',
    },
    headerContainer: {
      width: '95%',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    inputBar: {
      flexBasis: '60%',
    },
    titleSpan: {
      fontWeight: 300,
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface TableItem {
  barcode?: string;
  sku?: string;
  description?: string;
  deliveryQty?: number;
  checkoutQty?: number;
}

interface State {
  toggleLoading: boolean;
  input: string;
  invoiceNumber: string;
  alertDeliveryRequest: boolean;
  alertPackingRequest: boolean;
  delivery: any;
  items: TableItem[];
  barcodeToSKU: { [barcode: string]: string };
  barcodeQueue: string[];
  alertOpen: boolean;
  done: boolean;
}

const initialState = {
  toggleLoading: false,
  input: '',
  alertDeliveryRequest: false,
  alertPackingRequest: false,
  invoiceNumber: '',
  delivery: {},
  items: [],
  barcodeToSKU: {},
  barcodeQueue: [],
  alertOpen: false,
  done: false,
};

class Checkout extends Component<Props, State> {
  state: State = initialState;
  // promiseQueue = new PromiseQueue();

  promiseQueue = new Queue(1);

  tableFields = [
    {
      key: 'barcode',
      text: 'Cód. Barras',
    },
    {
      key: 'sku',
      text: 'SKU',
    },
    {
      key: 'description',
      text: 'Descrição',
    },
    {
      key: 'deliveryQty',
      text: 'Qtd. no pedido',
    },
    {
      key: 'checkoutQty',
      text: 'Qtd. bipado',
    },
  ];

  handleChange = event => {
    this.setState({ input: event.target.value });
  };

  handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const input = this.state.input;

    if (!this.state.invoiceNumber) {
      this.setState({ invoiceNumber: input, input: '' });
      return this.promiseQueue.add(() => this.orderRequest(input));
    }

    const barcodeQueue = this.state.barcodeQueue;
    barcodeQueue.push(input);

    this.setState({ input: '', barcodeQueue });
    return this.promiseQueue.add(() => this.itemRequest(input));
  };

  async orderRequest(invoiceNumber: string) {
    const options = {
      warehouse_code: Cookies.get('warehouse'),
      invoice_key: invoiceNumber,
    };
    let delivery;
    try {
      const deliveryRequest = (
        await request('get:/api/delivery/find-by-invoice', options)
      ).data;

      delivery = deliveryRequest.delivery;
    } catch (error) {
      this.setState({ invoiceNumber: '', alertDeliveryRequest: true });
      return;
    }

    const { deliveryItems } = (
      await request('GET:/api/delivery/item', { id: delivery.id })
    ).data;

    const items = deliveryItems.reduce((items: any[], item: any) => {
      const idx = items.findIndex(i => i.sku === item.sku);

      const itemFormated = {
        description: item.productDescription,
        sku: item.sku,
        deliveryQty: item.qty,
        checkoutQty: 0,
      };

      if (idx === -1) return [...items, itemFormated];
      items[idx].deliveryQty += itemFormated.deliveryQty;
      return items;
    }, []);

    this.setState({ delivery, items });
  }

  async itemRequest(barcode: string) {
    const barcodeQueue = this.state.barcodeQueue;
    barcodeQueue.splice(barcodeQueue.indexOf(barcode), 1);
    this.setState({ barcodeQueue });

    const item = this.state.items.find(
      tableItem => tableItem.barcode === barcode,
    );

    if (item) {
      let checkoutQty = item.checkoutQty ? item.checkoutQty : 0;
      return this.setItem({ barcode, checkoutQty: checkoutQty + 1 });
    }

    const { product } = (
      await request('GET:/api/product/find-by-barcode', { barcode })
    ).data;

    if (!product) {
      this.setState({ alertOpen: true });
      return;
    }

    this.setItem({
      barcode,
      sku: product.sku,
      description: product.description,
      checkoutQty: 1,
    });
  }

  setItem(item: TableItem) {
    let itemFinded = false;
    let alert = false;

    const items = this.state.items.map(tableItem => {
      if (tableItem.barcode == item.barcode || tableItem.sku == item.sku) {
        if (tableItem.deliveryQty! < item.checkoutQty!) {
          alert = true;
          return tableItem;
        }

        itemFinded = true;
        return { ...tableItem, ...item };
      }

      return tableItem;
    });

    if (!itemFinded) {
      alert = true;
    }
    if (alert) return this.setState({ alertOpen: true });

    let done = true;
    for (const item of items) {
      if (item.checkoutQty !== item.deliveryQty) done = false;
    }

    this.setState({ items, done });
  }

  checkoutRequest = async () => {
    try {
      this.setState({ toggleLoading: true });

      await request('GET:/api/packing/pack', {
        id: this.state.delivery.id,
      });

      this.setState(initialState);
    } catch (e) {
      this.setState({ toggleLoading: false, alertPackingRequest: true });
    }
  };

  handleClose = () => {
    this.setState({
      alertOpen: false,
      alertDeliveryRequest: false,
      alertPackingRequest: false,
    });
  };

  handleCloseInitialState = () => {
    this.setState(initialState);
  };

  render() {
    const { classes }: Props = this.props;
    const { delivery, invoiceNumber, items, input, barcodeQueue }: State =
      this.state;

    return (
      <>
        {this.state.toggleLoading && <Loading />}
        <Dialog open={this.state.alertOpen} onClose={this.handleClose}>
          <DialogTitle>Erro!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Este produto não existe no pedido ou a quantidade é inválida!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.alertDeliveryRequest}
          onClose={this.handleClose}
        >
          <DialogTitle>Erro!</DialogTitle>
          <DialogContent>
            <DialogContentText>Nota Fiscal não encontrada!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.alertPackingRequest}
          onClose={this.handleCloseInitialState}
        >
          <DialogTitle>Erro!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pedido não está no status correto para realizar o checkout!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseInitialState} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.root}>
          <form
            onSubmit={this.handleSubmit}
            className={classes.headerContainer}
          >
            <Typography variant="h4" color="inherit">
              Checkout
            </Typography>

            {!this.state.done ? (
              <>
                <TextField
                  type="text"
                  variant="outlined"
                  value={input}
                  label={
                    this.state.invoiceNumber
                      ? 'Código do item'
                      : 'Chave da Nota Fiscal'
                  }
                  className={classes.inputBar}
                  onChange={this.handleChange}
                />

                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                >
                  Inicia
                </Button>
              </>
            ) : (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={this.checkoutRequest}
                >
                  Finalizar pedido
                </Button>
              </>
            )}
          </form>

          <Divider
            variant="middle"
            style={{ margin: '20px auto ', width: '95%' }}
          />

          <Grid container className={classes.headerContainer}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">
                Pedido:{' '}
                <span className={classes.titleSpan}>
                  {invoiceNumber && !delivery ? (
                    <LinearProgress />
                  ) : (
                    delivery.orderId
                  )}
                </span>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6">
                Transportadora:{' '}
                <span className={classes.titleSpan}>
                  {this.state.delivery!.carrierCode}
                </span>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6">
                NF:
                <span className={classes.titleSpan}>{invoiceNumber}</span>
              </Typography>
            </Grid>
          </Grid>

          <List
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflow: 'auto',
              margin: 0,
            }}
          >
            {barcodeQueue.map((field, key) => {
              return (
                <Chip key={key} label={field} style={{ marginRight: 2 }} />
              );
            })}
          </List>
        </Paper>

        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '95%' }}
        />

        <Paper className={classes.root}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableHeaderText}>Item</TableCell>
                <TableCell className={classes.tableHeaderText}>
                  Cód. Barras
                </TableCell>
                <TableCell className={classes.tableHeaderText}>SKU</TableCell>
                <TableCell className={classes.tableHeaderText}>
                  Descrição
                </TableCell>
                <TableCell className={classes.tableHeaderText}>
                  Qtd. no pedido
                </TableCell>
                <TableCell className={classes.tableHeaderText}>
                  Qtd. bipado
                </TableCell>
                <TableCell className={classes.tableHeaderText}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{item['barcode']}</TableCell>
                    <TableCell>{item['sku']}</TableCell>
                    <TableCell>{item['description']}</TableCell>
                    <TableCell>{item['deliveryQty']}</TableCell>
                    <TableCell>{item['checkoutQty']}</TableCell>
                    <TableCell>
                      {item['deliveryQty'] === item['checkoutQty'] ? (
                        <Check />
                      ) : (
                        <Close />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(Checkout);
