import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import {
  Paper,
  Table,
  Dialog,
  Button,
  Divider,
  TableRow,
  TextField,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  DialogContentText,
} from '@material-ui/core';
import moment from 'moment';
import cn from 'classnames';
import { request } from '../helpers';
import Info from '../components/Infos/Info';
import { RouteComponentProps } from 'react-router';
import Loading from '../components/Loading/Loading';
import {
  Search,
  VpnKeyTwoTone,
  HourglassFullTwoTone,
} from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    searchContainer: {
      width: '95%',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    searchBar: {
      flexBasis: '60%',
    },
    cardHeader: {
      height: 40,
      minWidth: 150,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '30px 0',
      justifyContent: 'center',
      backgroundColor: '#3f51b5',
    },
    cardSet: {
      width: 600,
      height: 200,
      borderRadius: '30px 2px 2px 2px',
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    buttonPanel: {
      height: 55,
      padding: 10,
      width: '100%',
      marginTop: 25,
      backgroundColor: theme.palette.grey['300'],
    },
    cardsContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    centralize: {
      height: 60,
      paddingTop: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    row: {
      '&:nth-child(odd)': {
        background: theme.palette.grey['100'],
      },
      '&:hover': {
        background: theme.palette.grey['300'],
      },
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles {}

interface State {
  open: boolean;
  error?: string;
  success?: string;
  loading: boolean;
  saleInvoice: any;
  fields: string[];
  invoiceKey: string;
  reverseInvoice: any;
  toggleReturnedInvoice?: boolean;
  deliveryReceived?: boolean;
}

class ReverseInvoice extends React.Component<Props, State> {
  state = {
    open: false,
    loading: false,
    invoiceKey: '',
    saleInvoice: {},
    reverseInvoice: {},
    fields: ['Item', 'SKU', 'Descrição', 'Quantidade', 'Preço'],
  };

  handleChange = async event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as any);
  };

  fetchInvoices = async () => {
    const { invoiceKey } = this.state;

    if (!invoiceKey) {
      return;
    }

    this.loading();

    try {
      const { saleInvoice, reverseInvoice, delivery } = (
        await request('/api/reverse-logistics/invoices', {
          invoiceKey: invoiceKey,
        })
      ).data.invoices;

      if (!saleInvoice) {
        this.setState({
          error: 'Nota fiscal de venda não encontrada',
        });
        this.loading();
        return;
      }

      if (delivery && delivery.status.code === 'DELIVERY_RECEIVED') {
        this.setState({
          error: 'Nota Fiscal de venda já recebida',
          deliveryReceived: true,
        });
      }

      if (!delivery) {
        this.setState({
          deliveryReceived: false,
        });
      }

      this.setState({
        saleInvoice,
        loading: false,
        reverseInvoice,
        toggleReturnedInvoice: true,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  loading = () => {
    this.setState({ loading: !this.state.loading });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleSubmit = async () => {
    this.loading();
    const { invoiceKey } = this.state;

    try {
      await request('/api/reverse-logistics/generate', {
        invoiceKey: invoiceKey,
      });

      this.setState({
        success:
          'Nota Fiscal de devolução gerada, clique em "Buscar" para visualizá-la',
      });

      this.handleToggle();
      this.loading();
    } catch (error) {
      const { message } = error.response.data;
      console.error('Error: ', message);
      this.handleToggle();
      this.loading();

      this.setState({
        error: message,
      });
    }
  };

  render() {
    const {
      open,
      error,
      fields,
      success,
      loading,
      saleInvoice,
      reverseInvoice,
      toggleReturnedInvoice,
      deliveryReceived,
    }: State = this.state;
    const { classes }: Props = this.props;

    const orderNumber =
      Object.keys(saleInvoice).length === 0
        ? ''
        : String(saleInvoice.invoiceItems[0].prodXped);

    const items =
      Object.keys(saleInvoice).length !== 0
        ? [...saleInvoice.invoiceItems]
        : [];

    const itemPrice = (price: number): string =>
      price.toString().replace('.', ',');

    const message = success ? success : error;

    return (
      <>
        {loading && <Loading />}

        <header>
          <Paper className={classes.root}>
            <div className={classes.searchContainer}>
              <TextField
                type="number"
                name="invoiceKey"
                variant="outlined"
                className={classes.searchBar}
                label="Buscar nota fiscal de venda"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    this.fetchInvoices();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyTwoTone fontSize="small" />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <Button
                      size="medium"
                      color="primary"
                      variant="contained"
                      onClick={this.fetchInvoices}
                    >
                      <Search />
                    </Button>
                  ),
                }}
                onChange={event => this.handleChange(event)}
              />
            </div>
          </Paper>
        </header>

        {message && (
          <>
            <Divider
              variant="middle"
              style={{ margin: '5px auto ', width: '95%' }}
            />

            <Info
              type={!error ? 'success' : 'error'}
              onClose={() =>
                this.setState({ error: undefined, success: undefined })
              }
            >
              {message}
            </Info>
          </>
        )}

        <Divider
          variant="middle"
          style={{ margin: '5px auto ', width: '95%' }}
        />

        <main>
          <Paper className={cn(classes.root, classes.cardsContainer)}>
            <Paper className={cn(classes.root, classes.paper, classes.cardSet)}>
              <div className={classes.cardHeader}>
                <Typography variant="h6" style={{ color: 'white' }}>
                  Nota Fiscal - Venda
                </Typography>
              </div>

              {Object.keys(saleInvoice).length > 1 ? (
                <>
                  <div>
                    <Typography variant="h6">
                      Cliente: {saleInvoice.destXnome}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="h6">Pedido: {orderNumber}</Typography>
                  </div>

                  <div>
                    <Typography variant="h6">
                      Número: {saleInvoice.number}
                    </Typography>
                  </div>
                </>
              ) : null}
            </Paper>

            <Paper className={cn(classes.root, classes.paper, classes.cardSet)}>
              <div className={classes.cardHeader}>
                <Typography variant="h6" style={{ color: 'white' }}>
                  Nota Fiscal - Devolução
                </Typography>
              </div>

              {Object.keys(reverseInvoice).length > 1 ? (
                <>
                  <div>
                    <Typography variant="h6">
                      Chave: {reverseInvoice.invoiceKey}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="h6">
                      Número: {reverseInvoice.number}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="h6">
                      Data de emissão:{' '}
                      {moment(reverseInvoice.createdAt).format(
                        'DD/MM/YY HH:mm:ss',
                      )}
                    </Typography>
                  </div>
                </>
              ) : (
                toggleReturnedInvoice && (
                  <div>
                    <section>
                      {deliveryReceived ? (
                        <div className={classes.centralize}>
                          <HourglassFullTwoTone />
                          <Typography variant="h6">
                            Nota fiscal em processamento
                          </Typography>
                        </div>
                      ) : (
                        <Typography color="textSecondary" variant="h6">
                          Esta nota fiscal de venda não possui nota fiscal de
                          devolução relacionada
                        </Typography>
                      )}
                    </section>

                    <section className={classes.buttonPanel}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleToggle}
                        disabled={deliveryReceived ? true : false}
                      >
                        Confirmar
                      </Button>
                    </section>
                  </div>
                )
              )}
            </Paper>
          </Paper>
        </main>

        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '70%' }}
        />

        <section>
          <Paper className={classes.root}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {fields.map(field => {
                    return (
                      <TableCell
                        key={field}
                        className={classes.tableHeaderText}
                      >
                        {field}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={item.id} className={classes.row}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.prodCprod}</TableCell>
                    <TableCell>{item.prodXprod}</TableCell>
                    <TableCell>{item.prodQcom}</TableCell>
                    <TableCell>{itemPrice(item.unitPrice)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </section>

        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '70%' }}
        />

        <Dialog open={open} onClose={this.handleToggle}>
          <DialogTitle>Deseja emitir nota fiscal de devolução?</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Cliente: {saleInvoice.destXnome}
            </DialogContentText>
            <DialogContentText>Pedido: {orderNumber}</DialogContentText>
            <DialogContentText>Número: {saleInvoice.number}</DialogContentText>
          </DialogContent>

          <DialogContent>
            <DialogContentText>
              Este processo não pode ser desfeito.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={this.handleToggle}
            >
              Não
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ReverseInvoice);
