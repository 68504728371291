import {
  Grid,
  Paper,
  Table,
  Button,
  Divider,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import { request } from '../helpers';
import React, { Component } from 'react';
import {
  Search,
  ReplyAllSharp,
  Add,
  Close,
  MoreVert,
  BorderColor,
  Delete,
} from '@material-ui/icons';
import StorageControllerItemsAdd from '../components/StorageControllerItem/StorageControllerItemAdd';
import Info from '../components/Infos/Info';
import StorageControllerItemEdit from '../components/StorageControllerItem/StorageControllerItemEdit';
import { History, LocationState } from 'history';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import StorageControllerItemDelete from '../components/StorageControllerItem/StorageControllerItemDelete';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    textSpace: {
      padding: '15px',
    },
    searchContainer: {
      display: 'flex',
      marginBottom: '30px',
      justifyContent: 'space-between',
    },
    headerContainer: {
      width: '95%',
      margin: 'auto',
    },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonsContainer: {
      width: 200,
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '10px',
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
  });

interface Props extends WithStyles<typeof styles> {
  match: {
    params: {
      [key: string]: string;
    };
  };
  history: History<LocationState>;
}

interface State {
  itemsFiltered: [];
  toggleItems: boolean;
  allItemsOfTheStorage: [];
  showAddItems: boolean;
  fields: string[];
  storageController: {};
  showModalEditItem: boolean;
  item: {};
  showModalDeleteItem: boolean;
}

interface ClassCSS {
  className: any;
}

class StorageControllerItemList extends Component<Props, State, ClassCSS> {
  constructor(props) {
    super(props);
    this.state = {
      itemsFiltered: [],
      toggleItems: false,
      allItemsOfTheStorage: [],
      showAddItems: false,
      fields: ['Posição', 'SKU', 'Empresa', 'Ação'],
      storageController: {},
      showModalEditItem: false,
      item: {},
      showModalDeleteItem: false,
    };
  }

  getAllItemsOfTheStorage = async () => {
    const { id } = this.props.match.params;
    const response = await request(
      `get:/api/storage-controller-items/list/${id}`,
      {},
    );
    this.setState({
      allItemsOfTheStorage: await response.data.storageControllerItems,
    });
  };

  handleChange = async event => {
    event.preventDefault();
    const {
      target: { value },
    } = event;

    const regExp = new RegExp(value, 'gi');
    const itemFind = this.state.allItemsOfTheStorage.filter((item: any) => {
      if (item.sku.match(regExp)) {
        return item;
      }
    });
    this.setState({
      toggleItems: true,
      itemsFiltered: itemFind,
    } as State);
  };

  componentDidMount = () => {
    this.getAllItemsOfTheStorage();
    this.getStorageController();
  };

  goStorageControllerList = () => {
    this.props.history.push('/storage-controller/list');
  };

  toggleAddItems = () => {
    this.setState({ showAddItems: !this.state.showAddItems });
  };

  handleMessageSuccess = () => {
    //@ts-ignore
    const paramsSearch = new URLSearchParams(this.props.location.search);

    if (paramsSearch.has('sku')) {
      return `Item "${paramsSearch.get('sku')}" cadastrado com sucesso.`;
    }
  };

  getStorageController = async () => {
    const { id } = this.props.match.params;
    const { storageController } = (
      await request(`get:/api/storage-controller/find/?id=${id}`, {})
    ).data;

    this.setState({
      storageController: await storageController,
    });
  };

  updateListItems = () => {
    this.getAllItemsOfTheStorage();
    this.forceUpdate();
  };

  handleEditItem = item => {
    this.setState({
      showModalEditItem: !this.state.showModalEditItem,
      item: item,
    });
  };

  handleDeleteItem = item => {
    this.setState({
      showModalDeleteItem: !this.state.showModalDeleteItem,
      item: item,
    });
  };

  render() {
    const { classes }: Props = this.props;
    const {
      toggleItems,
      itemsFiltered,
      allItemsOfTheStorage,
      showAddItems,
      fields,
      storageController,
      showModalEditItem,
      item,
      showModalDeleteItem,
    }: any = this.state;
    const items = toggleItems ? itemsFiltered : allItemsOfTheStorage;
    const renderMessageSuccess: any = this.handleMessageSuccess();
    const { id } = this.props.match.params;

    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.headerContent}>
            <Typography
              variant="h4"
              color="inherit"
              className={classes.textSpace}
            >
              Colmeia: {storageController.code}
            </Typography>

            <Typography
              variant="h5"
              color="inherit"
              className={classes.textSpace}
            >
              Armazém: {storageController.warehouseCode}
            </Typography>
          </div>

          <Typography
            variant="h5"
            color="inherit"
            className={classes.textSpace}
          >
            Items: {allItemsOfTheStorage.length}{' '}
            {allItemsOfTheStorage.length > 1 ? 'unidades' : 'unidade'}
          </Typography>

          <div className={classes.buttonsContainer}>
            <div>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => this.goStorageControllerList()}
              >
                <ReplyAllSharp fontSize="default" />
                Voltar
              </Button>
            </div>

            <div>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => this.toggleAddItems()}
              >
                {showAddItems ? (
                  <Close fontSize="default" />
                ) : (
                  <Add fontSize="default" />
                )}
                {showAddItems ? 'Fechar' : 'Items'}
              </Button>
            </div>
          </div>
        </div>

        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '95%' }}
        />

        {showAddItems ? (
          <>
            {
              // @ts-ignore
              <StorageControllerItemsAdd
                storageConstrollerId={id}
                updateListItems={() => this.updateListItems()}
              />
            }
            <Divider
              variant="middle"
              style={{ margin: '20px auto ', width: '95%' }}
            />
          </>
        ) : null}

        {renderMessageSuccess == null ? null : (
          <Info>
            <Typography variant="h5" color="inherit">
              {renderMessageSuccess}
            </Typography>
          </Info>
        )}

        <Paper className={classes.root}>
          <div className={classes.searchContainer}>
            <Grid style={{ width: '40%' }} container alignItems="flex-end">
              <Grid item>
                <Search />
              </Grid>

              <Grid item>
                <TextField
                  id="input-with-icon-grid"
                  label="SKU"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
          </div>

          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {fields.map(field => {
                  return (
                    <TableCell
                      className={classes.tableHeaderText}
                      key={field.id}
                    >
                      {field.toUpperCase()}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {items.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>{item.branchCode}</TableCell>

                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {popupState => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreVert />
                            </IconButton>

                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  this.handleEditItem(item);
                                }}
                              >
                                <ListItemIcon>
                                  <BorderColor />
                                </ListItemIcon>

                                <ListItemText inset>Editar</ListItemText>
                              </MenuItem>

                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  this.handleDeleteItem(item);
                                }}
                              >
                                <ListItemIcon>
                                  <Delete />
                                </ListItemIcon>

                                <ListItemText inset>Apagar</ListItemText>
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>

        {showModalEditItem ? (
          <StorageControllerItemEdit
            {...item}
            updateListItems={this.updateListItems}
          />
        ) : null}

        {showModalDeleteItem ? (
          <StorageControllerItemDelete
            {...item}
            updateListItems={this.updateListItems}
          />
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(StorageControllerItemList);
