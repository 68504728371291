import {
  Table,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Container,
  makeStyles,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import Info from '../Infos/Info';
import { request } from '../../helpers';
import Loading from '../Loading/Loading';
import { Theme } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';

interface Message {
  error?: string;
  success?: string;
}

interface WarehouseWaveConfig {
  [key: string]: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(18),
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  header: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.dark,
  },
  headerTitle: {
    color: theme.palette.background.default,
  },
  main: {
    padding: theme.spacing(3),
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.grey['300'],
  },
  tableHeader: {
    maxWidth: 300,
    fontWeight: 700,
    padding: '3px 12px',
    textTransform: 'uppercase',
    border: 'solid #8294b8 2px',
    backgroundColor: '#244079',
  },
  tableHeaderText: {
    color: theme.palette.primary.contrastText,
  },
  row: {
    '&:nth-child(odd)': {
      background: theme.palette.grey['100'],
    },
    '&:hover': {
      background: theme.palette.grey['300'],
    },
  },
  columnReduceSize: {
    padding: '0px 5px 0px 20px',
  },

  infoStylePersonalized: {
    width: '94%',
    margin: '10px 0px 0px 20px',
  },
}));

export default function WarehouseParameters({ warehouse, warehouseError }) {
  const waveConfig = {
    'Mínimo pedidos para agrupamento onda': 0,
    'Máximo de pedidos onda bancada preKit (PARTIAL_KIT)': 0,
    'Máximo de pedidos onda bancada kit até 3 produtos por pedido': 0,
    'Máximo de pedidos onda bancada kit até 5 produtos por pedido': 0,
    'Máximo de pedidos onda bancada para produto único (SINGLE-SKU)': 0,
    'Máximo de pedidos onda bancada kit mais de 5 produtos por pedido': 0,
  };

  const [message, setMessage] = useState<Message>({});
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [loading, setToggleLoading] = useState<boolean>(false);
  const [warehouseWaveConfig, setWarehouseWaveConfig] = useState<
    WarehouseWaveConfig
  >(waveConfig);

  const classes = useStyles();
  const { error, success } = message;
  const renderMessage = success ? success : error;
  const fields = ['Descrição', 'Valor'];

  const handleOpenOrCloseModal = () => {
    setToggleModal(!toggleModal);
  };

  const fetchWarehouse = async () => {
    try {
      setToggleLoading(true);

      setWarehouseWaveConfig(waveConfig);

      const response = (
        await request(`GET:/api/warehouse?warehouseCode=${warehouse}`, {})
      ).data;

      if (response.warehouse) {
        const waveConfig = JSON.parse(response.warehouse.waveConfig);

        if (!waveConfig) return;

        const {
          optimization: { StockMovementManagement, type },
        } = waveConfig;

        const warehouseParameters = {
          'Mínimo pedidos para agrupamento onda':
            StockMovementManagement.MinimumAggregation || 0,
          'Máximo de pedidos onda bancada preKit (PARTIAL_KIT)':
            type.PARTIAL_KIT.maximum_order || 0,
          'Máximo de pedidos onda bancada para produto único (SINGLE-SKU)':
            type.SINGLE_SKU.maximum_order || 0,
          'Máximo de pedidos onda bancada kit até 3 produtos por pedido':
            type.KIT.KIT_P.maximum_order || 0,
          'Máximo de pedidos onda bancada kit até 5 produtos por pedido':
            type.KIT.KIT_M.maximum_order || 0,
          'Máximo de pedidos onda bancada kit mais de 5 produtos por pedido':
            type.KIT.KIT_G.maximum_order || 0,
        };

        return setWarehouseWaveConfig(warehouseParameters);
      }

      return;
    } catch (error) {
      setMessage({ error: error.message });
    } finally {
      setToggleLoading(false);
    }
  };

  useEffect(() => {
    fetchWarehouse();
  }, [warehouse]);

  const handleChange = event => {
    const { name, value } = event.target;

    setWarehouseWaveConfig({
      ...warehouseWaveConfig,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (!Object.keys(warehouse).length) {
        throw new Error('Armazém não encontrado');
      }

      setToggleLoading(true);

      const response = (
        await request(`POST:/api/warehouse/${warehouse}`, {
          warehouseWaveConfig,
        })
      ).data;

      setMessage({ success: response.message });
    } catch (error) {
      setMessage({ error: error.message });
    } finally {
      setToggleLoading(false);
    }
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          if (warehouseError()) return;
          handleOpenOrCloseModal();
        }}
      >
        Parâmetros
      </Button>

      <Dialog
        fullWidth
        maxWidth={'md'}
        open={toggleModal}
        onClose={handleOpenOrCloseModal}
      >
        <DialogTitle className={classes.header}>
          <Typography className={classes.headerTitle}>
            Parametrizar armazém - {warehouse}
          </Typography>
        </DialogTitle>

        {loading && <Loading />}

        <Info
          type={!error ? 'success' : 'error'}
          onClose={() => setMessage({ error: undefined, success: undefined })}
          infoStylePersonalized={classes.infoStylePersonalized}
        >
          {renderMessage}
        </Info>

        <DialogContent className={classes.main}>
          <DialogContentText>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {fields.map(field => {
                    return (
                      <TableCell
                        key={field}
                        className={classes.tableHeaderText}
                      >
                        {field}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(warehouseWaveConfig).map(parameter => (
                  <TableRow key={parameter} className={classes.row}>
                    <TableCell className={classes.columnReduceSize}>
                      {parameter}
                    </TableCell>

                    <TableCell className={classes.columnReduceSize}>
                      <TextField
                        margin="normal"
                        name={parameter}
                        value={warehouseWaveConfig[parameter]}
                        onChange={event => handleChange(event)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.footer}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Salvar
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={handleOpenOrCloseModal}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
