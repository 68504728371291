import { request } from './helpers';
import Cookies from 'js-cookie';

export function isAuthenticated(): boolean {
  return (Cookies.get('token') || '') !== '';
}

export async function authenticate(
  username: string,
  password: string,
  warehouse: string,
) {
  let response: any = {};

  if (!warehouse) {
    throw new Error('Selecione um armazém');
  }

  try {
    response = (
      await request('post:/api/auth/login', {
        username,
        password,
        warehouse,
      })
    ).data;
  } catch (e) {
    throw new Error('Houve um problema na autenticação');
  }

  if (response.token) {
    Cookies.set('token', response.token);
    Cookies.set('warehouse', warehouse);
    Cookies.set('username', username);
  } else {
    throw new Error('Usuário ou senha inválido');
  }
}

export function unauthenticate() {
  Cookies.remove('token');
  Cookies.remove('warehouse');
}
