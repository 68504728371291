import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import qs from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  orders: any[];
  status?: any;
  preShipmentStatus?: any;
}

class DashboardOrders extends React.Component<Props, State> {
  state = {
    loading: true,
    orders: [],
  };

  async componentDidMount() {
    const params = qs.parse(this.props.location.search);

    await Promise.all([
      this.getOrders(params),
      this.getStatus(params),
      this.getPreShipmentStatus(params),
    ]);

    this.setState({ loading: false });
  }

  async getOrders(params): Promise<void> {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      status: params.status,
      pre_shipment_status: params.pre_shipment_status,
    };

    const response = (await request('get:/api/report/orders', options)).data;

    this.setState({ orders: response.orders });
  }

  async getStatus(params): Promise<void> {
    const options: any = {
      status_code: params.status,
    };

    const response = (await request('get:/api/status/find', options)).data;

    this.setState({ status: response.status });
  }

  async getPreShipmentStatus(params): Promise<void> {
    if (!params.pre_shipment_status) {
      return;
    }
    const options: any = {
      status_code: params.pre_shipment_status,
    };

    const response = (await request('get:/api/status/find', options)).data;

    this.setState({ preShipmentStatus: response.status });
  }

  render() {
    const { loading, orders, status, preShipmentStatus }: any = this.state;

    return (
      <div>
        <Typography variant="h6">
          Relatório - Processamento de Pedidos - {status && status.name}{' '}
          {preShipmentStatus &&
            ' - Status Pré-embarque: ' + preShipmentStatus.name}
        </Typography>
        <div>{loading && <Loading />}</div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pedido</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>NF</TableCell>
              <TableCell>Pré-embarque</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order: any) => (
              <TableRow key={order.id}>
                <TableCell>{order.orderId}</TableCell>
                <TableCell>{order.externalCode}</TableCell>
                <TableCell>
                  {order.invoiceNumber} / {order.invoiceSerie}
                </TableCell>
                <TableCell>{order.preShipmentId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardOrders);
