import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { RouteComponentProps } from 'react-router';
import Info from '../components/Infos/Info';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    containerInput: {
      paddingRight: '20px',
    },
    containerBarcode: {
      height: '20px',
      fontSize: '16px',
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  values: any;
  invoiceKey?: string;
  delivery?: any;
  success?: string;
  error?: string;
  preShipment: any;
  items: any[];
  closeModal: boolean;
  modalError?: string;
}

class PreShipmentAssign extends React.Component<Props, State> {
  state = {
    loading: false,
    values: {},
    invoiceKey: '',
    delivery: null,
    preShipment: {},
    closeModal: false,
    items: [],
  };

  audioError: any;
  audioSuccess: any;

  constructor(props) {
    super(props);
    this.audioError = React.createRef();
    this.audioSuccess = React.createRef();
  }

  handleCloseModalOpen = preShipment => {
    this.setState({
      closeModal: true,
      modalError: '',
    });
  };

  handleCloseModalClose = () => {
    this.setState({
      closeModal: false,
    });
  };

  renderMenuActions() {
    const container = document.querySelector('#header-items');
    if (!container) {
      return;
    }

    const { classes } = this.props;

    return ReactDOM.createPortal(
      <div>
        <Button
          variant="contained"
          size="medium"
          onClick={this.handleCloseModalOpen}
        >
          Fechar <DoneIcon />
        </Button>
      </div>,
      container,
    );
  }

  handleChange = e => {
    const { name, value } = e.target;

    const { values } = this.state;

    values[name] = value;

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  loadPreShipment = async () => {
    const params: any = this.props.match!.params;

    const options: any = {
      id: params.id,
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (await request('get:/api/pre-shipment/details', options))
      .data;

    this.setState({ preShipment: response.preShipment, items: response.items });
  };

  findDelivery = async invoiceKey => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      invoice_key: invoiceKey,
    };

    this.setState({
      invoiceKey: undefined,
      delivery: undefined,
      error: undefined,
      loading: true,
    });

    const response = (
      await request('get:/api/delivery/find-by-invoice', options)
    ).data;

    this.setState({ loading: false });

    if (!response.delivery) {
      this.setError('Nota fiscal não encontrada');
      return;
    }

    if (response.delivery.status.code !== 'DELIVERY_PACKING') {
      this.setError('Remessa no status inválido');
      return;
    }

    this.setState({ invoiceKey, delivery: response.delivery });
  };

  findHandlingUnit = async barcode => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      barcode,
    };
    try {
      this.setState({ loading: true });

      const response = (
        await request('get:/api/pre-shipment/find-handling-unit', options)
      ).data;
      this.setState({ loading: false });

      if (!response.handlingUnit) {
        this.setError('Pacote não encontrado');
        return;
      }

      return response.handlingUnit;
    } catch (e) {
      this.setError('Pacote não encontrado');
      this.setState({ loading: false });
      return;
    }
  };

  inputFocus() {
    const input: any = document.querySelector('[name="input"]');
    if (!input) {
      return;
    }
    input.focus();
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { values, delivery }: any = this.state;

    if (!values.input) {
      return;
    }

    this.setState({ error: undefined, success: undefined });

    const barcode = values.input.trim();

    values.input = '';
    this.setState({ values });

    if (barcode.length === 44) {
      await this.findDelivery(barcode);
    } else {
      if (!delivery) {
        this.setError('Escolha uma Nota Fiscal primeiro');
      } else {
        const handingUnit = await this.findHandlingUnit(barcode);
        if (handingUnit) {
          this.assign(handingUnit);
        }
      }
    }
  };

  assign = async handingUnit => {
    const { preShipment, delivery }: any = this.state;
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      pre_shipment_id: preShipment.id,
      handling_unit_id: handingUnit.id,
      delivery_id: delivery.id,
    };

    try {
      this.setState({ loading: true });

      const response = (await request('get:/api/pre-shipment/assign', options))
        .data;

      this.setSuccess(`Atribuído com sucesso`);

      this.setState({
        loading: false,
        error: undefined,
        invoiceKey: undefined,
        delivery: undefined,
      });

      await this.loadPreShipment();

      this.inputFocus();
    } catch (e) {
      this.setError(
        'Houve um erro:' +
          (e.response.data.message ? e.response.data.message : e.message),
      );
      this.setState({
        loading: false,
      });
      return;
    }
  };

  close = async () => {
    this.setState({ modalError: '' });

    const { preShipment }: any = this.state;

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      pre_shipment_id: preShipment.id,
    };

    try {
      const response = (await request('get:/api/pre-shipment/close', options))
        .data;

      this.handleCloseModalClose();
      this.setSuccess(`Pré-embarque fechado com sucesso`);
      this.setState({
        error: undefined,
        modalError: undefined,
      });

      setTimeout(() => {
        window.location.href = '/pre-shipment/list';
      }, 3000);
    } catch (e) {
      this.setState({
        modalError:
          'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      });
    }
  };

  remove = async handingUnit => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      handling_unit_id: handingUnit.id,
    };

    try {
      this.setState({ loading: true });
      const response = (await request('get:/api/pre-shipment/remove', options))
        .data;

      this.setSuccess(`Removido com sucesso`);

      this.setState({
        loading: false,
        error: undefined,
      });

      await this.loadPreShipment();

      this.inputFocus();
    } catch (e) {
      this.setError(
        'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      );
      this.setState({
        loading: false,
      });
      return;
    }
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await this.loadPreShipment();

    this.setState({ loading: false });
  }

  setError(error: string) {
    this.setState({ error });

    this.audioError.current.play();
  }

  setSuccess(success: string) {
    this.setState({
      success,
    });

    this.audioSuccess.current.play();
  }

  render() {
    const {
      loading,
      success,
      error,
      preShipment,
      delivery,
      values,
      items,
      invoiceKey,
      modalError,
    }: any = this.state;

    const { classes }: any = this.props;

    return (
      <div>
        {this.renderMenuActions()}
        <audio src="/audio/error.mp3" ref={this.audioError} />
        <audio src="/audio/success.mp3" ref={this.audioSuccess} />

        <Info
          type="success"
          onClose={() => this.setState({ success: undefined })}
        >
          {success}
        </Info>

        <Info type="error" onClose={() => this.setState({ error: undefined })}>
          {error}
        </Info>

        <div>{loading && <Loading />}</div>

        <Grid container>
          <Grid item xs={2}>
            <Typography variant="h6">Pré-embarque: {preShipment.id}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Total: {items.length}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">
              Empresa: {preShipment.branchCode}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6">
              Transportadora: {preShipment.carrierName}
            </Typography>
          </Grid>
        </Grid>

        <div className={classes.containerBarcode}>
          <Typography variant="h6">
            {delivery &&
              delivery.invoice &&
              parseInt(delivery.invoice.number, 10) +
                ' / ' +
                parseInt(delivery.invoice.serie, 10)}
          </Typography>
        </div>

        <form onSubmit={this.handleSubmit}>
          <Grid container>
            <Grid item xs={5} className={classes.containerInput}>
              <TextField
                name="input"
                label="Nota Fiscal / Rastreio"
                margin="normal"
                autoFocus
                onChange={this.handleChange}
                autoComplete={'off'}
                value={values.input}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={7}>
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Atribuir
              </Button>
            </Grid>
          </Grid>
        </form>

        <Dialog
          open={this.state.closeModal}
          onClose={this.handleCloseModalClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Fechar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Info
                type="error"
                onClose={() => this.setState({ modalError: undefined })}
              >
                {modalError}
              </Info>
              <div>Tem certeza que deseza fechar?</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModalClose}>Cancelar</Button>
            <Button onClick={this.close} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        {!loading && items.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nota Fiscal</TableCell>
                <TableCell>Rastreio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item: any) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.delivery && item.delivery.invoice
                      ? `${parseInt(
                          item.delivery.invoice.number,
                          10,
                        )} / ${parseInt(item.delivery.invoice.serie, 10)}`
                      : '-'}
                  </TableCell>
                  <TableCell>{item.barcode ? item.barcode : '-'}</TableCell>
                  <TableCell>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <React.Fragment>
                          <Button
                            variant="contained"
                            {...bindTrigger(popupState)}
                          >
                            Ação
                          </Button>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                this.remove(item).then();
                              }}
                            >
                              <ListItemIcon className={classes.icon}>
                                <DeleteIcon />
                              </ListItemIcon>

                              <ListItemText
                                classes={{ primary: classes.primary }}
                                inset
                              >
                                Remover
                              </ListItemText>
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PreShipmentAssign);
