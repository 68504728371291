import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { request } from '../../helpers';
import React, { Component, Fragment } from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  FormControl: {
    width: 500,
  },
});

interface Props extends WithStyles<typeof styles> {
  id: string;
  sku: string;
  code: string;
  open: boolean;
  branch: string;
  updateListItems: () => string;
}

interface State {
  id: string;
  sku: string;
  code: string;
  open: boolean;
  branch: string;
}

class StorageControllerItemDelete extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      id: this.props.id,
      sku: this.props.sku,
      code: this.props.code,
      branch: this.props.branch,
    };
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleDelete = async () => {
    const { id } = this.props;

    try {
      await request('DELETE:/api/storage-controller-item', { id });

      this.props.updateListItems();

      this.handleToggle();
    } catch (e) {
      throw new Error(e);
    }
  };

  render() {
    const { open, code, branch, sku } = this.state;

    return (
      <Fragment>
        <Dialog open={open} onClose={this.handleToggle}>
          <DialogTitle>Tem certeza que deseja apagar o item?</DialogTitle>

          <DialogContent>
            <DialogContentText>Posição: {code}</DialogContentText>
            <DialogContentText>SKU: {sku}</DialogContentText>
            <DialogContentText>Empresa: {branch}</DialogContentText>
          </DialogContent>

          <DialogContent>
            <DialogContentText>
              Este processo não pode ser desfeito.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={this.handleToggle}
            >
              Cancelar
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleDelete}
            >
              Apagar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(StorageControllerItemDelete);
