import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import Header from './../components/Header/Header';

const styles = (theme: Theme) =>
  createStyles({
    spacing: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(4),
    },
  });

interface Props
  extends React.Props<any>,
    RouteComponentProps<{}>,
    WithStyles<typeof styles> {}

class Page extends React.Component<Props> {
  render() {
    const { children, classes } = this.props;
    return (
      <>
        {
          // @ts-ignore
          <Header />
        }
        <div className={classes.spacing} />
        <div className={classes.content}>{children}</div>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Page));
