import { MenuItem, TextField } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { request } from '../../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      width: 170,
    },
  });

interface Props extends WithStyles<typeof styles> {
  onChange: any;
  value: string;
}

interface State {
  branches: any[];
}

const inputLabelProps = {
  shrink: true,
};

class BranchInput extends React.Component<Props, State> {
  state = {
    branches: [],
  };

  async componentDidMount() {
    const response = (
      await request('post:/api/branch/list', {
        warehouse_code: Cookies.get('warehouse'),
      })
    ).data;

    this.setState({ branches: response.branches });
  }

  render() {
    const { onChange, value, classes } = this.props;
    const { branches } = this.state;

    return (
      <TextField
        name="branch"
        select
        label="Filial"
        margin="normal"
        InputLabelProps={inputLabelProps}
        className={classes.textField}
        onChange={onChange}
        value={value}
      >
        <MenuItem value="" />
        {branches.map((branch: any) => (
          <MenuItem value={branch.code} key={branch.code}>
            {branch.short_name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default withStyles(styles)(BranchInput);
