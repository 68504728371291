import {
  Grid,
  Paper,
  Table,
  Button,
  Dialog,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import cn from 'classnames';
import { request } from '../../helpers';
import Loading from '../Loading/Loading';
import React, { useState, useEffect } from 'react';

interface Orders {
  id: number;
  carrierCode: string;
  delivery: {
    invoice: {
      number: string;
      trackingCode: string;
    };
    status: {
      name: string;
    };
    orderId: number;
  };
  type: string;
  description: string;
  warehouseCode: string;
}

const styles: any = (theme: any) => ({
  root: {
    margin: 20,
    borderRadius: 5,
    border: '2px solid #1A237E',
  },
  tableHeader: {
    textTransform: 'uppercase',
    border: 'solid #8294b8 2px',
    background: theme.palette.primary.dark,
  },
  tableHeaderText: {
    color: theme.palette.primary.contrastText,
  },
  row: {
    '&:nth-child(odd)': {
      background: theme.palette.grey['100'],
    },
    '&:hover': {
      background: theme.palette.grey['300'],
    },
  },
  text: {
    top: 5,
    left: 50,
    position: 'absolute',
    backgroundColor: 'white',
    padding: '0px 10px 0px 10px',
  },
  headerContainer: {
    width: '90%',
    marginTop: 10,
    margin: 'auto',
    padding: '1em 1em',
  },
});

const WaveOptimizationOrders = ({ classes, handleAction, wave, ...props }) => {
  const [orders, setOrders] = useState<Orders[]>();
  const [toggleLoading, setToggleLoading] = useState(false);

  const fields = [
    'Pedido',
    'Nota Fiscal',
    'Transportadora',
    'Rastreio',
    'Status',
  ];

  const fetchOrders = async () => {
    try {
      setToggleLoading(true);

      const waveOptimizationOrders = (
        await request('GET:/api/wave-optimization/orders', {})
      ).data;

      setOrders(waveOptimizationOrders);
    } catch (error) {
      console.error('Error', error);
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={handleAction}
        onClose={handleAction}
      >
        <Typography className={classes.text} variant="h6">
          Pedidos
        </Typography>

        <DialogContent className={classes.root}>
          <DialogContentText>
            <Grid container className={classes.headerContainer}>
              <Grid xs item container direction="column">
                <Typography>Armazém</Typography>

                <Typography variant="h5">
                  {orders && orders[0].warehouseCode}
                </Typography>
              </Grid>

              <Grid
                md
                item
                container
                direction="column"
                style={{ borderLeft: '2px solid gray', paddingLeft: 15 }}
              >
                <Typography variant="h6">
                  Tipo: {orders && orders[0].type}
                </Typography>
                <Typography variant="h6">
                  Descrição: {orders && orders[0].description}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
          <Paper>
            {toggleLoading && <Loading />}

            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {fields.map(field => {
                    return (
                      <TableCell
                        key={field}
                        className={cn(classes.tableHeaderText)}
                      >
                        {field}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {orders &&
                  orders.map(order => {
                    return (
                      <TableRow key={order.id} className={classes.row}>
                        <TableCell>{order.delivery.orderId}</TableCell>
                        <TableCell>{order.delivery.invoice.number}</TableCell>
                        <TableCell>{order.carrierCode}</TableCell>
                        <TableCell>
                          {order.delivery.invoice.trackingCode}
                        </TableCell>
                        <TableCell>{order.delivery.status.name}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(WaveOptimizationOrders);
