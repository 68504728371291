import {
  Tab,
  Tabs,
  Grid,
  Paper,
  Table,
  AppBar,
  Button,
  Divider,
  TableRow,
  TableHead,
  TableCell,
  TextField,
  TableBody,
  Typography,
  withStyles,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Waves,
  DoneAll,
  LockOpen,
  Assignment,
  CardGiftcard,
  LocalShipping,
  AddShoppingCart,
  MoreVert,
  SwapHoriz,
} from '@material-ui/icons';
import moment from 'moment';
import cn from 'classnames';
import { request } from '../helpers';
import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading/Loading';
import WaveOrders from '../components/WaveSmart/WaveOrders';
import Pagination from '../components/Pagination/Pagination';
import WaveChangeStation from '../components/WaveSmart/WaveChangeStation';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

interface Waves {
  id?: number;
  status?: string;
  packing?: number;
  shipped?: number;
  percent?: number;
  createdAt?: Date;
  preShipped?: number;
  totalOrder?: string;
  warehouseCode?: string;
  optimizationType?: string;
  optimizationDescription?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  valueTab: any;
}

interface WaveSearch {
  date?: string | undefined;
  waveId?: string;
  order?: string;
  invoice?: string;
}

interface WavesSummary {
  packing: number;
  shipped: number;
  totalOrder: number;
  preShipped: number;
  openPercent: string;
  closedPercent: string;
}

const styles: any = (theme: any) => ({
  cardContainer: {
    width: '95%',
    margin: 'auto',
  },
  card: {
    padding: 10,
    flexBasis: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': { transform: 'scaleX(1.02)' },
  },
  cardIcon: {
    padding: 10,
    borderRadius: '50%',
  },
  cardIconColor: {
    fill: 'white',
  },
  tableHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    border: 'solid #8294b8 2px',
    background: theme.palette.primary.dark,
  },
  tableHeaderText: {
    color: theme.palette.primary.contrastText,
  },
  row: {
    '&:nth-child(odd)': {
      background: theme.palette.grey['100'],
    },
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.grey['300'],
    },
  },
  paginationContainer: {
    display: 'flex',
    paddingLeft: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterContainer: {
    width: '90%',
    margin: 'auto',
  },
  filter: {
    display: 'flex',
    margin: '0px 40px',
    padding: '10px 0px',
    justifyContent: 'space-evenly',
  },
  columnReduceSize: {
    padding: '0px 5px 0px 20px',
  },
});

const WaveSmart = props => {
  const { classes } = props;
  const [valueTab, setValueTab] = useState(0);
  const [waves, setWaves] = useState<Waves[]>();
  const [pagination, setPagination] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [wave, setWave] = useState<object>({});
  const [waveSearch, setWaveSearch] = useState<WaveSearch>({});
  const [toggleOrdersModal, setToggleOrdersModal] = useState<boolean>(false);
  const [toggleChangeStationWaveModal, setChangeStationWaveModal] = useState<
    boolean
  >(false);
  const [toFocusWave, setToFocusWave] = useState<boolean>(false);
  const [toFocusDate, setToFocusDate] = useState<boolean>(false);
  const [toFocusOrder, setToFocusOrder] = useState<boolean>(false);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const [toFocusInvoice, setToFocusInvoice] = useState<boolean>(false);
  const [wavesSummary, setWavesSummary] = useState<WavesSummary>({
    packing: 0,
    shipped: 0,
    totalOrder: 0,
    preShipped: 0,
    openPercent: '',
    closedPercent: '',
  });
  const [isDateError, setIsDateError] = useState<boolean>(false);

  const fieldNameDate = 'date';
  const fieldNameOrder = 'order';
  const fieldNameWaveId = 'waveId';
  const fieldNamInvoice = 'invoice';

  const fields = [
    'Onda',
    'Data',
    'Otimização',
    'Descrição',
    'Pedido',
    'Packing',
    'Romaneio',
    'Expedido',
    'Status',
    'Concluído',
  ];

  const cards = [
    {
      icon: <Waves className={classes.cardIconColor} />,
      title: 'Ondas',
      color: '#115293',
      value: pagination.total,
    },
    {
      title: 'Pedidos',
      color: '#800080',
      icon: <AddShoppingCart className={classes.cardIconColor} />,
      value: wavesSummary.totalOrder,
    },
    {
      title: 'Packing',
      icon: <CardGiftcard className={classes.cardIconColor} />,
      color: '#f57c00',
      value: wavesSummary.packing,
    },
    {
      icon: <Assignment className={classes.cardIconColor} />,
      title: 'Romaneio',
      color: '#2f4f4f',
      value: wavesSummary.preShipped,
    },
    {
      icon: <LocalShipping className={classes.cardIconColor} />,
      title: 'Expedido',
      color: '#a52a2a',
      value: wavesSummary.shipped,
    },
    {
      icon: <LockOpen className={classes.cardIconColor} />,
      title: 'Aberto',
      color: '#e32636',
      value: wavesSummary.openPercent,
    },
    {
      icon: <DoneAll className={classes.cardIconColor} />,
      title: 'Concluído',
      color: '#388e3c',
      value: wavesSummary.closedPercent,
    },
  ];

  const fetchWaves = async () => {
    try {
      setToggleLoading(true);
      setIsDateError(false);

      if (waveSearch.date) {
        if (waveSearch.date[2] !== '/' || waveSearch.date[5] !== '/') {
          setIsDateError(true);
        }

        if (waveSearch.date.length <= 0) {
          setWaveSearch({
            ...waveSearch,
            date: undefined,
          });
        }
      }

      const { response } = (
        await request('GET:/api/wave/search', {
          perPage: 10,
          currentPage,
          waveSearch,
        })
      ).data;

      setWavesSummary(response.data[response.data.length - 1]);

      response.data.pop();

      setWaves(response.data);
      setPagination({ pagination, ...response });
    } catch (error) {
      console.error('Error', error);
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  useEffect(() => {
    fetchWaves();
  }, []);

  const renderPagination = () => {
    try {
      if (waves && waves.length > 0) {
        return (
          <Pagination pagination={pagination} changePage={handleChangePage} />
        );
      }
    } catch (e) {
      return null;
    }
  };

  const handleChangePage = async page => {
    setCurrentPage(await page);
  };

  useEffect(() => {
    if (currentPage >= 1) {
      fetchWaves();
    }
  }, [currentPage]);

  const handleOrderModal = wave => {
    setWave(wave);
    setToggleOrdersModal(!toggleOrdersModal);
  };

  const handleChangeStationWave = wave => {
    setWave(wave);
    setChangeStationWaveModal(!toggleChangeStationWaveModal);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, valueTab, index } = props;

    return (
      <>
        {valueTab === index && (
          <div>
            <Typography component={'span'}>{children}</Typography>
          </div>
        )}
      </>
    );
  };

  const handleChange = (event: any, newValue: number) => {
    const { name, value } = event.target;

    if (newValue < 3) {
      setValueTab(newValue);

      if (newValue == 0) {
        setWaveSearch({ date: undefined });
      }
    }

    if (name === fieldNameDate) {
      setToFocusWave(false);
      setToFocusOrder(false);
      setToFocusInvoice(false);
      setToFocusDate(true);
    }

    if (name === fieldNameWaveId) {
      setToFocusOrder(false);
      setToFocusInvoice(false);
      setToFocusWave(true);
    }

    if (name === fieldNameOrder) {
      setToFocusWave(false);
      setToFocusInvoice(false);
      setToFocusOrder(true);
    }

    if (name === fieldNamInvoice) {
      setToFocusWave(false);
      setToFocusOrder(false);
      setToFocusInvoice(true);
    }

    setWaveSearch({
      ...waveSearch,
      [name]: value,
    });
  };

  useEffect(() => {
    if (valueTab === 1) {
      return setWaveSearch({ date: moment().format('DD/MM/YYYY') });
    }

    setWaveSearch({ date: undefined });
  }, [valueTab]);

  return (
    <>
      {toggleLoading && <Loading />}
      {toggleOrdersModal && (
        <WaveOrders handleAction={handleOrderModal} wave={wave} />
      )}

      {toggleChangeStationWaveModal && (
        <WaveChangeStation handleAction={handleChangeStationWave} wave={wave} />
      )}

      <header>
        <AppBar position="static">
          <Tabs value={valueTab} onChange={handleChange}>
            <Tab label="Abertas" />
            <Tab label="Filtrar" />
          </Tabs>
        </AppBar>

        <TabPanel valueTab={valueTab} index={0}>
          <br />
          <Grid
            item
            xs={12}
            container
            spacing={8}
            justify="space-between"
            className={classes.cardContainer}
          >
            {cards.map((card, index) => (
              <Paper key={index} className={classes.card}>
                <div
                  className={classes.cardIcon}
                  style={{
                    backgroundColor: card.color,
                  }}
                >
                  {card.icon}
                </div>
                <div>
                  <Typography variant="button">
                    {card.title} <div>{card.value}</div>
                  </Typography>
                </div>
              </Paper>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel valueTab={valueTab} index={1}>
          <br />
          <Paper className={classes.filterContainer}>
            <form className={classes.filter}>
              <div>
                <TextField
                  name="date"
                  label="Data"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  className={classes.FormControl}
                  value={waveSearch && waveSearch.date}
                  autoFocus={toFocusDate ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => handleChange(event, 3)}
                  error={isDateError ? true : false}
                />

                {isDateError && (
                  <Typography color="error" component="div" variant="subtitle1">
                    * Utilize o formato: dd/mm/aaaa
                  </Typography>
                )}
              </div>

              <TextField
                type="number"
                name="waveId"
                value={waveSearch.waveId}
                label="Onda"
                margin="normal"
                variant="outlined"
                InputProps={{ inputProps: { min: 0 } }}
                autoFocus={toFocusWave ? true : false}
                onChange={event => handleChange(event, 3)}
              />

              <TextField
                type="Number"
                name="order"
                value={waveSearch.order}
                label="Pedido"
                margin="normal"
                variant="outlined"
                className={classes.FormControl}
                InputProps={{ inputProps: { min: 0 } }}
                autoFocus={toFocusOrder ? true : false}
                onChange={event => handleChange(event, 3)}
              />

              <TextField
                type="Number"
                name="invoice"
                value={waveSearch.invoice}
                label="Nota Fiscal"
                margin="normal"
                variant="outlined"
                className={classes.FormControl}
                InputProps={{ inputProps: { min: 0 } }}
                autoFocus={toFocusInvoice ? true : false}
                onChange={e => handleChange(e, 3)}
              />
            </form>
          </Paper>
        </TabPanel>
      </header>

      <Divider variant="middle" style={{ margin: '20px auto ' }} />

      <main>
        <Paper>
          <div className={classes.paginationContainer}>
            <div>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => {
                  fetchWaves();
                }}
                style={{ marginLeft: 5 }}
              >
                Buscar
              </Button>
            </div>

            {renderPagination()}
          </div>

          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell />

                {fields.map((field, index) => {
                  return (
                    <TableCell
                      key={field}
                      className={cn(
                        classes.tableHeaderText,
                        index === 0 || index === 4 || index === 5 || index === 6
                          ? classes.columnReduceSize
                          : null,
                      )}
                    >
                      {field}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {waves &&
                waves.map(wave => {
                  return (
                    <TableRow key={wave.id} className={classes.row}>
                      <TableCell
                        style={{
                          padding: '4px 0px 4px 24px',
                        }}
                      >
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreVert />
                              </IconButton>

                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    handleChangeStationWave(wave);
                                  }}
                                >
                                  <ListItemIcon>
                                    <SwapHoriz />
                                  </ListItemIcon>

                                  <ListItemText inset>
                                    Alterar onda de estação
                                  </ListItemText>
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                      {Object.values(wave).map((waveValue, index) => {
                        if (index <= 9) {
                          return (
                            <TableCell
                              className={classes.tableHeaderText}
                              key={index}
                              onClick={() => handleOrderModal(wave)}
                            >
                              <Typography color="textPrimary">
                                {waveValue}
                              </Typography>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {renderPagination()}
        </Paper>
      </main>
    </>
  );
};

export default withStyles(styles)(WaveSmart);
