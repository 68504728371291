import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PrintIcon from '@material-ui/icons/Print';
import RedeemIcon from '@material-ui/icons/Redeem';
import SyncIcon from '@material-ui/icons/Sync';
import format from 'date-fns/format';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import Info from '../components/Infos/Info';
import BranchInput from '../components/Inputs/BranchInput';
import CarrierInput from '../components/Inputs/CarrierInput';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      position: 'absolute',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100%',
    },
    noResults: {
      padding: '10px 15px',
    },
    textField: {
      width: 170,
    },
    generatePreShipmentModalContainer: {
      minWidth: '500px',
    },
    button: { background: '#ffffff', color: '#000000' },
  });

const statusList = [
  {
    code: 'HANDLING_UNIT_OPEN',
    name: 'Aberto',
  },
  {
    code: 'HANDLING_UNIT_CLOSED',
    name: 'Fechado',
  },
  {
    code: 'HANDLING_UNIT_SHIPPED',
    name: 'Expedido',
  },
];

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  values: any;
  filters: any;
  parameters?: any;
  preShipments: any[];
  success?: string;
  error?: string;
  modalError?: string;
  selectedPreShipment?: any;
  generatePreShipmentModal: boolean;
  shipmentModal: boolean;
  closeModal: boolean;
  reopenModal: boolean;
}

class PreShipmentList extends React.Component<Props, State> {
  state = {
    loading: false,
    values: {},
    filters: {
      start_at: format(new Date(), 'YYYY-MM-DD'),
    },
    preShipments: [],
    generatePreShipmentModal: false,
    shipmentModal: false,
    closeModal: false,
    reopenModal: false,
    parameters: {},
  };

  loadReport = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const { filters }: any = this.state;

    if (filters.branch) {
      options.branch_code = filters.branch;
    }

    if (filters.carrier) {
      options.carrier_code = filters.carrier;
    }

    if (filters.status) {
      options.status_code = filters.status;
    }

    if (filters.start_at) {
      options.start_at = filters.start_at;
    }

    if (filters.end_at) {
      options.end_at = filters.end_at;
    }

    const response = (await request('get:/api/pre-shipment/report', options))
      .data;

    this.setState({ preShipments: response.preShipments });
  };

  handleChange = e => {
    const { name, value } = e.target;

    const { values } = this.state;

    values[name] = value;

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  handleFilter = e => {
    const { name, value } = e.target;

    const { filters } = this.state;

    filters[name] = value;

    return new Promise(resolve =>
      this.setState({ filters }, async () => {
        await this.loadReport();
        resolve(undefined);
      }),
    );
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await this.loadReport();
    await this.loadParameters();

    this.setState({ loading: false });
  }

  handleGeneratePreShipmentModalOpen = () => {
    this.setState({
      generatePreShipmentModal: true,
      modalError: '',
    });
  };

  handleGeneratePreShipmentModalClose = () => {
    this.setState({ generatePreShipmentModal: false });
  };

  handleShipmentModalOpen = preShipment => {
    this.setState({
      shipmentModal: true,
      selectedPreShipment: preShipment,
      modalError: '',
    });
  };

  handleShipmentModalClose = () => {
    this.setState({
      shipmentModal: false,
    });
  };

  handleCloseModalOpen = preShipment => {
    this.setState({
      closeModal: true,
      selectedPreShipment: preShipment,
      modalError: '',
    });
  };

  handleCloseModalClose = () => {
    this.setState({
      closeModal: false,
    });
  };

  handleReopenModalOpen = preShipment => {
    this.setState({
      reopenModal: true,
      selectedPreShipment: preShipment,
      modalError: '',
    });
  };

  handleReopenModalClose = () => {
    this.setState({
      reopenModal: false,
    });
  };

  create = async () => {
    this.setState({ modalError: '' });

    const { values, parameters }: any = this.state;

    if (parameters.pre_shipment_by_branch === '1' && !values.branch) {
      this.setState({
        modalError: 'Selecione uma filial',
      });

      return;
    }

    if (!values.carrier) {
      this.setState({
        modalError: 'Selecione uma tranportadora',
      });

      return;
    }

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      branch_code: values.branch,
      carrier_code: values.carrier,
    };

    try {
      const response = (await request('get:/api/pre-shipment/create', options))
        .data;

      this.handleGeneratePreShipmentModalClose();
      this.setState({
        error: undefined,
        modalError: undefined,
        success: `Pré-embarque #${response.preShipment.id} gerado com sucesso`,
      });

      await this.loadReport();
    } catch (e) {
      this.setState({
        modalError:
          'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      });
    }
  };

  ship = async () => {
    this.setState({ modalError: '' });

    const { selectedPreShipment }: any = this.state;

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      pre_shipment_id: selectedPreShipment.id,
    };

    try {
      const response = (await request('get:/api/pre-shipment/ship', options))
        .data;

      this.handleShipmentModalClose();
      this.setState({
        error: undefined,
        modalError: undefined,
        success: `Pré-embarque expedido com sucesso`,
      });

      await this.loadReport();
    } catch (e) {
      this.setState({
        modalError:
          'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      });
    }
  };

  close = async () => {
    this.setState({ modalError: '' });

    const { selectedPreShipment }: any = this.state;

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      pre_shipment_id: selectedPreShipment.id,
    };

    try {
      const response = (await request('get:/api/pre-shipment/close', options))
        .data;

      this.handleCloseModalClose();
      this.setState({
        error: undefined,
        modalError: undefined,
        success: `Pré-embarque fechado com sucesso`,
      });

      await this.loadReport();
    } catch (e) {
      this.setState({
        modalError:
          'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      });
    }
  };

  reopen = async () => {
    this.setState({ modalError: '' });
    const { selectedPreShipment }: any = this.state;

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      pre_shipment_id: selectedPreShipment.id,
    };

    try {
      const response = (await request('get:/api/pre-shipment/reopen', options))
        .data;

      this.handleShipmentModalClose();
      this.setState({
        error: undefined,
        modalError: undefined,
        success: `Pré-embarque reaberto com sucesso`,
      });

      await this.loadReport();
    } catch (e) {
      this.setState({
        modalError:
          'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      });
    }
  };

  goToAssign(preShipment) {
    this.props.history.push('/pre-shipment/assign/' + preShipment.id);
  }

  async reprint(preShipment) {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      pre_shipment_id: preShipment.id,
    };

    this.setState({
      error: undefined,
      modalError: undefined,
      success: undefined,
    });

    try {
      const response = (await request('get:/api/pre-shipment/print', options))
        .data;

      this.setState({
        error: undefined,
        modalError: undefined,
        success: `Reimpresso com sucesso`,
      });
    } catch (e) {
      this.setState({
        modalError:
          'Houve um erro: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      });
    }
  }

  renderMenuActions() {
    const container = document.querySelector('#header-items');
    if (!container) {
      return;
    }

    const { classes } = this.props;

    return ReactDOM.createPortal(
      <div>
        <Button
          variant="contained"
          size="medium"
          className={classes.button}
          onClick={this.handleGeneratePreShipmentModalOpen}
        >
          Criar <AddIcon />
        </Button>
      </div>,
      container,
    );
  }

  loadParameters = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (
      await request('get:/api/pre-shipment/parameters', options)
    ).data;

    this.setState({ parameters: response.parameters });
  };

  render() {
    const { classes } = this.props;
    const {
      preShipments,
      loading,
      success,
      error,
      values,
      filters,
      modalError,
      parameters,
    }: any = this.state;

    const inputLabelProps = {
      shrink: true,
    };

    return (
      <div>
        {this.renderMenuActions()}
        <Paper className={classes.root}>
          <Info
            type="success"
            onClose={() => this.setState({ success: undefined })}
          >
            {success}
          </Info>

          <Info
            type="error"
            onClose={() => this.setState({ error: undefined })}
          >
            {error}
          </Info>

          <div>
            <Grid container>
              <Grid item xs={2}>
                <BranchInput
                  onChange={this.handleFilter}
                  value={filters.branch}
                />
              </Grid>

              <Grid item xs={2}>
                <CarrierInput
                  onChange={this.handleFilter}
                  value={filters.carrier}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="status"
                  select
                  label="Status"
                  margin="normal"
                  InputLabelProps={inputLabelProps}
                  className={classes.textField}
                  onChange={this.handleFilter}
                  value={filters.status}
                >
                  <MenuItem value="" />
                  {statusList.map((status: any) => (
                    <MenuItem value={status.code} key={status.code}>
                      {status.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="start_at"
                  label="Data Inicial"
                  type="date"
                  margin="normal"
                  InputLabelProps={inputLabelProps}
                  className={classes.textField}
                  onChange={this.handleFilter}
                  value={filters.start_at}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="end_at"
                  type="date"
                  label="Data Final"
                  margin="normal"
                  InputLabelProps={inputLabelProps}
                  className={classes.textField}
                  onChange={this.handleFilter}
                  value={filters.end_at}
                />
              </Grid>
            </Grid>
          </div>

          <div>{loading && <Loading />}</div>

          {!loading && preShipments.length === 0 && (
            <Paper elevation={1} component="div" className={classes.noResults}>
              <Typography paragraph={true}>
                Não foram encontrados nenhum resultado
              </Typography>
            </Paper>
          )}

          {!loading && preShipments.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Filial</TableCell>
                  <TableCell>Transportadora</TableCell>
                  <TableCell>Pedidos</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Peso</TableCell>
                  <TableCell>Data de Expedição</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {preShipments.map((preShipment: any) => (
                  <TableRow key={preShipment.id}>
                    <TableCell>{preShipment.id}</TableCell>
                    <TableCell>{preShipment.branchCode}</TableCell>
                    <TableCell>{preShipment.carrierName}</TableCell>
                    <TableCell>{preShipment.total}</TableCell>
                    <TableCell>
                      {format(preShipment.createdAt, 'DD/MM/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>{preShipment.statusName}</TableCell>
                    <TableCell>{preShipment.weight}</TableCell>
                    <TableCell>
                      {preShipment.shipmentAt &&
                        format(preShipment.shipmentAt, 'DD/MM/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {popupState => (
                          <React.Fragment>
                            <Button
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              Ação
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  this.reprint(preShipment);
                                }}
                              >
                                <ListItemIcon>
                                  <PrintIcon />
                                </ListItemIcon>

                                <ListItemText inset>Reimprimir</ListItemText>
                              </MenuItem>

                              {preShipment.statusCode ===
                                'HANDLING_UNIT_OPEN' && (
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    this.goToAssign(preShipment);
                                  }}
                                >
                                  <ListItemIcon>
                                    <RedeemIcon />
                                  </ListItemIcon>

                                  <ListItemText inset>Atribuir</ListItemText>
                                </MenuItem>
                              )}

                              {preShipment.statusCode ===
                                'HANDLING_UNIT_OPEN' && (
                                <MenuItem
                                  onClick={() => {
                                    popupState.close;
                                    this.handleCloseModalOpen(preShipment);
                                  }}
                                >
                                  <ListItemIcon>
                                    <DoneIcon />
                                  </ListItemIcon>

                                  <ListItemText inset>Fechar</ListItemText>
                                </MenuItem>
                              )}

                              {[
                                'HANDLING_UNIT_OPEN',
                                'HANDLING_UNIT_CLOSED',
                              ].includes(preShipment.statusCode) && (
                                <MenuItem
                                  onClick={() => {
                                    popupState.close;
                                    this.handleShipmentModalOpen(preShipment);
                                  }}
                                >
                                  <ListItemIcon>
                                    <LocalShippingIcon />
                                  </ListItemIcon>

                                  <ListItemText inset>Expedir</ListItemText>
                                </MenuItem>
                              )}

                              {preShipment.statusCode ===
                                'HANDLING_UNIT_CLOSED' && (
                                <MenuItem
                                  onClick={() => {
                                    popupState.close;
                                    this.handleReopenModalOpen(preShipment);
                                  }}
                                >
                                  <ListItemIcon>
                                    <SyncIcon />
                                  </ListItemIcon>

                                  <ListItemText inset>Reabrir</ListItemText>
                                </MenuItem>
                              )}
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          <Dialog
            open={this.state.generatePreShipmentModal}
            onClose={this.handleGeneratePreShipmentModalClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Criar Pré-Embarque</DialogTitle>
            <DialogContent
              className={classes.generatePreShipmentModalContainer}
            >
              <DialogContentText>
                <Info
                  type="error"
                  onClose={() => this.setState({ modalError: undefined })}
                >
                  {modalError}
                </Info>

                <Grid container>
                  {parameters.pre_shipment_by_branch === '1' && (
                    <Grid item xs={6}>
                      <BranchInput
                        onChange={this.handleChange}
                        value={values.branch}
                      />
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <CarrierInput
                      onChange={this.handleChange}
                      value={values.carrier}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleGeneratePreShipmentModalClose}>
                Cancelar
              </Button>
              <Button onClick={this.create} color="primary">
                Criar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.closeModal}
            onClose={this.handleCloseModalClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Fechar</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Info
                  type="error"
                  onClose={() => this.setState({ modalError: undefined })}
                >
                  {modalError}
                </Info>
                <div>Tem certeza que deseza fechar?</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseModalClose}>Cancelar</Button>
              <Button onClick={this.close} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.shipmentModal}
            onClose={this.handleShipmentModalClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Expedir</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Info
                  type="error"
                  onClose={() => this.setState({ modalError: undefined })}
                >
                  {modalError}
                </Info>
                <div>Tem certeza que deseza expedir?</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleShipmentModalClose}>Cancelar</Button>
              <Button onClick={this.ship} color="primary">
                Expedir
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.reopenModal}
            onClose={this.handleReopenModalClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Reabrir</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Info
                  type="error"
                  onClose={() => this.setState({ modalError: undefined })}
                >
                  {modalError}
                </Info>
                <div>Tem certeza que deseza reabrir?</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleReopenModalClose}>Cancelar</Button>
              <Button onClick={this.reopen} color="primary">
                Reabrir
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(PreShipmentList));
