import * as React from 'react';

interface Props extends React.Props<any> {
  children: (props: any) => React.ReactNode;
}

interface State {
  isOpen: boolean;
}

class Collapse extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  render() {
    return this.props.children({
      ...this.state,
      toggle: this.toggle,
    });
  }

  toggle = () => {
    this.setState(() => ({
      isOpen: !this.state.isOpen,
    }));
  };
}

export default Collapse;
