import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Info from '../components/Infos/Info';
import AppBar from '@material-ui/core/AppBar';
import { Container } from '@material-ui/core';
import Loading from '../components/Loading/Loading';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState, ChangeEvent, useMemo } from 'react';
import WarehouseDashboardTable from '../components/Dashboard/WarehouseDashboardTable';

interface TabPanelProps {
  index: any;
  value: any;
  children?: React.ReactNode;
}

interface Message {
  error?: string;
  success?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
      className={classes.root}
    >
      {value === index && (
        <Box paddingTop={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Container>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    flexGrow: 1,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function WarehouseManagementDashboard({ location }) {
  const warehouses = {
    es01: 'ES-01',
    sp01: 'SP-01',
    rj02: 'RJ-02',
  };

  const [deliveries, setDeliveries] = useState<[]>();
  const [warehouseCode, setWarehouseCode] = useState<string>();

  const [message, setMessage] = useState<Message>({});
  const [loading, setToggleLoading] = useState<boolean>(false);

  const classes = useStyles();
  const { error, success } = message;
  const renderMessage = success ? success : error;

  const handleChange = (event: ChangeEvent<{}>, warehouseCode: string) => {
    setWarehouseCode(warehouseCode);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('warehouse')) {
      return setWarehouseCode(`${params.get('warehouse')}`);
    }

    setWarehouseCode(warehouses.es01);
  }, []);

  const memoizedWarehouseCode = useMemo(
    () => (
      <TabPanel value={warehouseCode} index={warehouseCode}>
        <WarehouseDashboardTable warehouseCode={warehouseCode} />
      </TabPanel>
    ),
    [warehouseCode],
  );

  return (
    <Container className={classes.root}>
      {loading && <Loading />}

      <Info
        type={!error ? 'success' : 'error'}
        onClose={() => setMessage({ error: undefined, success: undefined })}
      >
        {renderMessage}
      </Info>

      <AppBar position="static">
        <Tabs value={warehouseCode} onChange={handleChange}>
          <Tab
            value={warehouses.es01}
            label={warehouses.es01}
            wrapped
            {...a11yProps(warehouses.es01)}
          />
          <Tab
            value={warehouses.sp01}
            label={warehouses.sp01}
            {...a11yProps(warehouses.sp01)}
          />
          <Tab
            value={warehouses.rj02}
            label={warehouses.rj02}
            {...a11yProps(warehouses.rj02)}
          />
        </Tabs>
      </AppBar>

      {memoizedWarehouseCode}
    </Container>
  );
}
