import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import format from 'date-fns/format';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Info from '../components/Infos/Info';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    container: {
      padding: '0 10px',
    },
    containerInput: {
      paddingRight: '20px',
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  invoiceKey?: string;
  delivery?: any;
  values: any;
  events: any[];
  success?: string;
  error?: string;
}

class Invoice extends React.Component<Props, State> {
  state = {
    loading: false,
    invoiceKey: '',
    delivery: null,
    values: {
      input: '',
    },
    events: [],
  };

  audioError: any;
  audioSuccess: any;

  constructor(props) {
    super(props);
    this.audioError = React.createRef();
    this.audioSuccess = React.createRef();
  }

  handleChange = e => {
    e.persist();

    const { name, value } = e.target;

    const { values } = this.state;
    values[name] = value;

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  changeStatus = async delivery => {
    if (!delivery) {
      return;
    }

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      delivery_id: delivery.id,
    };

    try {
      await request('get:/api/delivery/change-status', options);
      await this.success();
    } catch (e) {
      this.setError(
        'Houve um erro ao finalizar: ' +
          (e.response.data.message ? e.response.data.message : e.message),
      );
    }
  };

  success = async () => {
    this.setSuccess('Operação realizada com sucesso');

    setTimeout(() => {
      this.setState({
        success: undefined,
      });
    }, 10000);

    await this.loadReport();
  };

  loadReport = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (await request('get:/api/delivery/report', options)).data;

    this.setState({ events: response.events });
  };

  findDelivery = async invoiceKey => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      invoice_key: invoiceKey,
    };

    this.setState({
      invoiceKey: undefined,
      delivery: undefined,
      error: undefined,
    });

    try {
      const response = (
        await request('get:/api/delivery/find-by-invoice', options)
      ).data;

      if (!response.delivery) {
        this.setError('Nota fiscal não encontrada');
        return;
      }

      this.setState({ invoiceKey, delivery: response.delivery });

      return response.delivery;
    } catch (e) {
      this.setError('Houve um erro: ' + (e.response.data.message || e.message));
    }
  };

  findDeliveryByTrackingCode = async trackingCode => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
      tracking_code: trackingCode,
    };

    this.setState({
      invoiceKey: undefined,
      delivery: undefined,
      error: undefined,
    });

    try {
      const response = (
        await request('get:/api/delivery/find-by-tracking-code', options)
      ).data;

      if (!response.delivery) {
        this.setError('Rastreio não encontrado');
        return;
      }

      const invoiceKey = response.delivery.invoiceKey;

      this.setState({ invoiceKey, delivery: response.delivery });

      return response.delivery;
    } catch (e) {
      this.setError('Houve um erro: ' + (e.response.data.message || e.message));
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { values } = this.state;

    if (!values.input) {
      return;
    }

    const barcode = values.input.trim();

    values.input = '';
    this.setState({ values });

    let delivery = null;
    if (barcode.length === 44) {
      delivery = await this.findDelivery(barcode);
    } else {
      delivery = await this.findDeliveryByTrackingCode(barcode);
    }

    if (delivery) {
      this.changeStatus(delivery);
    }
  };

  setError(error: string) {
    this.setState({ error });

    this.audioError.current.play();
  }

  setSuccess(success: string) {
    this.setState({
      success,
      error: undefined,
      invoiceKey: '',
      delivery: null,
    });
    this.audioSuccess.current.play();
  }

  async componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { classes } = this.props;
    const { values, events, loading, success, error, invoiceKey }: any =
      this.state;

    return (
      <div>
        <audio src="/audio/error.mp3" ref={this.audioError} />
        <audio src="/audio/success.mp3" ref={this.audioSuccess} />

        <form onSubmit={this.handleSubmit}>
          <Info
            type="success"
            onClose={() => this.setState({ success: undefined })}
          >
            {success}
          </Info>

          <Info
            type="error"
            onClose={() => this.setState({ error: undefined })}
          >
            {error}
          </Info>

          <div className={classes.container}>
            {invoiceKey && (
              <Typography variant="h6">Nota Fiscal: {invoiceKey}</Typography>
            )}

            {loading && <Loading />}

            <Grid container>
              <Grid item xs={5} className={classes.containerInput}>
                <TextField
                  name="input"
                  label="Nota Fiscal / Rastreio "
                  margin="normal"
                  autoFocus
                  onChange={this.handleChange}
                  autoComplete={'off'}
                  value={values.input}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={2}>
                <br />
                <br />
              </Grid>
            </Grid>

            {!loading && events.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nota Fiscal</TableCell>
                    <TableCell>Pedido</TableCell>
                    <TableCell>Expedição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event: any) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.invoice_key}</TableCell>
                      <TableCell>{event.order_id}</TableCell>
                      <TableCell>
                        {format(event.shipped_at, 'DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(Invoice);
