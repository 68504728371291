import {
  Grid,
  Button,
  Dialog,
  MenuItem,
  TextField,
  Typography,
  withStyles,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { request } from '../../helpers';
import Info from '../../components/Infos/Info';
import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading/Loading';

interface StorageControllers {
  id: number;
  code: string;
  warehouseCode: string;
}

interface ChangeStation {
  waveId?: number;
  warehouseCode?: string;
  storageControllerCode?: string;
}

interface Message {
  error?: string;
  success?: string;
}

const styles: any = (theme: any) => ({
  root: {
    margin: 20,
  },
  header: {
    color: 'white',
    padding: '20px 30px',
    backgroundColor: '#1A237E',
  },
  inputContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContent: {
    width: '49%',
  },
  buttonPanel: {
    margin: 0,
    padding: 10,
    backgroundColor: theme.palette.grey['300'],
  },
  infoStylePersonalized: {
    height: 40,
    paddingLeft: 32,
    color: '#ffffff',
    backgroundColor: '#228b22',
  },
});

const WaveChangeStation = ({ classes, handleAction, wave }) => {
  const [changeStation, setChangeStation] = useState<ChangeStation>({
    waveId: wave.id,
  });
  const [loading, setToggleLoading] = useState<boolean>(false);
  const [storageControllers, setStorageControllers] = useState<
    StorageControllers[]
  >();
  const [message, setMessage] = useState<Message>({});

  const characterDashCode = 45;
  const { error, success } = message;
  const renderMessage = success ? success : error;
  const enableSaveButton =
    changeStation && Object.keys(changeStation).length > 2 ? false : true;

  const fetchStorageControllers = async () => {
    try {
      setToggleLoading(true);

      const { storageControllers } = (
        await request('GET:/api/storage-controller/find-actives', {})
      ).data;

      setStorageControllers(storageControllers);
    } catch (error) {
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  useEffect(() => {
    fetchStorageControllers();
  }, []);

  const sendChangeStation = async () => {
    try {
      setToggleLoading(true);

      const { message } = (
        await request(
          'POST:/api/storage-controller/change-station',
          changeStation,
        )
      ).data;

      setMessage({ success: message });
    } catch (error) {
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setChangeStation({
      ...changeStation,
      [name]: value,
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={handleAction}
        onClose={handleAction}
      >
        {loading && <Loading />}

        <Typography className={classes.header} variant="h6">
          Alterar onda de estação
        </Typography>

        <Info
          type={!error ? 'success' : 'error'}
          infoStylePersonalized={classes.infoStylePersonalized}
          onClose={() => setMessage({ error: undefined, success: undefined })}
        >
          {renderMessage}
        </Info>

        <DialogContent className={classes.root}>
          <DialogContentText>
            <Grid container>
              <Typography>Onda: {wave.id}</Typography>
            </Grid>

            <Grid container>
              <Typography>
                Otimização:{' '}
                {wave.optimizationType.charCodeAt(0) === characterDashCode
                  ? 'Não há descrição de tipo'
                  : wave.optimizationType}
              </Typography>
            </Grid>

            <Grid container>
              <Typography>
                Descrição:{' '}
                {wave.optimizationDescription.charCodeAt(0) ===
                characterDashCode
                  ? 'Não há descrição de otimização'
                  : wave.optimizationDescription}
              </Typography>
            </Grid>

            <div className={classes.inputContainer}>
              <div className={classes.inputContent}>
                <TextField
                  select
                  required
                  fullWidth
                  label="Armazém"
                  variant="outlined"
                  name="warehouseCode"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  value={changeStation && changeStation.warehouseCode}
                >
                  {storageControllers &&
                    storageControllers.map(
                      (storageController: { warehouseCode: string }) => (
                        <MenuItem
                          key={storageController.warehouseCode}
                          value={storageController.warehouseCode}
                        >
                          {storageController.warehouseCode}
                        </MenuItem>
                      ),
                    )}
                </TextField>
              </div>

              <div className={classes.inputContent}>
                <TextField
                  select
                  required
                  fullWidth
                  name="storageControllerCode"
                  label="Estação"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  value={changeStation && changeStation.storageControllerCode}
                >
                  {storageControllers &&
                    storageControllers.map(
                      (storageController: { code: string }) => (
                        <MenuItem
                          key={storageController.code}
                          value={storageController.code}
                        >
                          {storageController.code}
                        </MenuItem>
                      ),
                    )}
                </TextField>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.buttonPanel}>
          <Button
            color="primary"
            variant="contained"
            onClick={sendChangeStation}
            disabled={enableSaveButton}
          >
            Salvar
          </Button>

          <Button color="secondary" variant="contained" onClick={handleAction}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(WaveChangeStation);
