import {
  Collapse as MaterialCollapse,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Collapse from './../Collapse';
import { HeaderContext } from './HeaderContext';

const menus: any[] = [
  {
    label: 'Relatório',
    path: '/report',
    resources: [
      {
        label: 'Dashboard',
        path: '/dashboard',
      },
      {
        label: 'Consulta de Pedidos',
        path: '/orders',
      },
      {
        label: 'Dashboard Armazéns',
        path: '/dashboard-warehouses',
      },
    ],
  },
  {
    label: 'Onda',
    path: '/wave',
    resources: [
      {
        label: 'Gerar',
        path: '/generate',
      },
      {
        label: 'Listar',
        path: '/list',
      },
      {
        label: 'Inteligente',
        path: '/smart',
      },
      {
        label: 'Otimizar',
        path: '/optimized',
      },
    ],
  },
  {
    label: 'Checkout',
    path: '/checkout',
    resources: [
      {
        label: 'Iniciar',
        path: '/packing',
      },
    ],
  },
  {
    label: 'Romaneio',
    path: '/pre-shipment',
    resources: [
      {
        label: 'Listar',
        path: '/list',
      },
    ],
  },
  {
    label: 'Expedição',
    path: '/delivery',
    resources: [
      {
        label: 'Iniciar',
        path: '/',
      },
    ],
  },
  {
    label: 'Colmeia',
    path: '/storage-controller',
    resources: [
      {
        label: 'Listar',
        path: '/list',
      },
    ],
  },
  {
    label: 'Delivery',
    path: '/delivery',
    resources: [
      {
        label: 'Listar',
        path: '/list',
      },
    ],
  },
  {
    label: 'Reversa',
    path: '/unilog',
    resources: [
      {
        label: 'Nota Fiscal - Devolução',
        path: '/reverse-logistics',
      },
    ],
  },
];

function ParentItem({ menu, path, isOpen, toggle }) {
  const hasResources = menu.resources.length > 0;

  return (
    <ListItem
      button={hasResources as any}
      component={props =>
        hasResources ? (
          <div {...props} onClick={toggle} />
        ) : (
          <Link to={path} {...(props as any)} />
        )
      }
    >
      <ListItemText primary={menu.label} />
      {hasResources && <Icon>{isOpen ? 'expand_less' : 'expand_more'}</Icon>}
    </ListItem>
  );
}

function ChildItem({ menu, path, isOpen, classes, toggle, toggleDrawer }) {
  const hasResources = menu.resources.length > 0;

  if (!hasResources) {
    return null;
  }

  function handleClick() {
    toggle();
    toggleDrawer(false);
  }

  return (
    <MaterialCollapse in={isOpen}>
      <List disablePadding dense>
        {menu.resources.map(child => (
          <ListItem
            onClick={handleClick}
            key={child.label}
            component={props => (
              <Link to={path + child.path} {...(props as any)} />
            )}
            style={{ width: 230 }}
          >
            <ListItemText
              primary={child.label}
              classes={{
                primary: classes.childText,
              }}
            />
          </ListItem>
        ))}
      </List>
    </MaterialCollapse>
  );
}

function MenuItems({ classes }) {
  return (
    <HeaderContext.Consumer>
      {consumer => (
        <List component="nav" subheader={<ListSubheader>Áreas</ListSubheader>}>
          {menus.map(menu => {
            return (
              <Collapse key={menu.label}>
                {({ isOpen, toggle }) => {
                  const props = {
                    menu,
                    isOpen,
                    toggle,
                    classes,
                    path: `${menu.path}`,
                  };

                  return (
                    <>
                      <ParentItem {...props} />
                      <ChildItem
                        {...props}
                        toggleDrawer={consumer.toggleDrawer}
                      />
                    </>
                  );
                }}
              </Collapse>
            );
          })}
        </List>
      )}
    </HeaderContext.Consumer>
  );
}

const styles = theme => ({
  childText: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(3),
  },
});

export default withStyles(styles)(MenuItems);
