import {
  Grid,
  Paper,
  Table,
  Button,
  Dialog,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import moment from 'moment';
import cn from 'classnames';
import { request } from '../../helpers';
import Loading from '../Loading/Loading';
import React, { useState, useEffect } from 'react';

interface Orders {
  id: number;
  orderId: number;
  carrierCode: string;
  invoiceNumber: string;
  status: {
    name: string;
  };
  packingAt: string;
  preShipmentAt: string;
  shipmentAt: string;
  invoice: {
    trackingCode: string;
  };
}

interface waveOrders {
  id?: number;
  createdAt?: string;
  warehouseCode?: string;
  optimizationType?: string;
  optimizationDescription?: string;
}

const styles: any = (theme: any) => ({
  root: {
    margin: 20,
    borderRadius: 5,
    position: 'relative',
    border: '2px solid #1A237E',
  },
  tableHeader: {
    fontWeight: 700,
    marginBottom: 1000,
    padding: '3px 12px',
    textTransform: 'uppercase',
    border: 'solid #8294b8 2px',
    background: theme.palette.primary.dark,
  },
  tableHeaderText: {
    color: theme.palette.primary.contrastText,
  },
  row: {
    '&:nth-child(odd)': {
      background: theme.palette.grey['100'],
    },
    '&:hover': {
      background: theme.palette.grey['300'],
    },
  },
  text: {
    top: 5,
    left: 50,
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    padding: '0px 10px 0px 10px',
  },
  headerContainer: {
    width: '90%',
    marginTop: 10,
    margin: 'auto',
    padding: '1.5em 1.5em',
  },
  columnReduceSize: {
    padding: '0px 5px 0px 20px',
  },
});

const WaveOrders = ({ classes, handleAction, wave, ...props }) => {
  const [orders, setOrders] = useState<Orders[]>();
  const [toggleLoading, setToggleLoading] = useState(false);
  const [waveOrders, setWaveOrders] = useState<waveOrders>({});

  const fields = [
    'Pedido',
    'Packing',
    'Romaneio',
    'Expedido',
    'Nota Fiscal',
    'Transportadora',
    'Rastreio',
    'Status',
  ];

  const dateFormatter = (date: string) => {
    moment.updateLocale(moment.locale(), { invalidDate: '-' });
    return moment(date).format('DD/MM/YY HH:mm:ss');
  };

  const fetchOrders = async () => {
    try {
      setToggleLoading(true);

      const { waveOrders } = (
        await request(`GET:/api/wave/orders/${wave.id}`, {})
      ).data;

      setOrders(waveOrders.handlingUnits.map(order => order.delivery));

      setWaveOrders(waveOrders);
    } catch (error) {
      console.error('Error', error);
      throw new Error(error);
    } finally {
      setToggleLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <Dialog maxWidth={'xl'} open={handleAction} onClose={handleAction}>
        <Typography className={classes.text} variant="h6">
          Pedidos
        </Typography>

        <DialogContent className={classes.root}>
          <DialogContentText>
            <Grid
              container
              key={waveOrders.id}
              className={classes.headerContainer}
            >
              <Grid xs={2} item container direction="column">
                <Typography color="textPrimary">Onda</Typography>

                <Typography variant="h5" color="textPrimary">
                  {waveOrders.id}
                </Typography>
              </Grid>

              <Grid xs item container direction="column">
                <Typography color="textPrimary">Abertura</Typography>

                <Typography variant="h5" color="textPrimary">
                  {moment(waveOrders.createdAt).format('DD/MM/YY HH:mm')}
                </Typography>
              </Grid>

              <Grid xs item container direction="column">
                <Typography color="textPrimary">Armazém</Typography>

                <Typography variant="h5" color="textPrimary">
                  {waveOrders.warehouseCode}
                </Typography>
              </Grid>

              <Grid
                md
                item
                container
                direction="column"
                style={{ borderLeft: '2px solid gray', paddingLeft: 15 }}
              >
                <Typography variant="h6" color="textPrimary">
                  Tipo: {waveOrders.optimizationType}
                </Typography>

                <Typography variant="h6" color="textPrimary">
                  Descrição: {waveOrders.optimizationDescription}{' '}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
          <Paper>
            {toggleLoading && <Loading />}

            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {fields.map(field => {
                    return (
                      <TableCell
                        key={field}
                        className={cn(
                          classes.tableHeaderText,
                          classes.columnReduceSize,
                        )}
                      >
                        {field}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {orders &&
                  orders.map(order => {
                    return (
                      <TableRow key={order.id} className={classes.row}>
                        <TableCell className={classes.columnReduceSize}>
                          {order.orderId}
                        </TableCell>
                        <TableCell>{dateFormatter(order.packingAt)}</TableCell>
                        <TableCell>
                          {dateFormatter(order.preShipmentAt)}
                        </TableCell>
                        <TableCell>{dateFormatter(order.shipmentAt)}</TableCell>
                        <TableCell>{order.invoiceNumber}</TableCell>
                        <TableCell>{order.carrierCode}</TableCell>
                        <TableCell>{order.invoice.trackingCode}</TableCell>
                        <TableCell>{order.status.name}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(WaveOrders);
