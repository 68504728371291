import { CircularProgress, withStyles } from '@material-ui/core';
import * as React from 'react';

function Loading({ classes }) {
  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader} />
    </div>
  );
}

const styles: any = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, .8)',
    zIndex: 10,
  },
  loader: {
    '&:focus': {
      outline: 'none',
    },
  },
});

export default withStyles(styles)(Loading);
