import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Info from '../components/Infos/Info';
import CarrierInput from '../components/Inputs/CarrierInput';
import BrandInput from '../components/Inputs/BrandInput';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  values: any;
  bundles: any[];
  currentBundle?: any;
  parameters?: any;
  generateOrderModal: boolean;
  success?: string;
  error?: string;
}

class WaveGenerate extends React.Component<Props, State> {
  state = {
    loading: false,
    values: {
      carrier: '',
      brand: '',
      limit: '',
    },
    bundles: [],
    parameters: {},
    currentBundle: undefined,
    generateOrderModal: false,
  };

  handleCarrierChange = async e => {
    this.handleChange(e);

    await this.loadReport();
  };

  handleBrandChange = async e => {
    this.handleChange(e);

    await this.loadReport();
  };

  handleChange = e => {
    const { name, value } = e.target;

    const { values } = this.state;

    values[name] = value;

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  handleGenerateOrderModalOpen = bundle => {
    this.setState({ generateOrderModal: true, currentBundle: bundle });
  };

  handleGenerateOrderModalClose = () => {
    this.setState({ generateOrderModal: false });
  };

  generate = async () => {
    this.handleGenerateOrderModalClose();

    const { values, currentBundle }: any = this.state;

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    if (currentBundle) {
      options.bundle_id = currentBundle.bundle_id;
    }

    if (values.carrier) {
      options.carrier_code = values.carrier;
    }

    if (values.brand) {
      options.brand_code = values.brand;
    }

    if (values.limit) {
      options.limit = values.limit;
    }

    try {
      this.setState({ loading: true });

      const response = (await request('get:/api/wave/generate', options)).data;
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      this.setState({
        success: 'Onda gerada com sucesso!',
        error: undefined,
        loading: false,
      });

      this.setState({ loading: true });

      await this.loadReport();

      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        error: 'Houve um problema na geração da onda!',
        success: undefined,
        loading: false,
      });
    }
  };

  loadReport = async () => {
    const { values } = this.state;

    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    if (values.carrier) {
      options.carrier_code = values.carrier;
    }

    if (values.brand) {
      options.brand_code = values.brand;
    }

    const response = (await request('get:/api/wave/report', options)).data;

    this.setState({ bundles: response.bundles });
  };

  loadParameters = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (await request('get:/api/wave/parameters', options)).data;

    this.setState({ parameters: response.parameters });
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await this.loadReport();
    await this.loadParameters();

    this.setState({ loading: false });
  }

  render() {
    const { classes } = this.props;
    const {
      values,
      bundles,
      loading,
      currentBundle,
      parameters,
      success,
      error,
    }: any = this.state;

    return (
      <div>
        <Info
          type="success"
          onClose={() => this.setState({ success: undefined })}
        >
          {success}
        </Info>

        <Info type="error" onClose={() => this.setState({ error: undefined })}>
          {error}
        </Info>
        {loading && <Loading />}
        <Grid container>
          <Grid item xs={3}>
            <CarrierInput
              onChange={this.handleCarrierChange}
              value={values.carrier}
            />
          </Grid>
          <Grid item xs={3}>
            <BrandInput
              onChange={this.handleBrandChange}
              value={values.brand}
            />
          </Grid>
          <Grid item xs={6}>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleGenerateOrderModalOpen(null)}
            >
              Gerar Tudo
            </Button>
          </Grid>
        </Grid>
        {!loading && bundles.length === 0 && (
          <Paper className={classes.root} elevation={1}>
            <Typography paragraph={true}>
              Não foram encontrados nenhum resultado
            </Typography>
          </Paper>
        )}

        {!loading && bundles.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Marca</TableCell>
                <TableCell>Descrição Kit</TableCell>
                <TableCell>Qtd. Kit</TableCell>
                <TableCell>Descrição Produto</TableCell>
                <TableCell>Qtd. por Kit</TableCell>
                <TableCell>Qtd. Total</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bundles.map((bundle: any) =>
                bundle.products.map((product: any, productIndex) => (
                  <TableRow key={bundle.id + '_' + productIndex}>
                    {productIndex === 0 && (
                      <React.Fragment>
                        <TableCell rowSpan={bundle.products.length}>
                          {bundle.brand_code}
                        </TableCell>
                        <TableCell rowSpan={bundle.products.length}>
                          {bundle.description || 'Kit Genérico'}
                        </TableCell>
                        <TableCell rowSpan={bundle.products.length}>
                          {bundle.total}
                        </TableCell>
                      </React.Fragment>
                    )}
                    <TableCell>{product.sku_description}</TableCell>
                    <TableCell>{product.qty}</TableCell>
                    <TableCell>{product.qty * bundle.total}</TableCell>
                    {productIndex === 0 && (
                      <TableCell rowSpan={bundle.products.length}>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.handleGenerateOrderModalOpen(bundle)
                          }
                        >
                          Produzir
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
        )}

        <Dialog
          open={this.state.generateOrderModal}
          onClose={this.handleGenerateOrderModalClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Gerar Pedidos</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {currentBundle && currentBundle.description!}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="limit"
              label="Quantidade"
              type="text"
              fullWidth
              onChange={this.handleChange}
            />
            <DialogContentText>
              Limite de {parameters.generate_limit}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleGenerateOrderModalClose}>
              Cancelar
            </Button>
            <Button onClick={this.generate} color="primary">
              Gerar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(WaveGenerate);
