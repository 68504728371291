import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import format from 'date-fns/format';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Info from '../components/Infos/Info';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      position: 'absolute',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100%',
    },
    textField: {
      width: 170,
    },
    generatePreShipmentModalContainer: {
      minWidth: '500px',
    },
    button: { background: '#ffffff', color: '#000000' },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  values: any;
  filters: any;
  waves: any[];
  success?: string;
  error?: string;
}

class WaveList extends React.Component<Props, State> {
  state = {
    loading: false,
    values: {},
    filters: {},
    waves: [],
  };

  loadList = async () => {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const { filters }: any = this.state;

    if (filters.id) {
      options.id = filters.id;
    }

    const response = (await request('get:/api/wave/list', options)).data;

    this.setState({ waves: response.waves });
  };

  handleChange = e => {
    const { name, value } = e.target;

    const { values } = this.state;

    values[name] = value;

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  handleFilter = e => {
    const { name, value } = e.target;

    const { filters } = this.state;

    filters[name] = value;

    return new Promise(resolve =>
      this.setState({ filters }, async () => {
        await this.loadList();
        resolve(undefined);
      }),
    );
  };

  reprintList = async wave => {
    await request('get:/api/wave/reprint-list', { id: wave.id });
    this.setState({
      error: undefined,
      success: `Impressão da lista gerada com sucesso`,
    });
  };

  reprintInvoices = async wave => {
    await request('get:/api/wave/reprint-invoices', { id: wave.id });
    this.setState({
      error: undefined,
      success: `Impressão de notas gerada com sucesso`,
    });
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await this.loadList();

    this.setState({ loading: false });
  }

  render() {
    const { classes } = this.props;
    const { waves, loading, success, error, values, filters, modalError }: any =
      this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <Info
            type="success"
            onClose={() => this.setState({ success: undefined })}
          >
            {success}
          </Info>

          <Info
            type="error"
            onClose={() => this.setState({ error: undefined })}
          >
            {error}
          </Info>

          <div>
            <Grid container>
              <Grid item xs={2}>
                <TextField
                  name="id"
                  label="ID"
                  margin="normal"
                  className={classes.textField}
                  onChange={this.handleFilter}
                  value={filters.id}
                />
              </Grid>
            </Grid>
          </div>

          <div>{loading && <Loading />}</div>

          {!loading && waves.length === 0 && (
            <Paper elevation={1} component="div">
              <Typography paragraph={true}>
                Não foram encontrados nenhum resultado
              </Typography>
            </Paper>
          )}

          {!loading && waves.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {waves.map((wave: any) => (
                  <TableRow key={wave.id}>
                    <TableCell>{wave.id}</TableCell>
                    <TableCell>{wave.username}</TableCell>
                    <TableCell>
                      {format(wave.createdAt, 'DD/MM/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>{wave.total}</TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {popupState => (
                          <React.Fragment>
                            <Button
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              Ação
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  this.reprintList(wave);
                                }}
                              >
                                <ListItemIcon>
                                  <ListIcon />
                                </ListItemIcon>

                                <ListItemText inset>
                                  Reimprimir Lista
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  this.reprintInvoices(wave);
                                }}
                              >
                                <ListItemIcon>
                                  <RestorePageIcon />
                                </ListItemIcon>

                                <ListItemText inset>
                                  Reimprimir Notas
                                </ListItemText>
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(WaveList));
