import {
  Box,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  makeStyles,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import { request } from '../../helpers';
import { Send } from '@material-ui/icons';
import Info from '../Infos/Info';
import { Theme } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Loading from '../Loading/Loading';

interface WarehouseDeliveries {
  delivery: {
    open?: number;
    total?: number;
    status?: string;
    canceled?: number;
    shipped?: number;
    preShipment?: number;
    awaitingWave?: number;
    awaitingStock?: number;
    warehouseTime?: number;
    warehouseCode?: string;
    expeditionDate?: string;
    awaitingPacking?: number;
    awaitingInvoice?: number;
    warehouseDeadline: string;
  };
}

interface Message {
  error?: string;
  success?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },
  tableHeader: {
    maxWidth: 300,
    fontWeight: 700,
    padding: '3px 12px',
    textTransform: 'uppercase',
    border: 'solid #8294b8 2px',
    background: theme.palette.primary.dark,
  },
  tableHeaderText: {
    color: theme.palette.primary.contrastText,
  },
  row: {
    '&:nth-child(odd)': {
      background: theme.palette.grey['100'],
    },
    '&:hover': {
      background: theme.palette.grey['300'],
    },
  },
  tableCellDeliveryAwaitingInvoice: {
    backgroundColor: '#00bfff',
  },
  tableCellDeliveryAwaitingStock: {
    backgroundColor: '#ff9f65',
  },
  tableCellDeliveryOpen: {
    backgroundColor: '#fffe00',
  },
  tableCellToday: {
    backgroundColor: '#cbcbcb',
    '&:hover': {
      background: theme.palette.grey['400'],
    },
  },
}));

const fields = [
  'HOJE',
  'Data expedição',
  'Prazo',
  'Total',
  'Em processamento',
  'Ag estoque',
  'Aberto',
  'Ag Onda',
  'Ag Pack',
  'Ag exped',
  'Expedido',
  'Cancelado',
];

export default function WarehouseDashboardTable({ warehouseCode }) {
  const [message, setMessage] = useState<Message>({});
  const [warehouseDeliveries, setWarehouseDeliveries] = useState<
    WarehouseDeliveries[]
  >();
  const [loading, setToggleLoading] = useState<boolean>(false);

  const classes = useStyles();
  const { error, success } = message;
  const renderMessage = success ? success : error;

  useEffect(() => {
    fetchWarehouseDeliveries(warehouseCode);
  }, [warehouseCode]);

  const fetchWarehouseDeliveries = async (warehouseCode: string) => {
    try {
      if (!warehouseCode) {
        return;
      }

      setToggleLoading(true);

      const { deliveries } = (
        await request('GET:/api/delivery/warehouse-management-dashboard', {
          warehouseCode,
        })
      ).data;

      setWarehouseDeliveries(deliveries);
    } catch (error) {
      setMessage({ error: error.message });
    } finally {
      setToggleLoading(false);
    }
  };

  return (
    <Paper className={classes.root}>
      {loading && <Loading />}

      <Info
        type={!error ? 'success' : 'error'}
        onClose={() => setMessage({ error: undefined, success: undefined })}
      >
        {renderMessage}
      </Info>

      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {fields.map(field => (
              <TableCell key={field} className={classes.tableHeaderText}>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'regular', fontSize: 12, m: 1 }}>
                    {field}
                  </Box>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {warehouseDeliveries &&
            warehouseDeliveries.map(({ delivery }, index) => (
              <TableRow
                key={index}
                className={cn(
                  index !== 4 ? classes.row : null,
                  index === 4 ? classes.tableCellToday : null,
                )}
              >
                <TableCell className={classes.tableCellToday}>
                  {index === 4 ? <Send color="error" /> : null}
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 14,
                        fontWeight: index === 4 ? 'bold' : 'regular',
                      }}
                    >
                      {delivery.warehouseDeadline}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.warehouseTime || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.total || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell className={classes.tableCellDeliveryAwaitingInvoice}>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.awaitingInvoice || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell className={classes.tableCellDeliveryAwaitingStock}>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.awaitingStock || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell className={classes.tableCellDeliveryOpen}>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'bold',
                      }}
                    >
                      {delivery.open || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.awaitingWave || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.awaitingPacking || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.preShipment || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.shipped || 0}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        fontSize: 13.5,
                        fontWeight: 'regular',
                      }}
                    >
                      {delivery.canceled || 0}
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
