import {
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import TimelineIcon from '@material-ui/icons/Timeline';
import cn from 'classnames';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from '../components/Loading/Loading';
import { request } from '../helpers';
import Cookies from 'js-cookie';
import DeliveryWarehouseDeadline from '../components/Dashboard/DeliveryWarehouseDeadline';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    title: {
      textAlign: 'center',
      fontSize: '30px',
    },
    boxContainer: {
      marginTop: '50px',
    },
    boxParent: {},
    box: {
      border: '1px solid #000',
      height: '180px',
      background: 'red',
      margin: 'auto',
      padding: '15px 10px',
      textAlign: 'center',
      width: '180px',
    },

    total: {
      paddingTop: '20px',
      fontSize: '40px',
    },
    orders: {
      paddingTop: '15px',
      lineHeight: '30px',
    },
    ordersTotal: {
      fontSize: '30px',
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    boxTypography: {
      fontSize: '20px',
      textAlign: 'center',
      color: '#fff',
    },
    awaitingWave: {
      background: '#3f51b5',
    },
    awaitingCheckout: {
      background: '#a97544',
    },
    awaitingPreShipment: {
      background: '#984300',
    },
    awaitingClosePreShipment: {
      background: '#ce8800',
    },
    awaitingShipment: {
      background: '#ac7a00',
    },
    lateDeliveries: {
      background: '#dc143c',
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  report: any;
  anchorEl?: any;
  status?: string;
  preShipmentStatus?: string;
  showLateDeliveries: boolean;
  lateDeliveries: any[];
}

class Dashboard extends React.Component<Props, State> {
  state = {
    loading: true,
    report: {},
    anchorEl: null,
    status: undefined,
    preShipmentStatus: undefined,
    showLateDeliveries: false,
    lateDeliveries: [],
  };
  async loadReport() {
    const options: any = {
      warehouse_code: Cookies.get('warehouse'),
    };

    const response = (await request('get:/api/report/dashboard', options)).data;

    const report = {
      awaitingWave: 0,
      awaitingCheckout: 0,
      awaitingPreShipment: 0,
      awaitingClosePreShipment: 0,
      awaitingShipment: 0,
      preShipmentsAwaitingClose: 0,
      preShipmentsAwaitingShipment: 0,
    };

    const map = {
      DELIVERY_OPEN_: 'awaitingWave',
      DELIVERY_WAVE_: 'awaitingCheckout',
      DELIVERY_PACKING_: 'awaitingPreShipment',
      DELIVERY_PRE_SHIPMENT_HANDLING_UNIT_OPEN: 'awaitingClosePreShipment',
      DELIVERY_PRE_SHIPMENT_HANDLING_UNIT_CLOSED: 'awaitingShipment',
      DELIVERY_PRE_SHIPMENT_HANDLING_UNIT_OPEN_PRE_SHIPMENTS:
        'preShipmentsAwaitingClose',
      DELIVERY_PRE_SHIPMENT_HANDLING_UNIT_CLOSED_PRE_SHIPMENTS:
        'preShipmentsAwaitingShipment',
    };

    for (const item of response.dashboard) {
      const handingUnitStatusCode = item.handing_unit_status_code || '';
      let key = `${item.status_code}_${handingUnitStatusCode}`;

      if (map[key]) {
        let code = map[key];
        report[code] = item.total;

        if (item.total_handling_unit) {
          key += '_PRE_SHIPMENTS';
          if (map[key]) {
            code = map[key];
            report[code] = item.total_handling_unit;
          }
        }
      }
    }

    this.setState({ report, loading: false });
  }

  componentDidMount() {
    this.loadReport();
    this.getDeliveriesWarehouseDeadline();
  }

  openMenu(e, status: string, preShipmentStatus?: string) {
    this.setState({ anchorEl: e.currentTarget, status, preShipmentStatus });
  }
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  goToOrders = () => {
    const { status, preShipmentStatus } = this.state;
    // @ts-ignore
    this.props.history.push(
      `/report/dashboard-orders?status=${status}&pre_shipment_status=${
        preShipmentStatus || ''
      }`,
    );
    this.handleCloseMenu();
  };

  goToProcess = () => {
    const { status } = this.state;
    const links = {
      DELIVERY_OPEN: '/wave/generate',
      DELIVERY_WAVE: '/packing',
      DELIVERY_PACKING: '/pre-shipment/list',
      DELIVERY_PRE_SHIPMENT: '/pre-shipment/list',
    };

    this.handleCloseMenu();

    if (!status) {
      return;
    }
    // @ts-ignore
    this.props.history.push(links[status]);
  };

  render() {
    const {
      loading,
      report,
      anchorEl,
      showLateDeliveries,
      lateDeliveries,
    }: any = this.state;

    const { classes } = this.props;

    return (
      <div>
        <Typography variant="h6" className={classes.title}>
          Dashboard - Processamento de Pedidos
        </Typography>
        <div>{loading && <Loading />}</div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.goToOrders}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>

            <ListItemText inset>Ver pedidos</ListItemText>
          </MenuItem>
          <MenuItem onClick={this.goToProcess}>
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>

            <ListItemText inset>Ir pra tela de processos</ListItemText>
          </MenuItem>
        </Menu>

        <Grid container className={classes.boxContainer} justify="space-evenly">
          <Grid item xs={2} className={classes.boxParent}>
            <div className={cn(classes.box, classes.awaitingWave)}>
              <Typography paragraph={true} className={classes.boxTypography}>
                Pedidos Ag. Geração de Onda
                <div className={classes.total}>
                  <a
                    href="javascript:void(0)"
                    className={classes.link}
                    onClick={e => {
                      this.openMenu(e, 'DELIVERY_OPEN');
                    }}
                  >
                    {report.awaitingWave}
                  </a>
                </div>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} className={classes.boxParent}>
            <div className={cn(classes.box, classes.awaitingCheckout)}>
              <Typography paragraph={true} className={classes.boxTypography}>
                Pedidos Ag. Checkout
                <div className={classes.total}>
                  <a
                    href="javascript:void(0)"
                    className={classes.link}
                    onClick={e => {
                      this.openMenu(e, 'DELIVERY_WAVE');
                    }}
                  >
                    {report.awaitingCheckout}
                  </a>
                </div>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} className={classes.boxParent}>
            <div className={cn(classes.box, classes.awaitingPreShipment)}>
              <Typography paragraph={true} className={classes.boxTypography}>
                Pedidos Ag. Romaneio
                <div className={classes.total}>
                  <a
                    href="javascript:void(0)"
                    className={classes.link}
                    onClick={e => {
                      this.openMenu(e, 'DELIVERY_PACKING');
                    }}
                  >
                    {report.awaitingPreShipment}
                  </a>
                </div>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container className={classes.boxContainer} justify="space-evenly">
          <Grid item xs={2} className={classes.boxParent}>
            <div className={cn(classes.box, classes.awaitingClosePreShipment)}>
              <Typography paragraph={true} className={classes.boxTypography}>
                Romaneios <br />
                em Aberto
                <div className={classes.total}>
                  <a
                    href="javascript:void(0)"
                    className={classes.link}
                    onClick={e => {
                      this.openMenu(
                        e,
                        'DELIVERY_PRE_SHIPMENT',
                        'HANDLING_UNIT_OPEN',
                      );
                    }}
                  >
                    {report.preShipmentsAwaitingClose}
                  </a>
                </div>
                <div className={classes.orders}>
                  Pedidos:{' '}
                  <span className={classes.ordersTotal}>
                    {report.awaitingClosePreShipment}
                  </span>
                </div>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} className={classes.boxParent}>
            <div className={cn(classes.box, classes.awaitingShipment)}>
              <Typography paragraph={true} className={classes.boxTypography}>
                Romaneios Ag. Expedição
                <div className={classes.total}>
                  <a
                    href="javascript:void(0)"
                    className={classes.link}
                    onClick={e => {
                      this.openMenu(
                        e,
                        'DELIVERY_PRE_SHIPMENT',
                        'HANDLING_UNIT_CLOSED',
                      );
                    }}
                  >
                    {report.preShipmentsAwaitingShipment}
                  </a>
                </div>
                <div className={classes.orders}>
                  Pedidos:{' '}
                  <span className={classes.ordersTotal}>
                    {report.awaitingShipment}
                  </span>
                </div>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} className={classes.boxParent}>
            <div className={cn(classes.box, classes.lateDeliveries)}>
              <Typography paragraph={true} className={classes.boxTypography}>
                Pedidos atrasados
                <div className={classes.total}>
                  <a
                    href="javascript:void(0)"
                    className={classes.link}
                    onClick={this.toggleDeliveryWarehouseDeadline}
                  >
                    {lateDeliveries.length}
                  </a>
                </div>
              </Typography>
            </div>
          </Grid>
        </Grid>

        {showLateDeliveries && (
          // @ts-ignore
          <DeliveryWarehouseDeadline
            toggleDeliveryWarehouseDeadline={() =>
              this.toggleDeliveryWarehouseDeadline()
            }
            lateDeliveries={lateDeliveries}
          />
        )}
      </div>
    );
  }

  toggleDeliveryWarehouseDeadline = () => {
    this.setState({
      showLateDeliveries: !this.state.showLateDeliveries,
    });
  };

  getDeliveriesWarehouseDeadline = async () => {
    try {
      const { deliveries } = (
        await request('GET:/api/delivery/delivery-warehouse-deadline', {})
      ).data;

      this.setState({
        lateDeliveries: deliveries,
      });
    } catch (e) {
      throw new Error(e);
    }
  };
}

export default withStyles(styles)(Dashboard);
