import {
  Search,
  MoreVert,
  BorderColor,
  PlaylistAddCheck,
  Add,
  Close,
} from '@material-ui/icons';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import {
  Menu,
  Paper,
  Table,
  Divider,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  Typography,
  ListItemText,
  ListItemIcon,
  InputAdornment,
  Fab,
  IconButton,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import { request } from '../helpers';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { History, LocationState } from 'history';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import StorageControllerAdd from '../components/StorageController/StorageControllerAdd';
import StorageControllerEdit from '../components/StorageController/StorageControllerEdit';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      padding: theme.spacing(3),
      width: '95%',
      margin: 'auto',
    },
    resetDefaultBehavior: {
      textDecoration: 'none',
    },
    headerContainer: {
      width: '95%',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    searchBar: {
      flexBasis: '60%',
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
  });

interface Props extends WithStyles<typeof styles> {
  history: History<LocationState>;
  allStorageControllers: any;
}

interface State {
  allStorageControllers: [];
  fields: string[];
  toggleControllers: boolean;
  controllersFiltered: [];
  showStorageControllerAdd: boolean;
  showStorageControllerModalEdit: boolean;
  storageController: {};
}

class StorageControllerList extends Component<Props, State> {
  state: State = {
    allStorageControllers: [],
    toggleControllers: false,
    controllersFiltered: [],
    fields: ['Armazém', 'Colmeia', 'Ação'],
    showStorageControllerAdd: false,
    showStorageControllerModalEdit: false,
    storageController: {},
  };

  getAllControllers = async () => {
    const warehouseCode = Cookies.get('warehouse');
    try {
      const response = (
        await request('GET:/api/storage-controller/list/', {
          warehouse_code: warehouseCode,
        })
      ).data;

      this.setState({
        allStorageControllers: response.storageControllers,
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  componentDidMount() {
    this.getAllControllers();
  }

  handleChange = async event => {
    event.preventDefault();
    const { allStorageControllers } = this.state;
    const {
      target: { value },
    } = event;

    const controllerFind = allStorageControllers.filter(controller =>
      //@ts-ignore
      controller.code.toLowerCase().includes(value.toLowerCase()),
    );
    this.setState({
      toggleControllers: true,
      controllersFiltered: controllerFind,
    } as State);
  };

  toggleAddStorageController = () => {
    this.setState({
      showStorageControllerAdd: !this.state.showStorageControllerAdd,
    });
  };

  redirectToStorageControllerItems = async storageControllerId => {
    return this.props.history.push(
      `/storage-controller-items/list/${await storageControllerId}`,
    );
  };

  toggleStorageControllerEditModal = storageController => {
    this.setState({
      showStorageControllerModalEdit:
        !this.state.showStorageControllerModalEdit,
      storageController: storageController,
    });
  };

  updateListItems = () => {
    this.getAllControllers();
    this.forceUpdate();
  };

  render() {
    const {
      fields,
      toggleControllers,
      allStorageControllers,
      controllersFiltered,
      showStorageControllerAdd,
      showStorageControllerModalEdit,
      storageController,
    }: any = this.state;
    const { classes }: Props = this.props;
    const showStorageControllers = toggleControllers
      ? controllersFiltered
      : allStorageControllers;

    return (
      <>
        <Paper className={classes.root}>
          <header className={classes.headerContainer}>
            <Typography variant="h4" color="inherit">
              Colmeias
            </Typography>

            <TextField
              type="search"
              variant="outlined"
              label="Buscar Colmeia"
              className={classes.searchBar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={event => this.handleChange(event)}
            />

            <div>
              <Fab
                color="primary"
                aria-label="add"
                onClick={this.toggleAddStorageController}
              >
                {showStorageControllerAdd ? <Close /> : <Add />}
              </Fab>
            </div>
          </header>
        </Paper>

        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '95%' }}
        />

        {showStorageControllerAdd ? (
          <>
            {
              // @ts-ignore
              <StorageControllerAdd
                redirect={storageControllerId =>
                  this.redirectToStorageControllerItems(storageControllerId)
                }
              />
            }
            <Divider
              variant="middle"
              style={{ margin: '20px auto ', width: '95%' }}
            />
          </>
        ) : null}

        <main>
          <Paper className={classes.root}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell />

                  {fields.map(field => {
                    return (
                      <TableCell className={classes.tableHeaderText}>
                        {field}
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {showStorageControllers.map(storageController => {
                  return (
                    <TableRow key={storageController.id}>
                      <TableCell />

                      <TableCell>{storageController.warehouseCode}</TableCell>
                      <TableCell>{storageController.code}</TableCell>

                      <TableCell>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreVert />
                              </IconButton>

                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    this.toggleStorageControllerEditModal(
                                      storageController,
                                    );
                                    this.updateListItems();
                                  }}
                                >
                                  <ListItemIcon>
                                    <BorderColor />
                                  </ListItemIcon>

                                  <ListItemText inset>Editar</ListItemText>
                                </MenuItem>

                                <Link
                                  className={classes.resetDefaultBehavior}
                                  to={`/storage-controller-items/list/${storageController.id}`}
                                >
                                  <MenuItem>
                                    <ListItemIcon>
                                      <PlaylistAddCheck />
                                    </ListItemIcon>

                                    <ListItemText inset>
                                      Mostrar itens
                                    </ListItemText>
                                  </MenuItem>
                                </Link>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </main>

        {showStorageControllerModalEdit ? (
          <StorageControllerEdit
            {...storageController}
            updateListItems={() => this.updateListItems()}
          />
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(StorageControllerList);
