import {
  Box,
  Divider,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Info from '../components/Infos/Info';
import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading/Loading';
import { request } from '@beyounglabs/alfred/helpers/request';
import WarehouseDashboardCard from '../components/Dashboard/WarehouseDashboardCard';

interface Message {
  error?: string;
  success?: string;
}

interface Warehouses {
  [key: string]: any;
}

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
}));

const warehouseCode = {
  es01: 'ES-01',
  sp01: 'SP-01',
  rj02: 'RJ-02',
};

export default function WarehousesManagementDashboardGeneral() {
  const [message, setMessage] = useState<Message>({});
  const [loading, setToggleLoading] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<Warehouses>({
    updatedAt: '',
    'SP-01': {
      open: {
        onTime: {
          orders: 0,
          awaitingWave: 0,
          awaitingPacking: 0,
          awaitingShipment: 0,
        },
        delay: {
          orders: 0,
          awaitingWave: 0,
          awaitingPacking: 0,
          awaitingShipment: 0,
        },
      },
      finishedToday: {
        delay: { ordersShipped: 0, ordersPacking: 0 },
        onTime: { ordersShipped: 0, ordersPacking: 0 },
      },
    },
    'ES-01': {
      open: {
        onTime: {
          orders: 0,
          awaitingWave: 0,
          awaitingPacking: 0,
          awaitingShipment: 0,
        },
        delay: {
          orders: 0,
          awaitingWave: 0,
          awaitingPacking: 0,
          awaitingShipment: 0,
        },
      },
      finishedToday: {
        delay: { ordersShipped: 0, ordersPacking: 0 },
        onTime: { ordersShipped: 0, ordersPacking: 0 },
      },
    },
    'RJ-02': {
      open: {
        onTime: {
          orders: 0,
          awaitingWave: 0,
          awaitingPacking: 0,
          awaitingShipment: 0,
        },
        delay: {
          orders: 0,
          awaitingWave: 0,
          awaitingPacking: 0,
          awaitingShipment: 0,
        },
      },
      finishedToday: {
        delay: { ordersShipped: 0, ordersPacking: 0 },
        onTime: { ordersShipped: 0, ordersPacking: 0 },
      },
    },
  });

  const classes = useStyles();
  const { error, success } = message;
  const renderMessage = success ? success : error;

  useEffect(() => {
    async function fetchWarehouses() {
      try {
        setToggleLoading(true);

        const response = (
          await request(
            `POST:/api/delivery/brief-warehouses-management-dashboard`,
            { warehouses: Object.values(warehouseCode) },
          )
        ).data.warehouses;

        setWarehouses(response);
      } catch (e) {
        setMessage({ error: e.message });
      } finally {
        setToggleLoading(false);
      }
    }

    fetchWarehouses();
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      {loading && <Loading />}

      <Info
        type={!error ? 'success' : 'error'}
        onClose={() => setMessage({ error: undefined, success: undefined })}
      >
        {renderMessage}
      </Info>

      <Box display="flex" justifyContent="space-between">
        <Typography>
          <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>
            Dashboard de armazéns
          </Box>
        </Typography>

        <Typography>Última atualização: {warehouses.updatedAt}</Typography>
      </Box>

      <Divider />

      <Box display="flex">
        {/*Card São Paulo*/}
        <WarehouseDashboardCard
          stateName="São Paulo"
          backgroundColorChildCard="#350368"
          warehouse={{ ...warehouses['SP-01'], code: warehouseCode.sp01 }}
          stateFlag="https://www.gov.br/anm/pt-br/canais_atendimento/fale-conosco/uf-flag/saopaulo.jpg/@@images/ad644b5c-4bae-46e0-8215-3053c3b5a537.jpeg"
        />

        {/*Card Espírito Santo*/}
        <WarehouseDashboardCard
          stateName="Espírito Santo"
          backgroundColorChildCard="#50AA54"
          warehouse={{ ...warehouses['ES-01'], code: warehouseCode.es01 }}
          stateFlag="https://www.gov.br/anm/pt-br/canais_atendimento/fale-conosco/uf-flag/es.jpg"
        />

        {/*Card Rio de Janeiro*/}
        <WarehouseDashboardCard
          stateName="Rio de Janeiro"
          backgroundColorChildCard="#EE514E"
          warehouse={{ ...warehouses['RJ-02'], code: warehouseCode.rj02 }}
          stateFlag="https://www.gov.br/anm/pt-br/canais_atendimento/fale-conosco/uf-flag/riodejaneiro.jpg"
        />
      </Box>
    </Container>
  );
}
