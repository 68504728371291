import { MenuItem, TextField } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { request } from '../../helpers';

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      width: 170,
    },
  });

interface Props extends WithStyles<typeof styles> {
  onChange: any;
  value: string;
}

interface State {
  carriers: any[];
}
const inputLabelProps = {
  shrink: true,
};

class CarrierInput extends React.Component<Props, State> {
  state = {
    carriers: [],
  };

  async componentDidMount() {
    const response = (await request('post:/api/carrier/list', {})).data;

    this.setState({ carriers: response.carriers });
  }

  render() {
    const { onChange, value, classes } = this.props;
    const { carriers } = this.state;

    return (
      <TextField
        name="carrier"
        select
        label="Transportadora"
        margin="normal"
        InputLabelProps={inputLabelProps}
        className={classes.textField}
        onChange={onChange}
        value={value}
      >
        <MenuItem value="" />
        {carriers.map((carrier: any) => (
          <MenuItem value={carrier.code} key={carrier.code}>
            {carrier.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default withStyles(styles)(CarrierInput);
