import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { request } from '../../helpers';
import React, { Component, Fragment } from 'react';
import BranchInput from '../Inputs/BranchInput';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  FormControl: {
    width: 500,
  },
});

interface Props extends WithStyles<typeof styles> {
  id: string;
  sku: string;
  code: string;
  open: boolean;
  branch: string;
  storageControllerId: string;
  updateListItems: () => string;
}

interface State {
  id: string;
  sku: string;
  code: string;
  open: boolean;
  branch: string;
}

class StorageControllerItemEdit extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      id: this.props.id,
      sku: this.props.sku,
      code: this.props.code,
      branch: this.props.branch,
    };
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as any);
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      const data = {
        storageControllerId: await this.props.storageControllerId,
        ...this.state,
      };

      const response = (
        await request('post:/api/storage-controller-item/upsert', data)
      ).data;

      this.props.updateListItems();

      this.handleToggle();
    } catch (e) {
      throw new Error(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { open, code, branch, sku } = this.state;

    return (
      <Fragment>
        <form onSubmit={event => this.handleSubmit(event)}>
          <Dialog open={open} onClose={this.handleToggle}>
            <DialogTitle>Item: {code}</DialogTitle>

            <DialogContent>
              <DialogContentText>Editando item.</DialogContentText>

              <TextField
                name="code"
                value={code}
                label="Posição"
                margin="normal"
                className={classes.FormControl}
                onChange={event => this.handleChange(event)}
              />
              <br />

              <TextField
                name="sku"
                label="SKU"
                value={sku}
                margin="normal"
                className={classes.FormControl}
                onChange={event => this.handleChange(event)}
              />
              <br />

              <BranchInput
                value={branch}
                onChange={event => this.handleChange(event)}
              />
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={event => this.handleSubmit(event)}
              >
                Salvar
              </Button>

              <Button
                color="default"
                variant="contained"
                onClick={this.handleToggle}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Fragment>
    );
  }
}

export default withStyles(styles)(StorageControllerItemEdit);
