import {
  Paper,
  Button,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import { request } from '../../helpers';
import React, { Component } from 'react';
import BranchInput from '../Inputs/BranchInput';
import { Save } from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      width: '70%',
      margin: 'auto',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
    title: {
      marginLeft: '15%',
    },
    containerInputFields: {
      width: '80%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
    containerButton: {
      width: '16%',
      display: 'flex',
      marginLeft: '15%',
      justifyContent: 'space-around',
    },
  });

interface Props extends WithStyles<typeof styles> {
  storageConstrollerId: any;
  updateListItems: () => void;
}

interface State {
  sku: string;
  code: string;
  branch: string;
  storageController: string;
}

class StorageControllerItemsAdd extends Component<Props, State> {
  state: State = {
    sku: '',
    code: '',
    branch: '',
    storageController: '',
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;

    this.setState({
      [name]: value,
    } as any);
  };

  handleSubmit = async event => {
    try {
      const data = {
        //@ts-ignore
        storageControllerId: await this.props.storageConstrollerId,
        ...this.state,
        branchCode: this.state.branch,
      };

      const response = (
        await request('post:/api/storage-controller-item/upsert', data)
      ).data;

      this.props.updateListItems();
    } catch (e) {
      throw new Error(e);
    }
  };

  getStorageController = async () => {
    try {
      const { storageController } = (
        await request(
          //@ts-ignore
          `get:/api/storage-controller/find/?id=${this.props.storageConstrollerId}`,
          {},
        )
      ).data;

      this.setState({
        storageController: storageController.code,
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  componentDidMount = () => {
    this.getStorageController();
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    const { classes }: Props = this.props;
    const { code, sku, branch }: State = this.state;

    return (
      <>
        <Typography variant="h5" color="inherit" className={classes.title}>
          Adicionar item
        </Typography>

        <Divider
          variant="middle"
          style={{ margin: '20px auto ', width: '70%' }}
        />

        <form onSubmit={e => this.handleSubmit(e)}>
          <Paper className={classes.root}>
            <div className={classes.containerInputFields}>
              <div>
                <TextField
                  required
                  autoFocus
                  name="code"
                  value={code}
                  margin="normal"
                  label="Posição"
                  onChange={e => this.handleChange(e)}
                />
              </div>

              <div>
                <TextField
                  required
                  name="sku"
                  value={sku}
                  label="SKU"
                  margin="normal"
                  onChange={e => this.handleChange(e)}
                />
              </div>

              <div>
                <BranchInput
                  value={branch}
                  onChange={e => this.handleChange(e)}
                />
              </div>
            </div>
          </Paper>

          <Divider
            variant="middle"
            style={{ margin: '20px auto ', width: '70%' }}
          />

          <div className={classes.containerButton}>
            <Button type="submit" variant="contained" color="primary">
              <Save fontSize="small" />
              Salvar
            </Button>
          </div>
        </form>
      </>
    );
  }
}

export default withStyles(styles)(StorageControllerItemsAdd);
