import { Button, Grid, Paper, MenuItem } from '@material-ui/core';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { authenticate, isAuthenticated } from '../auth';
import { request } from '../helpers';
import Loading from '../components/Loading/Loading';

const styles = (theme: Theme) =>
  createStyles({
    loginContainer: {
      padding: 50,
    },
    textField: {
      width: 170,
    },
    warehouseContainer: {
      position: 'relative',
    },
  });

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

interface State {
  loading: boolean;
  username: string;
  values: any;
  warehouseLoading: boolean;
  warehouses: {
    code: string;
    short_name: string;
  }[];
}

class Login extends React.Component<Props, State> {
  state = {
    loading: false,
    username: '',
    values: {
      username: '',
      password: '',
      warehouse: '',
    },
    warehouseLoading: false,
    warehouses: [],
  };

  handleLogin = async event => {
    event.preventDefault();

    this.setState({ loading: true });

    const { values } = this.state;

    try {
      await authenticate(values.username, values.password, values.warehouse);
      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;

    const { values } = this.state;

    values[name] = value;

    return new Promise(resolve =>
      this.setState({ values }, () => resolve(undefined)),
    );
  };

  onBlurUsername = async e => {
    const username = e.target.value;

    if (this.state.username === username) {
      return;
    }

    this.setState({ warehouses: [], warehouseLoading: true });

    const response = (
      await request('post:/api/warehouse/list', {
        username,
      })
    ).data;

    this.setState({ warehouses: response.warehouses, warehouseLoading: false });

    if (response.warehouses.length === 1) {
      const values = this.state.values;
      values.warehouse = response.warehouses[0].code;
      this.setState({ values });
    }
  };

  render() {
    const { classes } = this.props;
    const { warehouses, warehouseLoading, values, loading } = this.state;

    if (isAuthenticated() === true) {
      return <Redirect to="/" />;
    }

    return (
      <Grid container>
        {loading && <Loading />}
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Paper>
            <Grid container className={classes.loginContainer}>
              <form onSubmit={this.handleLogin} noValidate autoComplete="off">
                <Grid item xs={12}>
                  <TextField
                    name="username"
                    label="Usuário"
                    className={classes.textField}
                    margin="normal"
                    onBlur={this.onBlurUsername}
                    onChange={this.handleChange}
                    value={values.username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label="Senha"
                    className={classes.textField}
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    onChange={this.handleChange}
                    value={values.password}
                  />
                </Grid>
                <Grid item xs={12} className={classes.warehouseContainer}>
                  {warehouseLoading && <Loading />}
                  <TextField
                    name="warehouse"
                    select
                    label="Armazém"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange}
                    value={values.warehouse}
                  >
                    {warehouses.map((warehouse: any) => (
                      <MenuItem value={warehouse.code} key={warehouse.code}>
                        {warehouse.short_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={this.handleLogin}>
                    Login
                  </Button>
                </Grid>

                <button type="submit" style={{ display: 'none' }} />
              </form>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6} />
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
