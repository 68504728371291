import { CssBaseline as CSS } from '@material-ui/core';
import '@babel/polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
  typography: {
    htmlFontSize: 17,
    fontSize: 14,
  },
  spacing: 6,
  palette: {
    type: 'light',
    primary: {
      main: '#3F51B5',
      light: '#5C6BC0',
      dark: '#1A237E',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CSS />
    <App />
  </ThemeProvider>,

  document.querySelector('#app'),
);
