import {
  Paper,
  Table,
  Dialog,
  Divider,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import cn from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '95%',
      marginTop: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    topHeader: {
      height: 65,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      background: theme.palette.primary.dark,
    },
    tableHeader: {
      maxWidth: 300,
      fontWeight: 700,
      padding: '3px 12px',
      textTransform: 'uppercase',
      background: theme.palette.primary.dark,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    containerButtons: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    inputTextField: {
      width: 50,
      height: 5,
    },
    paperHeader: {
      padding: 30,
      textAlign: 'center',
    },
  });

interface Props extends WithStyles {
  toggleDeliveryWarehouseDeadline: () => void;
  lateDeliveries: [];
}

interface State {
  open: boolean;
  fields: string[];
}

interface Delivery {
  id: number;
  orderId: number;
  invoice: { number: number };
  deliveryWarehouseDeadline: Date;
  status: { name: string };
}

class DeliveryWarehouseDeadline extends Component<Props, State, Delivery[]> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      fields: [
        'Número do pedido',
        'Número nota fiscal',
        'Data limite do armazém',
        'Status',
      ],
    };
  }

  toggleThisModal = () => {
    this.props.toggleDeliveryWarehouseDeadline();
  };

  render() {
    const { open, fields }: State = this.state;
    const { classes, lateDeliveries }: Props = this.props;

    const dateFormatter = (date: Date) =>
      date ? moment(date).format('DD/MM/YYYY') : '-';

    return (
      <Fragment>
        <Dialog fullScreen open={open}>
          <header>
            <div className={classes.topHeader} onClick={this.toggleThisModal}>
              <IconButton>
                <Close style={{ color: 'white' }} />
              </IconButton>
            </div>

            <Paper className={cn(classes.root, classes.paperHeader)}>
              <Typography variant="h4">Pedidos atrasados</Typography>
            </Paper>
          </header>

          <main>
            <Paper className={classes.root}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    {fields.map(field => {
                      return (
                        <TableCell className={classes.tableHeaderText}>
                          {field}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {lateDeliveries.map((delivery: Delivery) => {
                    return (
                      <TableRow key={delivery.id}>
                        <TableCell>{delivery.orderId}</TableCell>
                        <TableCell>{delivery.invoice.number}</TableCell>
                        <TableCell>
                          {dateFormatter(delivery.deliveryWarehouseDeadline)}
                        </TableCell>
                        <TableCell>{delivery.status.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </main>

          <Divider
            variant="middle"
            style={{ margin: '20px auto ', width: '70%' }}
          />
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(DeliveryWarehouseDeadline);
