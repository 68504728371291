import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { request } from '../../helpers';
import React, { Component, Fragment } from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  FormControl: {
    width: 500,
  },
});

interface Props extends WithStyles {
  id: string;
  open: boolean;
  code: string;
  updateListItems: () => void;
}

interface State {
  id: string;
  code: string;
  open: boolean;
}

class StorageControllerItemEdit extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      id: this.props.id,
      code: this.props.code,
    };
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as any);
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      await request('POST:/api/storage-controller/upsert', {
        ...this.state,
      });

      this.props.updateListItems();

      this.handleToggle();
    } catch (e) {
      throw new Error(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { open, code } = this.state;

    return (
      <Fragment>
        <form onSubmit={event => this.handleSubmit(event)}>
          <Dialog open={open} onClose={this.handleToggle}>
            <DialogTitle>Colmeia: {code}</DialogTitle>

            <DialogContent>
              <DialogContentText>Editando colmeia.</DialogContentText>

              <TextField
                name="code"
                value={code}
                label="Posição"
                margin="normal"
                className={classes.FormControl}
                onChange={event => this.handleChange(event)}
              />
              <br />
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={event => this.handleSubmit(event)}
              >
                Salvar
              </Button>

              <Button
                color="default"
                variant="contained"
                onClick={this.handleToggle}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Fragment>
    );
  }
}

export default withStyles(styles)(StorageControllerItemEdit);
