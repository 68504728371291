import { request } from '../../helpers';
import Loading from '../Loading/Loading';
import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

interface Warehouse {
  code: string;
  name: string;
  active: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      width: 170,
    },
  });

const WarehouseInput = ({
  name,
  value,
  classes,
  variant,
  onChange,
  InputProps,
  InputLabelProps,
}) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

  useEffect(() => {
    try {
      const fetchWarehouses = async () => {
        const { warehouses } = (
          await request('GET:/api/warehouse/list-all', {})
        ).data;

        setWarehouses(warehouses);
      };

      fetchWarehouses();
    } catch (error) {
      console.error(Error, error);
      throw new Error(error);
    }
  }, []);

  return (
    <>
      {warehouses.length <= 0 && <Loading />}

      <TextField
        select
        name={name}
        value={value}
        label="Armazém"
        margin="normal"
        variant={variant}
        onChange={onChange}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        className={InputProps ? null : classes.textField}
      >
        <MenuItem value="" />
        {warehouses
          .filter(warehouse => !!warehouse.active)
          .map(warehouse => (
            <MenuItem value={warehouse.code} key={warehouse.code}>
              {warehouse.code}
            </MenuItem>
          ))}
      </TextField>
    </>
  );
};

export default withStyles(styles)(WarehouseInput);
