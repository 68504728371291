import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';
import { Link } from 'react-router-dom';
import MenuItems from './MenuItems';
import { HeaderContext } from './HeaderContext';
import { isAuthenticated } from '../../auth';
import { Theme } from '@material-ui/core/styles';

const drawerWidth = 180;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    sectionDesktop: {},
    grow: {
      flexGrow: 1,
    },
    header: {
      ...theme.mixins.toolbar,
      display: 'flex',
    },
    headerTypography: {
      marginRight: 20,
    },
    logo: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      textDecoration: 'none',
      padding: `0 ${theme.spacing(3)}px`,
    },
  });

interface Props extends React.Props<any>, WithStyles<typeof styles> {}

interface State {
  open: boolean;
  anchorEl?: any;
}

class Header extends React.Component<Props, State> {
  state = {
    open: false,
    anchorEl: null,
  };

  handleProfileMenuOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;

    const { anchorEl } = this.state;

    const isMenuOpen = Boolean(anchorEl);

    const isAuth = isAuthenticated();

    return (
      <HeaderContext.Provider value={{ toggleDrawer: this.toggleDrawer }}>
        <AppBar>
          <Toolbar id="toolbar">
            {isAuth && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography
              variant="h6"
              color="inherit"
              className={classes.headerTypography}
            >
              Pepper
            </Typography>
            <div className={classes.grow} id="header-items" />
            {isAuth && (
              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
        >
          <MenuItem>
            <Link to="/logout">Sair</Link>
          </MenuItem>
        </Menu>
        {isAuth && (
          <Drawer
            variant="persistent"
            open={this.state.open}
            onClose={() => this.toggleDrawer(false)}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.header}>
              <div>
                <IconButton onClick={() => this.toggleDrawer(false)}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <Link to="/" className={classes.logo}>
                <Typography variant="h6" color="primary">
                  Pepper
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Build: 0.1
                </Typography>
              </Link>
            </div>
            <Divider />
            <MenuItems />
            <Divider />
          </Drawer>
        )}
      </HeaderContext.Provider>
    );
  }

  toggleDrawer = (open: boolean): void => {
    this.setState({
      open,
    });
  };
}

export default withStyles(styles)(Header);
