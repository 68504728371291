import axios from 'axios';
import * as qs from 'querystring';
import Cookies from 'js-cookie';

export function isEmptyObject(obj) {
  try {
    return Object.keys(obj).length < 1;
  } catch (e) {
    return true;
  }
}

export function getURLQuery() {
  return qs.parse(window.location.search.substring(1));
}

export function request(requestUrl: string, params: any, baseUrl?: string) {
  let [method, url] = requestUrl.split(':');

  if (!url) {
    url = method;
    method = 'get';
  }

  method = method.toLowerCase();

  const requestConfig = {
    method: method as any,
    url: (baseUrl || '') + url,
    params: method === 'get' ? params : {},
    data: ['put', 'delete', 'post'].includes(method) ? params : {},
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  };

  return axios(requestConfig);
}

export function keys(object) {
  return Object.keys(object);
}

export function noError(func, fallback) {
  try {
    return func();
  } catch (e) {
    return fallback;
  }
}

export function setStateAsync(component, state) {
  return new Promise(resolve =>
    component.setState.call(component, state, resolve),
  );
}
