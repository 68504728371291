import {
  Box,
  Button,
  Divider,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  cardParent: {
    borderRadius: 3,
    position: 'relative',
    margin: theme.spacing(1),
    marginTop: theme.spacing(11),
    minHeight: theme.spacing(74),
    paddingBottom: theme.spacing(2),
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
    '&:hover #cardChild': {
      marginTop: -60,
      transition: '0.8s',
    },
  },
  cardChild: {
    zIndex: 1,
    width: '92%',
    marginTop: -10,
    minHeight: 195,
    borderRadius: 3,
    position: 'absolute',
    padding: theme.spacing(2),
    boxShadow: 'rgba(0, 0, 0, 0.418) 2.4px 2.4px 3.2px',
  },
  figureContainer: {
    width: '50%',
    margin: 'auto',
    display: 'flex',
    color: '#ffffff',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  figureFlag: {
    width: '25%',
  },
  cardChildDivider: {
    margin: '5px 0',
    backgroundColor: '#ffffff',
  },
  textColorWhite: {
    color: '#ffffff',
  },
  cardChildText: {
    margin: 3,
    fontSize: 14,
    color: '#ffffff',
  },
  buttonSeeMore: {
    width: '50%',
    margin: '145px 25%',
  },
}));

export default function WarehouseDashboardCard({
  warehouse,
  stateName,
  stateFlag,
  backgroundColorChildCard,
}) {
  const classes = useStyles();

  return (
    <Container className={classes.cardParent}>
      <Box
        id="cardChild"
        className={classes.cardChild}
        style={{
          backgroundColor: backgroundColorChildCard,
        }}
      >
        <figure className={classes.figureContainer}>
          <img src={stateFlag} alt={stateName} className={classes.figureFlag} />

          <figcaption>
            <Typography>
              <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>{stateName}</Box>
            </Typography>
          </figcaption>
        </figure>

        <Divider component="div" style={{ visibility: 'hidden', margin: 5 }} />

        <Typography component="div" className={classes.textColorWhite}>
          Pedidos Finalizados Hoje
        </Typography>

        <Divider className={classes.cardChildDivider} />

        <Box>
          <Typography className={classes.textColorWhite}>No Prazo</Typography>
        </Box>

        <Typography
          component="div"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <Box className={classes.cardChildText}>
            Packing {warehouse.finishedToday.onTime.ordersPacking}
          </Box>

          <Box className={classes.cardChildText}>
            Expedidos {warehouse.finishedToday.onTime.ordersShipped}
          </Box>
        </Typography>

        <Box>
          <Typography className={classes.textColorWhite}>Atrasados</Typography>
        </Box>

        <Typography
          component="div"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <Box className={classes.cardChildText}>
            Packing {warehouse.finishedToday.delay.ordersPacking}
          </Box>

          <Box className={classes.cardChildText}>
            Expedidos {warehouse.finishedToday.delay.ordersShipped}
          </Box>
        </Typography>
      </Box>

      <Button
        color="primary"
        variant="contained"
        className={classes.buttonSeeMore}
        href={`dashboard-by-warehouse?warehouse=${warehouse.code}`}
      >
        <Typography>Ver mais</Typography>
      </Button>

      <Typography component="div" style={{ marginTop: '-125px' }}>
        Pedidos Abertos
      </Typography>

      <Divider
        className={classes.cardChildDivider}
        style={{ backgroundColor: 'grey' }}
      />

      <Box>
        <Typography
          style={{
            color: 'white',
            paddingLeft: 2,
            backgroundColor: 'blue',
          }}
        >
          No Prazo
        </Typography>
      </Box>

      <Typography
        component="div"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Pedidos
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.onTime.orders}
        </Box>
      </Typography>

      <Typography
        component="div"
        style={{
          display: 'flex',
          backgroundColor: '#00bafd26',
          justifyContent: 'space-between',
        }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Aguardando Onda
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.onTime.awaitingWave}
        </Box>
      </Typography>

      <Typography
        component="div"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Aguardando Embalagem
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.onTime.awaitingPacking}
        </Box>
      </Typography>

      <Typography
        component="div"
        style={{
          display: 'flex',
          backgroundColor: '#00bafd26',
          justifyContent: 'space-between',
        }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Aguardando Expedição
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.onTime.awaitingShipment}
        </Box>
      </Typography>

      <Box>
        <Typography
          style={{
            color: 'white',
            paddingLeft: 2,
            backgroundColor: 'red',
          }}
        >
          Atrasados
        </Typography>
      </Box>

      <Typography
        component="div"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Pedidos
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.delay.orders}
        </Box>
      </Typography>

      <Typography
        component="div"
        style={{
          display: 'flex',
          backgroundColor: '#00bafd26',
          justifyContent: 'space-between',
        }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Aguardando Onda
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.delay.awaitingWave}
        </Box>
      </Typography>

      <Typography
        component="div"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Aguardando Embalagem
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.delay.awaitingPacking}
        </Box>
      </Typography>

      <Typography
        component="div"
        style={{
          display: 'flex',
          backgroundColor: '#00bafd26',
          justifyContent: 'space-between',
        }}
      >
        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          Aguardando Expedição
        </Box>

        <Box className={classes.cardChildText} style={{ color: 'black' }}>
          {warehouse.open.delay.awaitingShipment}
        </Box>
      </Typography>
    </Container>
  );
}
