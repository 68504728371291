import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { unauthenticate } from '../auth';

interface Props extends RouteComponentProps<{}> {
  classes: any;
}
class Logout extends React.Component<Props> {
  render() {
    unauthenticate();

    return <Redirect to="/login" />;
  }
}
const styles = theme => ({});

export default withStyles(styles)(Logout);
